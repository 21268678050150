<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <apexchart type="treemap" :options="chartOptions" :series="[{ data: series }]"></apexchart>
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IncomeChart',
  data () {
    return {
      icon: ['fal', 'chart-line'],
      labels: {
        name: 'Income'
      },
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      incomeByClient: state => state.incomeByClient.data
    }),
    series () {
      if (this.incomeByClient) {
        return this.incomeByClient.map(elem => {
          return { x: elem.x, y: elem.y }
        })
      }
      return {}
    }
  },
  mounted () {
    this.$store.dispatch('index', { collection: 'incomeByClient' })
  }
}
</script>
