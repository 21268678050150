<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.settings') }}</h3>
    </route-header>
    <div class="row">
      <div class="col">
        <nav-secondary-settings></nav-secondary-settings>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6" v-if="authCan('vendors')">
        <vendors />
      </div>
      <div class="col-12 col-sm-6" v-if="authCan('expense_categories')">
        <expense-categories />
      </div>
    </div>
  </div>
</template>

<script>
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'SettingsAccounts',
  mixins: [authMethods]
}
</script>
