<template>
    <div class="row">
        <div class="col-12 col-sm-6 mb-3">
            <bs-card v-if="resource && resource.tasks && resource.tasks.total.total > 0" class="card-purple">
                <div class="card-body border-bottom d-flex flex-column align-items-center">
                    <div class="d-flex">
                        <div class="bg-purple text-white px-4 d-flex align-items-center">
                            <h2 class="display-3">
                                <animated-number :value="resource.tasks ? resource.tasks.complete.total : 0" :formatValue="formatToInt" :duration="500" />
                            </h2>
                        </div>
                        <div>
                            <span class="d-block font-weight-bold text-purple pl-2">Tasks Complete</span>
                            <h3 class="d-block m-0 py-3 text-right">Of
                                <span class="bg-purple-light p-2">
                                    <animated-number :value="resource.tasks ? resource.tasks.total.total : 0" :formatValue="formatToInt" :duration="500" />
                                </span>
                            </h3>
                            <small class="d-block pl-2 text-purple text-right">Total</small>
                        </div>
                    </div>
                    <p class="text-muted py-2 m-0">out of
                        <span class="font-weight-bold text-purple">
                            <animated-number :value="resource.tasks ? resource.tasks.visits.total : 0" :formatValue="formatToInt" :duration="500" />
                        </span>
                        visits this month
                    </p>
                    <div class="progress w-100" style="height: 25px">
                        <div class="progress-bar bg-purple" role="progressbar" :style="'width:' + taskCompletePercent + '%;'" :aria-valuenow="taskCompletePercent" aria-valuemin="0" aria-valuemax="100">
                            <animated-number :value="taskCompletePercent" :formatValue="formatToPercent" :duration="500" />
                        </div>
                    </div>
                </div>
            </bs-card>
        </div>
        <div class="col-12 col-sm-6 mb-3">
            <bs-card v-if="resource && resource.earnings && resource.earnings.potential.total.total > 0" class="card-indigo">
                <div class="card-body border-bottom d-flex flex-column align-items-center">
                    <div class="d-flex">
                        <div class="bg-success text-white px-4 d-flex align-items-center">
                            <h2 class="display-3">
                                <animated-number :value="resource.earnings ? resource.earnings.so_far.total : 0" :formatValue="formatToPrice" :duration="500" />
                            </h2>
                        </div>
                    </div>
                    <p class="text-muted pt-2 m-0">earned so far this month</p>
                    <p class="text-muted pb-2 m-0">of a <strong class="text-indigo">potential</strong> <span class="bg-indigo text-light px-2">
                        <animated-number :value="resource.earnings ? resource.earnings.potential.total.total : 0" :formatValue="formatToPrice" :duration="500" />
                    </span></p>
                    <div class="progress w-100" style="height: 25px">
                        <div class="progress-bar bg-success" role="progressbar" :style="'width:' + earnedCompletePercent + '%;'" :aria-valuenow="earnedCompletePercent" aria-valuemin="0" aria-valuemax="100">
                            <animated-number :value="earnedCompletePercent" :formatValue="formatToPercent" :duration="500" />
                        </div>
                    </div>
                </div>
            </bs-card>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AnimatedNumber from 'animated-number-vue'

export default {
  name: 'UserMonthlyProgress',
  components: {
    AnimatedNumber
  },
  data () {
    return {
      loading: false,
      activeId: null
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      resource: state => state.userMonthlyProgress.data
    }),
    taskCompletePercent () {
      if (this.resource && this.resource.tasks) {
        return Math.round(this.resource.tasks.complete.total / this.resource.tasks.total.total * 100)
      }
      return 0
    },
    earnedCompletePercent () {
      if (this.resource && this.resource.earnings) {
        return Math.round(this.resource.earnings.so_far.total / this.resource.earnings.potential.total.dollars * 100)
      }
      return 0
    }
  },
  methods: {
    index () {
      this.$store.dispatch('index', { collection: 'UserMonthlyProgress' })
    },
    formatToInt (value) {
      return value.toFixed(0)
    },
    formatToPrice (value) {
      return this.$n(value, 'currency')
    },
    formatToPercent (value) {
      return this.$t('formats.percent', { amount: value.toFixed(0) })
    }

  }
}
</script>
