<template>
    <div class="form-group bs-form-group">
        <label :for="name" class="text-uppercase mb-0">{{ label }}</label>
        <textarea class="form-control shadow-sm border-top-0 border-left-0 border-bottom-1" rows="4" :id="name" v-model="inputValue"></textarea>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
    </div>

</template>

<script>
export default {
  name: 'TextField',
  props: ['label', 'name', 'help', 'value', 'error'],
  computed: {
    inputValue: {
      get () {
        return this.value ?? this.default
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>
