<template>
  <span>{{ display }}</span>
</template>

<script>
export default {
  name: 'Date',
  props: ['date'],
  computed: {
    display () {
      let dt = this.date
      if (typeof this.date === 'string') dt = new Date(dt)

      const dayOfWeek = dt.toLocaleString(
        'default', { weekday: 'short' }
      )
      const date = dt.getDate()
      const monthOfYear = dt.toLocaleString(
        'default', { month: 'short' }
      )
      const year = dt.getFullYear()

      return `${dayOfWeek} ${monthOfYear} ${date}, ${year}`
    }
  }
}
</script>
