<template>
  <div>

    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="8">
        <template v-slot:thead>
          <tr v-if="resourceState.search.active">
            <th class="p-1" :colspan="7">
              <input ref="searchInput" type="text" class="form-control border-1 border-primary shadow-sm bg-light" v-model="searchPhrase">
            </th>
            <th class="text-right"><bs-action :icon="['fas', 'times']" class="btn-outline-secondary" @click="applySearch({ active: false, phrase: '' })"></bs-action></th>
          </tr>
          <tr v-else>
            <bs-th :label="$t('labels.date')" :sortable="resourceState.sort" :col="'created_at'" @click="applySort"></bs-th>
            <bs-th scope="col" :label="$t('labels.source')"></bs-th>
            <bs-th scope="col" :label="$t('labels.description')"></bs-th>
            <bs-th scope="col" :label="$t('labels.amount')"></bs-th>
            <bs-th scope="col" :label="$t('labels.fee')"></bs-th>
            <bs-th scope="col" :label="$t('labels.state')" class="text-center"></bs-th>
            <bs-th scope="col" :label="$tc('labels.invoice', 0)"></bs-th>
            <th class="text-right"><bs-action :icon="['fal', 'search']" class="btn-outline-secondary" @click="applySearch({ active: true, phrase: '' })"></bs-action></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td><date :date="r.created_at" /></td>
          <td>{{ r.source }}</td>
          <td>{{ r.description }}</td>
          <td>
            <span>{{ $n(r.amount, 'currency') }}</span><br>
            <small v-if="r.amount !== r.remainder">{{ $t('labels.remainder', { amount: $n(r.remainder, 'currency') }) }}</small>
          </td>
          <td>
            <span v-if="r.fee_total">
              {{ $n(r.fee_total, 'currency') }} <small v-if="r.fee_tax">({{ $n(r.fee_tax, 'currency') }} GST)</small>
            </span>
          </td>
          <td class="text-center">
            <bs-badge :class="r.state.class">{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>
          <td>
            <div v-for="c in r.credits" :key="'c' + r.id + c.id">
              <bs-badge class="bg-green d-flex text-left">
                <fa-icon :icon="['fas', 'times']" class="mr-1 clickable" @click="destroyCredit(r, c)"></fa-icon>
                <span class="d-block">
                  <span class="d-block mb-1">{{ c.name }}</span>
                  <span class="d-block">Credit | {{ $n(c.pivot.amount, 'currency') }}</span>
                </span>
              </bs-badge>
            </div>
            <div v-for="i in r.invoices" :key="'i' + r.id + i.id">
              <bs-badge v-if="r.confirmed" :class="i.amount > i.paid ? 'bg-warning' : 'bg-success-light'" class="text-left d-flex">
                <fa-icon :icon="['fas', 'times']" class="mr-1 clickable" @click="destroy(r, i)"></fa-icon>
                <span class="d-block">
                  <span class="d-block mb-1">{{ i.client }}</span>
                  <span class="d-block">{{ $t('labels.nav.invoice', { no: i.number ? i.number : i.id }) }} | {{ $n(i.paid, 'currency') }}</span>
                  <span class="d-block mt-1" v-if="r.credit > 0">{{ $t('labels.remainder_credited', { amount: $n(r.credit, 'currency') }) }}</span>
                </span>
              </bs-badge>
              <bs-badge v-else class="bg-info-light p-0">
                <div class="p-1">
                  <fa-icon :icon="['fas', 'times']" class="mr-1 clickable" @click="destroy(r, i)"></fa-icon>
                  <span v-tooltip.left="'Confidence: ' + r.confidence + '%'">MATCH: {{ i.number }} - {{ i.client }} ({{ $n(i.amount, 'currency') }})</span>
                  <fa-icon :icon="['fas', 'check']" class="ml-1 clickable" @click="match(r, i)"></fa-icon>
                </div>
                <div class="progress" style="height: 2px">
                  <div class="progress-bar bg-indigo" role="progressbar" :style="'width: ' + r.confidence + '%'"></div>
                </div>
              </bs-badge>
            </div>
            <div v-for="e in r.expenses" :key="'e' + r.id + e.id">
              <bs-badge v-if="r.confirmed" :class="'bg-' + e.category.color">
                <fa-icon :icon="['fas', 'times']" class="mr-1 clickable" @click="destroyExpense(r, e)"></fa-icon>
                <span>{{ $n(e.amount * (e.business_use / 100), 'currency') }} - {{ e.category.name }} {{ e.category.number }}</span>
                <span v-if="e.vendor"> | {{ e.vendor.name }}</span>
                <span v-if="e.business_use < 100"> | {{ $n(e.business_use / 100, 'percentInt') }}</span>
                <span v-if="e.gst"> | {{ $t('labels.gst') }}</span>
              </bs-badge>
              <bs-badge v-else class="bg-info-light p-0">
                <div class="p-1">
                  <span v-tooltip.left="'Confidence: ' + r.confidence + '%'">
                    <span>MATCH: </span>
                    <span>{{ $n(e.amount * (e.business_use / 100), 'currency') }} - {{ e.category.name }} {{ e.category.number }}</span>
                    <span v-if="e.vendor"> | {{ e.vendor.name }}</span>
                    <span v-if="e.business_use < 100"> | {{ $n(e.business_use / 100, 'percentInt') }}</span>
                    <span v-if="e.gst"> | {{ $t('labels.gst') }}</span>
                  </span>
                  <fa-icon :icon="['fas', 'check']" class="ml-1 clickable" @click="matchExpense(r, e)"></fa-icon>
                </div>
                <div class="progress" style="height: 2px">
                  <div class="progress-bar bg-indigo" role="progressbar" :style="'width: ' + r.confidence + '%'"></div>
                </div>
              </bs-badge>
            </div>
          </td>
          <td class="t2">
            <bs-actions>
              <template v-slot:primary>
                <bs-action v-if="r.remainder > 0" :icon="['fal', 'link']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
                <bs-action v-if="r.remainder < 0" :icon="['fas', 'plus']" :tip="labels.assign" class="btn-info" @click="assign(r)"></bs-action>
                <bs-action v-if="false" :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
              </template>
              <template v-slot:secondary>
                <button v-if="r.remainder > 0" class="dropdown-item" @click="$refs.credit.show(r)">{{ labels.credit }}</button>
              </template>
            </bs-actions>
          </td>
        </tr>
      </bs-table>
    </bs-card>

    <portal to="app-modal">
      <credit-client-form ref="credit" />

      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template>
            <tuxedo-select :name="'invoice'" :options="filteredInvoices" v-model="resource.invoice" :insistLabel="'number'">
              <template v-slot:content="{ option }">
                <small class="mt-1 text-muted"><b>Amount:</b> {{ $n(option.amount, 'currency') }}</small><br>
                <small class="mt-1 text-muted"><b>Client:</b> {{ option.client }}</small><br>
                <small class="mt-1 text-muted"><b>ID:</b> {{ option.id }}</small>
              </template>
            </tuxedo-select>
            <bs-alert class="bg-warning text-light" v-if="resource.invoice && resource.invoice.amount !== resource.amount">
              <h5>{{ $t('messages.difference_amount') }}</h5>
              {{ $t('labels.invoice_amount', { amount: $n(resource.invoice.amount, 'currency') }) }}<br>
              {{ $t('labels.transaction_amount', { amount: $n(resource.remainder, 'currency') }) }}<br>
              {{ $t('labels.difference', { amount: $n(resource.remainder - resource.invoice.amount, 'currency') }) }}
            </bs-alert>
            <tuxedo-toggle v-if="resource.invoice && resource.invoice.amount < resource.amount" :name="'credit'" :label="$t('labels.credit_remainder')" v-model="resource.credit" />
          </template>
        </bs-form>
      </bs-modal>

      <bs-modal :name="'assign-' + $options.name" :label="labels.assign">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="slotProps">
            <div class="row">
              <div class="col-6">
                <tuxedo-input :name="'amount'" :label="$t('labels.amount')" v-model="resource.amount" :error="slotProps.error"></tuxedo-input>
              </div>
              <div class="col-6">
                <tuxedo-input :type="'percentage'" :name="'business_use'" :label="$t('labels.business_use') + ' %'" v-model="resource.business_use" :error="slotProps.error"></tuxedo-input>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-6">
                <div class="form-group bs-form-group">
                  <label class="text-uppercase mb-0">{{ $t('labels.gst') }}</label>
                  <input class="form-control shadow-sm border-top-0 border-left-0 border-bottom-1" :value="gstAmount" disabled>
                </div>
              </div>
              <div class="col-6">
                <tuxedo-toggle :name="'gst'" :label="$t('labels.gst')" v-model="resource.gst" :error="slotProps.error"></tuxedo-toggle>
              </div>
            </div>
            <tuxedo-select :name="'category'" :label="$tc('labels.expense_category', 1)" v-model="resource.category" :options="[{ id: '0', name: '' }].concat(sortedCategories)" :insistReturn="'id'" :insistLabel="'name'" :error="slotProps.error" />
            <tuxedo-select :name="'vendor'" :label="$tc('labels.vendor', 1)" v-model="resource.vendor" :options="[{ id: '0', name: '' }].concat(sortedVendors)" :insistReturn="'id'" :insistLabel="'name'" :error="slotProps.error" />
          </template>
        </bs-form>
      </bs-modal>

      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.disconnect')" @submit="destruction">
          {{ $t('messages.disconnect', { resource: $tc('labels.invoice', 1) }) }}
        </bs-form>
      </bs-modal>

      <bs-modal :name="'destroyExpense-' + $options.name" :label="labels.deleteExpense">
        <bs-form :destroy="$t('labels.disconnect')" @submit="destructionExpense">
          {{ $t('messages.disconnect', { resource: $tc('labels.expense', 1) }) }}
        </bs-form>
      </bs-modal>

      <bs-modal :name="'destroyCredit-' + $options.name" :label="labels.deleteCredit">
        <bs-form :destroy="$t('labels.disconnect')" @submit="destructionCredit">
          {{ $t('messages.disconnect', { resource: $tc('resource.credit', 1) }) }}
        </bs-form>
      </bs-modal>
    </portal>

    <portal to="app-footer">
      <pagination :data="resources" :state="resourceState" @pushPage="index" :filters="filters"></pagination>
    </portal>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import axios from 'axios'
import Fuse from 'fuse.js'
import CreditClientForm from '../forms/CreditClientForm'

export default {
  name: 'Transactions',
  mixins: [resourceControl],
  components: {
    'credit-client-form': CreditClientForm
  },
  data () {
    return {
      icon: ['fal', 'exchange'],
      resource: {
        invoice: {},
        category: null,
        vendor: null,
        amount: null,
        business_use: null,
        gst: true,
        credit: false
      },
      invoices: {},
      filteredInvoices: [],
      filters: {
        transactionDirection: true
      },
      labels: {
        delete: this.$t('labels.action_resource', { action: this.$t('labels.disconnect'), resource: this.$tc('labels.invoice', 1) }),
        deleteExpense: this.$t('labels.action_resource', { action: this.$t('labels.disconnect'), resource: this.$tc('labels.expense', 1) }),
        deleteCredit: this.$t('labels.action_resource', { action: this.$t('labels.delete'), resource: this.$tc('resource.credit', 1) }),
        credit: this.$t('labels.action_resource', { action: this.$t('labels.credit'), resource: this.$tc('resource.client', 1) })
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.transactions,
      resourceState: state => state.transactionsState,
      categories: state => state.expenseCategoriesStore,
      vendors: state => state.vendorsStore,
      clients: state => state.clientsStore
    }),
    sortedCategories () {
      return this._.orderBy(this.categories, ['name'])
    },
    sortedVendors () {
      return this._.orderBy(this.vendors, ['name'])
    },
    gstAmount () {
      return parseFloat(this.resource.amount / 11).toFixed(2)
    }
  },
  mounted () {
    this.getInvoices()
  },
  methods: {
    getInvoices () {
      axios.get('/api/invoices?unmatched=true').then((res) => {
        this.invoices = res.data.data
        this.filteredInvoices = this.invoices
      })
    },
    searchInvoices (query) {
      if (query.length > 0) {
        const fuse = new Fuse(this.invoices, {
          keys: ['amount', 'client', 'id', 'number']
        })
        const results = fuse.search(query)
        this.filteredInvoices = []
        results.forEach((r) => {
          this.filteredInvoices.push(r.item)
        })
      } else {
        this.filteredInvoices = this.invoices
      }
    },
    match (trans, inv) {
      this.resource = trans
      this.resource.invoice = inv
      this.resource.credit = trans.source !== 'Stripe'
      this.submit()
    },
    matchExpense (trans, exp) {
      this.resource = trans
      this.resource.expense = exp
      this.submit()
    },
    assign (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.amount = o.amount * -1
      this.resource.gst = true
      this.resource.business_use = 100
      this.$modal.show('assign-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.getInvoices()
        this.$modal.hide('create-' + this.$options.name)
        this.$modal.hide('assign-' + this.$options.name)
      })
    },
    destroy (r, i) {
      this.activeId = i.id
      this.resource = r
      this.$modal.show('destroy-' + this.$options.name)
    },
    destruction () {
      this.$store.dispatch('destroy', {
        path: 'transactions',
        id: this.resource.id,
        tag: 'invoices',
        tag_id: this.activeId,
        collection: this.$options.name
      }).then(() => {
        this.activeId = null
        this.getInvoices()
        this.$modal.hide('destroy-' + this.$options.name)
      })
    },
    destroyExpense (r, e) {
      this.activeId = e.id
      this.resource = r
      this.$modal.show('destroyExpense-' + this.$options.name)
    },
    destructionExpense () {
      this.$store.dispatch('destroy', {
        path: 'transactions',
        id: this.resource.id,
        tag: 'expenses',
        tag_id: this.activeId,
        collection: this.$options.name
      }).then(() => {
        this.$modal.hide('destroyExpense-' + this.$options.name)
        this.activeId = null
      })
    },
    destroyCredit (r, c) {
      this.activeId = c.id
      this.resource = r
      this.$modal.show('destroyCredit-' + this.$options.name)
    },
    destructionCredit () {
      this.$store.dispatch('destroy', {
        path: 'transactions',
        id: this.resource.id,
        tag: 'credits',
        collection: this.$options.name
      }).then(() => {
        this.$modal.hide('destroyCredit-' + this.$options.name)
        this.activeId = null
      })
    }
  }
}
</script>
