<template>
    <div class="form-group bs-form-group">
        <label v-if="label" :for="name" class="text-uppercase mb-0">{{ label }}</label>
        <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                @data-change="dataChange"
                :data-images="images"
                :dragText="$t('labels.drag_image')"
                :browseText="$t('labels.browse_image')"
                :dropText="$t('labels.drop_image')"
                :primaryText="$t('labels.primary_image')"
                :markIsPrimaryText="$t('labels.make_primary_image')"
                :popupText="null"
        ></vue-upload-multiple-image>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
    </div>
</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import helpers from '../../helpers'
export default {
  name: 'MultiImageUpload',
  components: {
    VueUploadMultipleImage
  },
  props: ['type', 'label', 'name', 'help', 'value', 'error'],
  data () {
    return {
      images: [],
      maxWidth: 1800,
      maxHeight: 1800
    }
  },
  // mounted () {
  //   this.images = this.value
  // },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    async uploadImageSuccess (formData, index, fileList) {
      console.log('upload image success')
      const files = []

      for (const file of fileList) {
        console.log(file.path)
        console.log(file)
        const canvas = document.createElement('canvas')
        console.log('canvas created')
        const ctx = canvas.getContext('2d')
        console.log('canvas context')
        console.log(ctx)
        const img = await new Promise(resolve => {
          const img = new Image()
          console.log('create new image')
          img.src = file.path
          console.log(img.src)
          resolve(img)
        })
        console.log(img)
        console.log('image created')

        const arr = file.path.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        console.log(arr)
        console.log('Mime: ' + mime)
        console.log('Image Width: ' + img.width)
        console.log('Image Height: ' + img.height)

        // Calculate the new size
        const ratio = Math.min(this.maxWidth / img.width, this.maxHeight / img.height)
        const width = img.width * ratio + 0.5 | 0
        const height = img.height * ratio + 0.5 | 0
        console.log('Calc Width: ' + width)
        console.log('Calc Height: ' + height)

        // resize the canvas to the new dimensions
        canvas.width = width
        canvas.height = height

        // scale & draw the image onto the canvas
        console.log('draw image')
        ctx.drawImage(img, 0, 0, width, height)
        console.log('image drawn')

        // Get the binary (aka blob)
        console.log(file.name)
        console.log('Mime: ' + mime)
        console.log(canvas)
        console.log(canvas.toDataURL(mime))
        // const blob = new Promise(resolve => canvas.toBlob(resolve, 1))
        // const resizedFile = new File([blob], file.name, { type: mime })

        files.push(helpers.base64ToFile(canvas.toDataURL(mime), file.name))
        console.log('new image pushed to array')
      }
      // fileList.forEach(function (file) {
      //   files.push(helpers.base64ToFile(file.path, file.name))
      // })

      this.$emit('input', files)
    },
    beforeRemove (index, done, fileList) {
      console.log('before remove')
      done()
    },
    editImage (formData, index, fileList) {
      console.log('edit image')
      const files = []
      fileList.forEach(function (file) {
        files.push(helpers.base64ToFile(file.path, file.name))
      })
      this.$emit('input', files)
    },
    dataChange (data) {
    }
  }
}
</script>

<style lang="scss">
    .bs-form-group {
        > label {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
</style>
