<template>
  <div>
    <bs-card class="card-info" :icon="['fal', 'clipboard-list']" :label="$t('labels.' + camelToSnake($options.name))">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
      </template>
      <div class="card-body border-bottom" v-for="r in resources.data" :key="r.id">
        <div class="card-title font-weight-bold d-flex justify-content-between align-items-center">
          <span>
            {{ r.name }}<br>
            <small v-if="r.address">{{ $t('labels.at_address', { address: r.address.name })}}<br></small>
            <small v-for="u in r.users" :key="'t' + r.id + 'u' + u.id" class="badge badge-secondary mr-1">{{ u.name }}</small>
          </span>
          <small class="text-right">
            <span v-html="$t('labels.total') + ': ' + $n(r.total, 'currency')"></span><br>
            <span v-html="$t('labels.contractor_receives') + ': ' + $n(r.contractor_total, 'currency')"></span><br>
          </small>
        </div>
        <table class="table table-sm">
          <tbody>
          <tr v-for="t in r.tasks" :key="'r' + r.id + 't' + t.id">
            <td class="pl-0">
              <div>{{ t.name }}</div>
              <small v-if="t.paused">
                <span v-if="t.pause_to">{{ $t('labels.paused_until', { date: dateString(t.pause_to) }) }}</span>
                <span v-else>{{ $t('labels.paused_indefinitely') }}</span>
              </small>
            </td>
            <td class="text-center">{{ $n(t.price, 'currency') }}</td>
            <td class="text-center" v-if="t.payment_type === 'percentage'">{{ $t('formats.percent' , { amount: t.contractor_percentage }) }}</td>
            <td class="text-center" v-else>{{ $n(t.contractor_amount, 'currency') }}</td>
            <td class="pr-0 text-right" style="width: 92px">
              <bs-action v-if="t.paused" :icon="['fas', 'play']" class="btn-warning mr-1" :tip="$t('tip.resume_task')" @click="playTaskPrompt(r, t)"></bs-action>
              <bs-action v-else :icon="['fas', 'pause']" class="btn-success mr-1" :tip="$t('tip.pause_task')" @click="pauseTaskPrompt(r, t)"></bs-action>
              <bs-action :icon="['fal', 'pencil']" class="btn-info mr-1" @click="updateTask(r, t)"></bs-action>
              <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroyTask(r, t)"></bs-action>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="px-0">
              <bs-button class="btn-sm btn-info w-100" @click="addTask(r)">{{ $t('labels.add_task') }}</bs-button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between align-items-center">
          <div class="card-text">
            <div class="text-muted" style="font-size:0.8rem">
              <div v-if="r.rotation === 'daily'">{{ $t('messages.daily_rotation') }}
                <span v-for="(day, key) in r.days_of_week" :key="'r' + r.id + 'dow' + day">
                  {{ getDay(day) }}<span v-if="key === r.days_of_week.length - 2"> & </span><span v-else-if="key < r.days_of_week.length -1">, </span>
                </span>
              </div>
              <div v-else-if="r.rotation === 'non'">
                {{ $t('messages.non_rotation') }}
              </div>
              <div v-else-if="r.rotation === 'monthly'">
                {{ $t('messages.' + r.rotation + '_' + r.frequency + '_rotation', { day: getDay(r.day_of_week), week: $t('labels.week_of_month.ordinal.' + r.week_of_month) }) }}
              </div>
              <div v-else>{{ $t('messages.' + r.rotation + '_' + r.frequency + '_rotation', { day: getDay(r.day_of_week) }) }}</div>
              <div>{{ $t('messages.last_updated', { time: dateString(r.updated_at) }) }}</div>
              <div v-if="r.start_at">Base Date: <date :date="r.start_at" /></div>
              <div>{{ $t('labels.task_group_conflict.' + r.conflict + '.description') }}</div>
            </div>
          </div>
          <div class="text-right">
            <bs-action :icon="['fal', 'pencil']" class="btn-info mr-1" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
          </div>
        </div>
      </div>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="{ error }" v-if="!formLoading">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
          <tuxedo-select :name="'address_id'" :label="$tc('labels.address', 1)" v-model="resource.address_id" :options="addresses" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
          <tuxedo-select :name="'users'" :label="$tc('labels.contractor', 0)" v-model="resource.users" :options="users" :insistLabel="'name'" :multi="true" :error="error" />
          <radio-slider :name="'rotation'" :label="$t('labels.schedule_rotation.label')" :help="$t('labels.schedule_rotation.help')" v-model="resource.rotation" :options="rotations" :error="error" />
          <tuxedo-select v-if="resource.rotation === 'weekly'" :name="'frequency'" :label="$t('labels.schedule_frequency.label')" :help="$t('labels.schedule_frequency.help')" v-model="resource.frequency" :options="weeklyFrequencies" :insistReturn="'id'" :error="error" />
          <tuxedo-select v-if="resource.rotation === 'monthly'" :name="'frequency'" :label="$t('labels.schedule_frequency.label')" :help="$t('labels.schedule_frequency.help')" v-model="resource.frequency" :options="monthlyFrequencies" :insistReturn="'id'" :error="error" />
          <tuxedo-select v-if="resource.rotation === 'monthly'" :name="'week_of_month'" :label="$t('labels.week_of_month.label')" :help="$t('labels.week_of_month.help')" v-model="resource.week_of_month" :options="weeksOfMonth" :insistReturn="'id'" :error="error" />
          <checkbox-slider v-if="resource.rotation === 'daily'" :name="'dsow'" :label="$t('labels.day_of_week.label')" :help="$t('labels.day_of_week.help')" v-model="resource.days_of_week" :options="{0: getDay(0), 1: getDay(1), 2: getDay(2), 3: getDay(3), 4: getDay(4), 5: getDay(5), 6: getDay(6)}" />
          <radio-slider v-else-if="resource.rotation !== 'non'" :name="'dow'" :label="$t('labels.day_of_week.label')" :help="$t('labels.day_of_week.help')" v-model="resource.day_of_week" :options="[getDay(0), getDay(1), getDay(2), getDay(3), getDay(4), getDay(5), getDay(6)]" />
          <radio-slider v-if="resource.rotation !== 'non'" :name="'conflict'" :label="$t('labels.task_group_conflict.label')" :help="$t('labels.task_group_conflict.help')" v-model="resource.conflict" :options="{overwrite: $t('labels.task_group_conflict.overwrite.label'), merge: $t('labels.task_group_conflict.merge.label'), coexist: $t('labels.task_group_conflict.coexist.label')}" :error="error" />
          <tuxedo-date v-if="resource.rotation !== 'non'" :name="'start_at'" :label="$t('labels.booking_start.label')" v-model="resource.start_at" :help="$t('labels.booking_start.help')" :insistFormat="'isoDate'" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'add-task'" :label="$t('labels.add_task')">
      <bs-form :submit="$t('labels.save')" @submit="submitTask">
        <template v-slot:default="{ error }" v-if="!formLoading">
          <task-field v-model="task.task" :error="error" />
          <div v-if="task.task">
            <text-field :name="'description'" :label="$t('labels.contractor_description.label')" :help="$t('labels.contractor_description.help')" v-model="task.description" :error="error" />
            <tuxedo-input :name="'price'" :label="$t('labels.task_group_price.label')" :help="$t('labels.task_group_price.help')" v-model="task.price" :error="error" />
            <tuxedo-radio class="pb-2" :name="'payment_type'" :label="$t('labels.payment_type.label')" :help="$t('labels.payment_type.help')" :options="{ percentage: $t('labels.percentage'), fixed: $t('labels.fixed') }" :default="'percentage'" v-model="task.payment_type" :error="error" />
            <tuxedo-input v-if="task.payment_type === 'percentage'" :type="'text'" :name="'contractor_percentage'" :label="$t('labels.contractor_percentage')" v-model="task.contractor_percentage" :error="error" />
            <tuxedo-input v-if="task.payment_type === 'fixed'" :type="'text'" :name="'contractor_amount'" :label="$t('labels.contractor_amount')" v-model="task.contractor_amount" :error="error" />
          </div>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-task'" :label="$t('labels.destroy_task')">
      <bs-form :destroy="$t('labels.destroy')" @submit="destructionTask">
        {{ $t('messages.delete', { resource: 'task' }) }}
      </bs-form>
    </bs-modal>
    <pause-task-form ref="pauseTask" @submit="pauseTask" />
    <play-task-form ref="playTask" @submit="playTask" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'
import helpers from '../../helpers'
import _ from 'lodash'
import { getDay } from '@/mixins/helpers'
import PauseTaskForm from '@/components/forms/PauseTaskForm'
import PlayTaskForm from '@/components/forms/PlayTaskForm'

export default {
  name: 'TaskGroups',
  props: ['type', 'id'],
  mixins: [getDay],
  components: {
    'pause-task-form': PauseTaskForm,
    'play-task-form': PlayTaskForm
  },
  data () {
    return {
      tableRows: 10,
      page: 1,
      sort: {
        col: 'name',
        dir: 'asc'
      },
      loading: false,
      formLoading: false,
      activeId: null,
      resource: {
        name: '',
        user_id: null,
        users: [],
        address_id: null,
        auto_schedule: true,
        rotation: null,
        frequency: null,
        week_of_month: null,
        day_of_week: null,
        days_of_week: [],
        start_at: null,
        conflict: null
      },
      taskGroup: null,
      taskGroupId: null,
      task: {
        id: null,
        task: null,
        description: '',
        price: null,
        contractor_percentage: null,
        payment_type: null,
        contractor_amount: null
      }
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      resources: state => state.taskGroups,
      submitting: state => state.submitting,
      users: state => state.usersStore,
      tasks: state => state.tasksStore,
      addresses: state => state.addressesStore
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.tableRows - length, 0)
    },
    rotations () {
      return {
        non: this.$t('labels.schedule_rotation.non'),
        daily: this.$t('labels.schedule_rotation.daily'),
        weekly: this.$t('labels.schedule_rotation.weekly'),
        monthly: this.$t('labels.schedule_rotation.monthly')
      }
    },
    weeklyFrequencies () {
      return [
        {
          id: 1,
          label: this.$t('labels.schedule_frequency.weekly_1')
        },
        {
          id: 2,
          label: this.$t('labels.schedule_frequency.weekly_2')
        },
        {
          id: 4,
          label: this.$t('labels.schedule_frequency.weekly_4')
        },
        {
          id: 6,
          label: this.$t('labels.schedule_frequency.weekly_6')
        },
        {
          id: 8,
          label: this.$t('labels.schedule_frequency.weekly_8')
        },
        {
          id: 12,
          label: this.$t('labels.schedule_frequency.weekly_12')
        },
        {
          id: 24,
          label: this.$t('labels.schedule_frequency.weekly_24')
        }
      ]
    },
    monthlyFrequencies () {
      return [
        {
          id: 1,
          label: this.$t('labels.schedule_frequency.monthly_1')
        },
        {
          id: 2,
          label: this.$t('labels.schedule_frequency.monthly_2')
        },
        {
          id: 3,
          label: this.$t('labels.schedule_frequency.monthly_3')
        },
        {
          id: 4,
          label: this.$t('labels.schedule_frequency.monthly_4')
        },
        {
          id: 6,
          label: this.$t('labels.schedule_frequency.monthly_6')
        },
        {
          id: 12,
          label: this.$t('labels.schedule_frequency.monthly_12')
        }
      ]
    },
    weeksOfMonth () {
      return [
        {
          id: 1,
          label: this.$t('labels.week_of_month.1')
        },
        {
          id: 2,
          label: this.$t('labels.week_of_month.2')
        },
        {
          id: 3,
          label: this.$t('labels.week_of_month.3')
        },
        {
          id: 4,
          label: this.$t('labels.week_of_month.4')
        }
      ]
    }
  },
  watch: {
    'task.task': function (newVal) {
      const task = _.find(this.tasks, { id: newVal })
      if (task) {
        this.task.description = task.description
        this.task.price = task.price
        this.task.contractor_percentage = task.contractor_percentage
        this.task.payment_type = task.payment_type
        this.task.contractor_amount = task.contractor_amount
      }
    }
  },
  methods: {
    dateString (date) {
      return new Sec(date).format('M jS, Y')
    },
    applySort (val) {
      this.sort = val
      this.index()
    },
    index (page, count) {
      if (page) this.page = page
      else page = this.page
      if (count) this.tableRows = count
      else count = this.tableRows
      this.loading = true
      this.$store.dispatch('indexTaskGroups', {
        id: this.id, page, count, sort: this.sort.col, direction: this.sort.dir
      }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.resource.rotation = 'non'
      this.resource.conflict = 'overwrite'
      this.$modal.show('create-' + this.$options.name)
    },
    addTask (group) {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.task)
      this.taskGroup = group.id
      this.$store.dispatch('index', { collection: 'Tasks' })
        .then(() => { this.formLoading = false })
      this.$modal.show('add-task')
    },
    updateTask (group, t) {
      this.$store.commit('axiosError', null)
      this.task.task = t.task
      this.taskGroup = group.id
      this.formLoading = true
      this.$store.dispatch('index', { collection: 'Tasks' })
        .then(() => { this.formLoading = false })
      setTimeout(() => {
        this.task.id = t.id
        this.task.description = t.description
        this.task.price = t.price
        this.task.contractor_percentage = t.contractor_percentage
        this.task.payment_type = t.payment_type
        this.task.contractor_amount = t.contractor_amount
        this.$modal.show('add-task')
      }, 10)
    },
    submitTask () {
      this.$store.dispatch(this.task.id ? 'update' : 'store', {
        collection: this.$options.name,
        path: 'task-groups',
        id: this.taskGroup,
        tag: 'tasks',
        resource: this.task
      }).then(() => {
        this.$modal.hide('add-task')
      })
    },
    destroyTask (group, o) {
      this.taskGroup = group.id
      this.activeId = o.id
      this.$modal.show('destroy-task')
    },
    destructionTask () {
      this.$store.dispatch('destroy', {
        collection: this.$options.name,
        path: 'task-groups',
        id: this.taskGroup,
        tag: 'tasks',
        tag_id: this.activeId,
        update_id: this.taskGroup
      }).then(() => {
        this.$modal.hide('destroy-task')
      })
    },
    pauseTaskPrompt (r, t) {
      this.taskGroupId = r.id
      this.$refs.pauseTask.show(t)
    },
    pauseTask (resource) {
      const config = {
        collection: 'TaskGroups',
        path: 'task-groups',
        id: this.taskGroupId,
        tag: 'tasks',
        resource
      }
      this.$store.dispatch('update', config).then(() => {
        this.$refs.pauseTask.hide()
      })
    },
    playTaskPrompt (r, t) {
      this.taskGroupId = r.id
      this.$refs.playTask.show(t)
    },
    playTask (resource) {
      const config = {
        collection: 'TaskGroups',
        path: 'task-groups',
        id: this.taskGroupId,
        tag: 'tasks',
        resource
      }
      this.$store.dispatch('update', config).then(() => {
        this.$modal.hide('play-task')
      })
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', {
        collection: this.$options.name,
        path: helpers.camelToKebab(helpers.pluralize(this.type)),
        id: this.id,
        tag: helpers.camelToKebab(this.$options.name),
        resource: this.resource
      }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', {
        collection: this.$options.name,
        path: helpers.camelToKebab(helpers.pluralize(this.type)),
        id: this.id,
        tag: helpers.camelToKebab(this.$options.name),
        tag_id: this.activeId
      }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
