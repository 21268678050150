<template>
    <div class="form-group bs-form-group">
        <label :for="name" class="text-uppercase mb-0">{{ label }}</label>
        <div class="custom-file">
            <input type="file" class="custom-file-input" :id="name" @change="input" :multiple="multiple" :accept="accept">
            <label class="custom-file-label shadow-sm border-top-0 border-left-0 border-bottom-1" :for="name" :data-browse="$t('labels.browse')">{{ $t('labels.choose_file') }}</label>
        </div>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
    </div>
</template>

<script>
import bsCustomFileInput from 'bs-custom-file-input'

export default {
  name: 'BSFileField',
  props: ['name', 'label', 'help', 'error', 'multiple', 'accept'],
  mounted () {
    bsCustomFileInput.init()
  },
  methods: {
    input (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      if (this.multiple) this.$emit('input', files)
      else this.$emit('input', files[0])
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.$emit('input', e.target.result)
      }
      reader.readAsDataURL(file)
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>

<style lang="scss">
    .custom-file {
        cursor: pointer;
    }
</style>
