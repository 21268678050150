import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/Login'
import Dashboard from '../views/Dashboard'
import MyJobs from '../views/MyJobs'
import MyEarnings from '../views/MyEarnings'
import Accounts from '../views/Accounts'
import AccountsInvoice from '../views/AccountsInvoice'
import AccountsExpenses from '../views/AccountsExpenses'
import AccountsContractors from '../views/AccountsContractors'
import ClientList from '../views/ClientList'
import Client from '../views/Client'
import ClientTasks from '../views/ClientTasks'
import ClientInvoices from '../views/ClientInvoices'
import ClientSettings from '../views/ClientSettings'
import GlobalTasks from '../views/GlobalTasks'
import Settings from '../views/Settings'
import SettingsAccounts from '../views/SettingsAccounts'
import Profile from '../views/Profile'
import AccountsTransactions from '../views/AccountsTransactions'
import AccountsActivity from '../views/AccountsActivity'
import ContractorAwayEvents from '../views/ContractorAwayEvents'
import DocumentList from '../views/DocumentList'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/my-jobs',
    name: 'MyJobs',
    component: MyJobs,
    props: true
  },
  {
    path: '/my-earnings',
    name: 'MyEarnings',
    component: MyEarnings,
    props: true
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: AccountsContractors,
    props: true
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Accounts,
    props: true
  },
  {
    path: '/expenses',
    name: 'AccountsExpenses',
    component: AccountsExpenses,
    props: true
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: AccountsTransactions,
    props: true
  },
  {
    path: '/activity',
    name: 'AccountsActivity',
    component: AccountsActivity,
    props: true
  },
  {
    path: '/accounts/invoice/:invoice',
    name: 'AccountsInvoice',
    component: AccountsInvoice,
    props: true
  },
  {
    path: '/accounts/contractors',
    name: 'AccountsContractors',
    component: AccountsContractors,
    props: true
  },
  {
    path: '/clients',
    name: 'ClientList',
    component: ClientList
  },
  {
    path: '/client/:client',
    name: 'Client',
    component: Client,
    props: true
  },
  {
    path: '/client/:client/tasks',
    name: 'ClientTasks',
    component: ClientTasks,
    props: true
  },
  {
    path: '/client/:client/invoices',
    name: 'ClientInvoices',
    component: ClientInvoices,
    props: true
  },
  {
    path: '/client/:client/settings',
    name: 'ClientSettings',
    component: ClientSettings,
    props: true
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: GlobalTasks,
    props: true
  },
  {
    path: '/away-events',
    name: 'ContractorAwayEvents',
    component: ContractorAwayEvents,
    props: true
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/settings/accounts',
    name: 'SettingsAccounts',
    component: SettingsAccounts
  },
  {
    path: '/my-profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/user/:user',
    name: 'User',
    component: Profile,
    props: true
  },
  {
    path: '/documents',
    name: 'DocumentList',
    component: DocumentList
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('viewLoading', false)
  if (!store.state.auth && to.name !== 'Login') next({ name: 'Login' })
  if (store.state.auth && to.name === 'Login') next({ name: 'Dashboard' })
  else next()
})

export default router
