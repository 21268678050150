<template>
  <div>
    <div v-if="mobileView">
      <bs-card class="card-info mb-2" v-for="r in resources.data" :key="r.id" :label="r.client_name">
        <bs-alert v-if="r.state.state === 'skipped'" class="alert-danger m-2">{{ $t('messages.skip_visit') }}</bs-alert>
        <bs-alert v-else-if="!r.client_active" class="alert-danger m-2">{{ $t('messages.visit_inactive') }}</bs-alert>
        <bs-alert v-else-if="r.client_suspended" class="alert-danger m-2">{{ $t('labels.suspended') }}</bs-alert>
        <bs-alert v-else-if="r.client_terms === 'cash'" class="alert-success m-2">{{ $t('messages.collect_cash') }}</bs-alert>
        <div class="m-2 text-info rounded" v-if="r.address">
          <small><strong class="text-uppercase">{{ $tc('labels.address', 1) }}:</strong> {{ r.address.readable }}</small><br>
          <small><strong class="text-uppercase">{{ $t('labels.due') }}:</strong>&nbsp;<date :date="r.due_at" /></small><br>
        </div>
        <div class="mx-2" v-if="parseInt(user) === 0">
          <div v-for="u in r.users" :key="'r' + r.id + 'u' + u.id">
            <avatar :user="u" />
          </div>
        </div>
        <div class="m-2">
          <span v-for="task in r.tasks" :key="'t' + task.id">
            {{ task.name }}
            <span v-if="authCan('task_price')">(<span v-html="$n(task.contractor_total, 'currency')"></span>)</span><br>
          </span>
        </div>
        <template v-slot:footer>
          <div class="d-flex pb-2 px-2 justify-content-between">
            <div>
              <bs-badge class="bg-danger text-light" v-if="!r.client_active">
                {{ $t('labels.inactive')}}
              </bs-badge>
              <bs-badge class="bg-danger text-light" v-else-if="r.client_suspended">
                {{ $t('labels.suspended')}}
              </bs-badge>
              <bs-badge class="bg-warning text-light" v-else-if="r.state.state === 'skipped'">
                {{ $t('labels.skipped')}}
              </bs-badge>
              <bs-badge :class="r.state.class" v-else>{{ $t('labels.' + r.state.state) }}</bs-badge>
            </div>
            <bs-actions>
              <template v-slot:primary>
                <bs-action v-if="['complete', 'invoiced'].includes(r.state.state)" :icon="['fas', 'eye']" class="btn-info mr-1" @click="viewReport(r)" style="height:27px"></bs-action>
                <bs-action v-else :icon="['fas', 'exchange-alt']" class="btn-primary mr-1" @click="update(r)" style="height:27px"></bs-action>
                <bs-action v-if="r.state.state !== 'invoiced'" class="btn-info mr-1" :icon="['fal', 'pencil']" @click="updateReport(r)" style="height:27px"></bs-action>
              </template>
              <template v-slot:secondary>
                <button class="dropdown-item" v-if="r.state.state !== 'invoiced'" :icon="['fal', 'pencil']" @click="updateReport(r)">{{ labels.view }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state) && r.state.state !== 'skipped'" @click="skip(true, r)">{{ labels.skip }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state) && r.state.state === 'skipped'" @click="skip(false, r)">{{ labels.unskip }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state)" @click="reschedule(r)">{{ labels.reschedule }}</button>
              </template>
            </bs-actions>
          </div>
        </template>
        <portal to="app-modal">
          <bs-modal :name="'task-group-card-' + r.id" :label="r.client_name">
            <task-group-card :visit="r" :collection="'userSchedules'" @refresh="index" hideLabel />
          </bs-modal>
        </portal>
      </bs-card>
    </div>
    <bs-card v-else class="card-info" :icon="icon" :label="$t('labels.attached_bookings')">
      <bs-table class="table-striped" v-bind="{dataLength, loading}" :cols="6">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.due_at')" :sortable="resourceState.sort" :col="'due_at'" @click="applySort"></bs-th>
            <bs-th v-if="parseInt(user) === 0" :label="$tc('labels.contractor', 0)"></bs-th>
            <bs-th :label="$t('labels.client')"></bs-th>
            <bs-th :label="$t('labels.task_group')"></bs-th>
            <bs-th :label="$t('labels.state')"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
            <date-time :date="r.due_at" />
          </td>
          <td v-if="parseInt(user) === 0">
            <div v-for="u in r.users" :key="'r' + r.id + 'u' + u.id">
              <avatar :user="u" showName />
            </div>
          </td>
          <td :class="authCan('manage_clients') ? 'clickable' : ''" @click="authCan('manage_clients') ? $router.push({ name: 'Client', params: { client: r.client_id }}) : null">
            <span :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
              <span class="font-weight-bold">{{ r.client_name }}</span><br>
              <small v-if="r.address">{{ r.address.readable }}</small>
            </span>
          </td>
          <td :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
            <div v-for="task in r.tasks" :key="'t' + task.id">
              <div :style="task.paused ? 'text-decoration:line-through' : ''">
                {{ task.name }}
                <span v-if="authCan('task_price')">(<span v-html="$n(task.contractor_total, 'currency')"></span>)</span>
              </div>
            </div>
          </td>
          <td>
            <bs-badge class="bg-danger text-light" v-if="!r.client_active">
              {{ $t('labels.inactive')}}
            </bs-badge>
            <bs-badge class="bg-danger text-light" v-else-if="r.client_suspended">
              {{ $t('labels.suspended')}}
            </bs-badge>
            <bs-badge class="bg-warning text-light" v-else-if="r.state.state === 'skipped'">
              {{ $t('labels.skipped')}}
            </bs-badge>
            <bs-badge :class="r.state.class" v-else>{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>
          <td class="t3">
            <bs-actions>
              <template v-slot:primary>
                <bs-action v-if="['complete', 'invoiced'].includes(r.state.state)" :icon="['fas', 'eye']" class="btn-info" @click="viewReport(r)"></bs-action>
                <bs-action v-else :icon="['fas', 'exchange-alt']" class="btn-primary" @click="update(r)"></bs-action>
                <bs-action v-if="r.state.state !== 'invoiced'" class="btn-info" :icon="['fal', 'pencil']" @click="updateReport(r)"></bs-action>
              </template>
              <template v-slot:secondary>
                <button class="dropdown-item" v-if="r.state.state !== 'invoiced'" :icon="['fal', 'pencil']" @click="updateReport(r)">{{ labels.view }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state) && r.state.state !== 'skipped'" @click="skip(true, r)">{{ labels.skip }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state) && r.state.state === 'skipped'" @click="skip(false, r)">{{ labels.unskip }}</button>
                <button class="dropdown-item" v-if="!['complete', 'invoiced'].includes(r.state.state)" @click="reschedule(r)">{{ labels.reschedule }}</button>
              </template>
            </bs-actions>
          </td>
          <portal to="app-modal">
            <bs-modal :name="'task-group-card-' + r.id" :label="r.client_name">
              <task-group-card :visit="r" :collection="'userSchedules'" @refresh="index" hideLabel />
            </bs-modal>
          </portal>
        </tr>
        <template v-slot:tfoot v-if="internalFooter">
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'report-task-group'" :label="$t('labels.report')">
        <task-group-report :report="activeGroup" />
      </bs-modal>
      <bs-modal :name="'swap-contractor'" :label="$t('labels.swap_contractor')">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-if="!formLoading">
            <tuxedo-select :name="'users'" :label="$tc('labels.contractor', 0)" v-model="resource.users" :options="users" :insistLabel="'name'" :multi="true" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'reschedule'" :label="$t('labels.reschedule')">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="slotProps">
              <tuxedo-date :label="$t('labels.date')" :name="'due_at'" v-model="resource.due_at" :insistFormat="'isoDate'" :error="slotProps.error"></tuxedo-date>
          </template>
        </bs-form>
      </bs-modal>
    </portal>
    <portal to="app-footer" v-if="!internalFooter">
      <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '../../helpers'
import { watchWindowSize } from '@/mixins/helpers'
import { authMethods } from '@/mixins/authMethods'

export default {
  name: 'UserSchedules',
  mixins: [watchWindowSize, authMethods],
  props: ['user', 'from', 'invoice'],
  data () {
    return {
      icon: ['fal', 'calendar-week'],
      loading: false,
      formLoading: false,
      activeGroup: null,
      resource: {
        id: null,
        users: null,
        locked: null,
        due_at: Date.now()
      },
      labels: {
        name: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 0),
        create: this.$t('labels.action_resource', { action: this.$t('labels.create'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        edit: this.$t('labels.action_resource', { action: this.$t('labels.edit'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        view: this.$t('labels.action_resource', { action: this.$t('labels.view'), resource: this.$tc('resource.job_card', 1) }),
        delete: this.$t('labels.action_resource', { action: this.$t('labels.delete'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        skip: this.$t('labels.action_resource', { action: this.$t('labels.skip.label'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        unskip: this.$t('labels.action_resource', { action: this.$t('labels.unskip'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        reschedule: this.$t('labels.reschedule')
      },
      internalFooter: Object.prototype.hasOwnProperty.call(this.$attrs, 'internalFooter')
    }
  },
  mounted () {
    if (this.invoice) {
      this.index(1)
    }
  },
  watch: {
    invoice: function () {
      if (!this.loading) this.index(1)
    },
    user: function () {
      if (!this.loading) this.index(1)
    },
    from: function () {
      if (!this.loading) this.index(1)
    }
  },
  computed: {
    ...mapState({
      resourceState: state => state.userSchedulesState,
      resources: state => state.userSchedules,
      users: state => state.usersStore,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    updateReport (r) {
      this.activeGroup = r
      this.$modal.show('task-group-card-' + r.id)
    },
    viewReport (r) {
      this.activeGroup = r
      this.$modal.show('report-task-group')
    },
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    skip (val, r) {
      this.resource = JSON.parse(JSON.stringify(r))
      this.resource.skip = val
      this.submit()
    },
    index (page, count) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      this.loading = true
      this.$store.dispatch('index', {
        path: 'Users',
        id: this.user || '0',
        tag: 'user-schedules',
        collection: this.$options.name,
        params: { page, count, sort: this.resourceState.sort.col, direction: this.resourceState.sort.dir, from: this.from, invoice: this.invoice }
      })
        .then(() => {
          this.loading = false
        })
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.locked = true
      this.$store.dispatch('index', { collection: 'Users' })
      this.$modal.show('swap-contractor')
    },
    submit () {
      this.$store.dispatch('update', {
        collection: 'UserSchedules',
        resource: this.resource
      }).then(() => {
        this.$modal.hide('swap-contractor')
        this.$modal.hide('reschedule')
      })
    },
    reschedule (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.reschedule = true
      this.$modal.show('reschedule')
    }
  }
}
</script>
