<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ resource.name }}</h3>
            <nav-secondary-client :client="client" />
        </route-header>
        <client-suspension />
        <div class="row">
            <div class="col">
                <client-schedules :id="client" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Client',
  props: ['client'],
  data () {
    return {
      domain: null
    }
  },
  computed: {
    ...mapState({
      resource: state => state.client.data
    })
  },
  mounted () {
    this.show()
  },
  methods: {
    show () {
      this.loading = true
      this.$store.commit('viewLoading', true)
      this.$store.dispatch('show', { object: this.$options.name, id: this.client }).then(() => {
        this.$store.commit('viewLoading', false)
        this.loading = false
      })
    }
  }
}
</script>
