export default {
  en: {
    currency: {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'symbol'
    },
    integer: {
      style: 'decimal'
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2
    },
    percentInt: {
      style: 'percent',
      minimumFractionDigits: 0
    }
  }
}
