<template>
  <div class="col">
    <h3 class="text-center pt-3 pb-0 mb-0">{{ report.task_group }}</h3>
    <p class="text-center"><small>
      <date :date="report.due_at" />
    </small></p>
    <table class="table">
      <tr v-for="task in report.tasks" :key="'task' + task.id">
        <td>{{ task.name }}</td>
        <td class="text-right"><span v-if="task.quantity">x{{ task.quantity }}</span></td>
        <td class="text-right text-success"><fa-icon v-if="task.complete" :icon="['fas', 'check']" /></td>
      </tr>
      <tr v-if="report.notes">
        <td colspan="3">
          <p class="font-weight-bold text-uppercase mb-1">{{ $t('labels.notes') }}:</p>
          {{ report.notes }}
        </td>
      </tr>
      <tr v-for="asset in report.assets" :key="'asset' + asset.id">
        <td colspan="3">
          <img :src="asset.path + asset.name" width="100%" /><br>
          <p><small>Photo Taken: <date-time :date="asset.created_at" /></small></p>
        </td>
      </tr>
      <tfoot>
      <tr>
        <td colspan="3" class="text-center">
          <bs-button class="btn-secondary" @click="create()">Email Report</bs-button>
        </td>
      </tr>
      </tfoot>
    </table>
    <bs-modal :name="'create-' + $options.name" :label="$t('labels.action_resource', { action: $t('labels.email'), resource: $t('labels.report', {}, 1) })">
      <bs-form :submit="$t('labels.send')" @submit="submit">
        <template v-slot:default="{ error }">
          <tuxedo-select :name="'users'" :label="$t('labels.email')" :options="filteredContacts" :insistLabel="'email'" :multi="true" v-model="resource.users" :error="error">
            <template v-slot:label="{ option }">
              <span>{{ option.name }}</span>
            </template>
            <template v-slot:content="{ option }">
              <span>{{ option.email }}</span>
            </template>
          </tuxedo-select>
          <tuxedo-input :name="'custom'" :label="'Manual Email Input'" :help="'You can enter custom email addresses (separated by a semi-colon) if this report should be emailed to someone other than what is available from the above list.'" v-model="resource.custom" />
        </template>
      </bs-form>
    </bs-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TaskGroupReport',
  props: ['report'],
  data: function () {
    return {
      resource: {
        users: [],
        custom: null
      }
    }
  },
  mounted () {
    this.$store.dispatch('index', { collection: 'allContacts' })
  },
  computed: {
    ...mapState({
      contacts: state => state.allContacts.data,
      users: state => state.usersStore
    }),
    filteredContacts () {
      const contacts = this._.filter(this.contacts, (c) => { return c.email && c.client_id === this.report.client_id })
      return contacts.concat(this.users)
    }
  },
  methods: {
    create () {
      this.$store.commit('axiosError', null)
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch('store', { collection: this.$options.name, id: this.report.id, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    }
  }
}
</script>
