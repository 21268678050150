<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ resource.name }}</h3>
            <nav-secondary-client :client="client" />
        </route-header>
        <client-suspension />
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-3">
            <bs-card class="card-info text-center">
              <h4 class="mt-3">{{ resource.name }}</h4>
              <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between">
                  <toggle-field :value="resource.active" @input="active($event)" :name="'a'"></toggle-field>
                  <small class="text-uppercase">{{ $t('labels.active') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <div>
                    <fa-icon v-if="resource.auto_invoice" :icon="['fas', 'check']"></fa-icon>
                    <fa-icon v-else :icon="['fas', 'times']"></fa-icon>
                  </div>
                  <small class="text-uppercase">{{ $t('labels.auto_invoice') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <div>
                    <fa-icon v-if="resource.auto_remind" :icon="['fas', 'check']"></fa-icon>
                    <fa-icon v-else :icon="['fas', 'times']"></fa-icon>
                  </div>
                  <small class="text-uppercase">{{ $t('labels.auto_remind') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between" v-if="clientData.billing_address">
                  <small class="text-left">
                    <span v-if="clientData.billing_address.address1">{{ clientData.billing_address.address1 }}<br></span>
                    <span v-if="clientData.billing_address.address2">{{ clientData.billing_address.address2 }}<br></span>
                    <span v-if="clientData.billing_address.address3">{{ clientData.billing_address.address3 }}<br></span>
                    <span v-if="clientData.billing_address.city">{{ clientData.billing_address.city }}</span>
                  </small>
                  <small class="text-uppercase">{{ $t('labels.billing_address') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <small>{{ $t('labels.' + resource.payment_type) }}</small>
                  <small class="text-uppercase">{{ $t('labels.payment_type.label') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <small v-if="resource.payment_method === 'terms'">{{ $t('labels.term_payment') }}</small>
                  <small v-else>{{ $t('labels.cash_payment') }}</small>
                  <small class="text-uppercase">{{ $t('labels.payment_method') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between" v-if="resource.payment_method === 'terms'">
                  <small>{{ resource.payment_terms }}</small>
                  <small class="text-uppercase">{{ $t('labels.payment_terms.label') }}</small>
                </li>
                <li class="list-group-item d-flex justify-content-between" v-if="resource.payment_method === 'terms'">
                  <small>{{ $t('labels.' + resource.invoice_schedule) }}</small>
                  <small class="text-uppercase">{{ $t('labels.invoice_schedule.label') }}</small>
                </li>
              </ul>
              <template v-slot:footer>
                <div class="m-3">
                  <bs-button class="btn-sm btn-info mr-1" @click="update">{{ $t('labels.action_resource', { action: $t('labels.edit'), resource: $t('labels.client') }) }}</bs-button>
                  <bs-button class="btn-sm btn-info" @click="editBilling">{{ $t('labels.action_resource', { action: $t('labels.edit'), resource: 'Billing' }) }}</bs-button>
                </div>
              </template>
            </bs-card>
          </div>
          <div class="col-12 col-md-6 col-lg-8 mb-3">
                <addresses />
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <contacts />
            </div>
        </div>

      <bs-modal :name="'create-' + $options.name" :label="$t('labels.action_resource', { action: $t('labels.edit'), resource: $t('labels.client') })">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="{ error }">
            <div class="row align-items-end">
              <div class="col-8">
                <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error" />
              </div>
              <div class="col-4">
                <toggle-field class="mb-4" :name="'active'" :label="$t('labels.active')" v-model="resource.active" />
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-12">
                <toggle-field class="mb-4" :name="'require_photos'" :label="$t('labels.require_photos.label')"
                              :help="$t('labels.require_photos.help')" v-model="resource.require_photos" />
              </div>
            </div>
          </template>
        </bs-form>
      </bs-modal>

      <bs-modal :name="'billing-' + $options.name" :label="$t('labels.action_resource', { action: $t('labels.edit'), resource: $t('labels.client') })">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="{ error }">
            <div class="row align-items-end">
              <div class="col-8">
                <tuxedo-select :name="'address_id'" :label="$t('labels.billing_address')" v-model="resource.address_id" :options="addresses" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
              </div>
              <div class="col-4">
                <toggle-field class="mb-4" :name="'auto_invoice'" :label="$t('labels.auto_invoice')" v-model="resource.auto_invoice" />
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-6">
                <tuxedo-select :name="'payment_type'" :label="$t('labels.payment_type.label')" v-model="resource.payment_type" :options="paymentTypes" :insistReturn="'id'" :error="error" />
              </div>
              <div class="col-6">
                <tuxedo-select :name="'payment_method'" :label="$t('labels.payment_method')" v-model="resource.payment_method" :options="paymentMethods" :insistReturn="'id'" :error="error" />
              </div>
            </div>
            <div class="row align-items-center" v-if="resource.payment_method === 'terms'">
              <div class="col-6">
                <tuxedo-input :type="'number'" :name="'payment_terms'" :label="$t('labels.payment_terms.label')" :help="$t('labels.payment_terms.help')" v-model="resource.payment_terms" :error="error" />
              </div>
              <div class="col-6">
                <toggle-field class="" :name="'auto_remind'" :label="$t('labels.auto_remind')" v-model="resource.auto_remind" />
              </div>
            </div>
            <tuxedo-select v-if="resource.payment_method === 'terms'" :name="'invoice_schedule'" :label="$t('labels.invoice_schedule.label')" :help="$t('labels.invoice_schedule.help')" v-model="resource.invoice_schedule" :options="invoiceSchedules" :insistReturn="'id'" :error="error" />
            <tuxedo-date v-if="resource.payment_method === 'terms'" :name="'invoice_start'" :label="$t('labels.invoice_start.label')" :help="$t('labels.invoice_start.help')" v-model="resource.invoice_start" :insistFormat="'isoDate'" :error="error" />
          </template>
        </bs-form>
      </bs-modal>

    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ClientSettings',
  props: ['client'],
  data () {
    return {
      domain: null,
      resource: {
        name: '',
        active: false,
        billing_address: null,
        address_id: null,
        invoice_schedule: '',
        invoice_start: null,
        payment_method: '',
        payment_terms: null,
        auto_invoice: null,
        auto_remind: null,
        payment_type: null,
        require_photos: null
      },
      paymentTypes: [
        { id: 'postpaid', label: this.$t('labels.postpaid') },
        { id: 'prepaid', label: this.$t('labels.prepaid') }
      ],
      paymentMethods: [
        { id: 'terms', label: this.$t('labels.term_payment') },
        { id: 'cash', label: this.$t('labels.cash_payment') }
      ],
      invoiceSchedules: [
        { id: 'month', label: this.$t('labels.month') },
        { id: 'fortnight', label: this.$t('labels.fortnight') },
        { id: 'week', label: this.$t('labels.week') }
      ]
    }
  },
  computed: {
    ...mapState({
      addresses: state => state.addresses.data,
      clientData: state => state.client.data
    })
  },
  mounted () {
    this.resource = Object.assign({}, this.clientData)
    this.resource.invoice_start = this.clientData.invoice_start
  },
  methods: {
    active (val) {
      this.resource.active = val
      this.submit()
    },
    update () {
      this.$modal.show('create-' + this.$options.name)
    },
    editBilling () {
      this.$modal.show('billing-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: 'Clients', resource: this.resource }).then((res) => {
        this.$store.commit('processResponse', { resource: 'client', data: res.data })
        this.$modal.hide('create-' + this.$options.name)
        this.$modal.hide('billing-' + this.$options.name)
      })
    }
  }
}
</script>
