<template>
  <div>
    <tuxedo-select :name="'task'" :label="$t('labels.task')" v-model="inputValue" :options="tasks"
                   :insistReturn="'id'" :insistLabel="'name'" :error="error"
    >
      <template v-slot:action>
        <div class="tuxedo-action-button" @click="create">New Task</div>
      </template>
    </tuxedo-select>
    <task-form v-if="taskFormActive" @closed="taskFormActive = false" @task="created" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TaskField',
  props: ['value', 'error'],
  data () {
    return {
      taskFormActive: false
    }
  },
  computed: {
    ...mapState({
      tasks: state => state.tasksStore
    }),
    inputValue: {
      get () {
        return this.value ?? this.default
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    created (id) {
      this.inputValue = parseInt(id)
    },
    create () {
      this.$store.commit('axiosError', null)
      this.taskFormActive = true
    }
  }
}
</script>

<style>
.tuxedo-action-button {
  background: #f1f1f1;
  border: none;
  border-left: 1px solid lightgray;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}
</style>
