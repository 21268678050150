<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="5">
        <template v-slot:thead>
          <tr>
            <th scope="col">{{ $t('labels.name') }}</th>
            <th scope="col">{{ $t('labels.number') }}</th>
            <th scope="col">{{ $t('labels.email') }}</th>
            <th scope="col">{{ $t('labels.type') }}</th>
            <th scope="col"></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.name }} <fa-icon v-if="r.notes" v-tooltip="r.notes" class="ml-2 text-warning clickable" :icon="['fas', 'sticky-note']" /></td>
          <td>{{ r.number }}</td>
          <td>{{ r.email }}</td>
          <td>{{ $tc('labels.' + r.type, 1) }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="slotProps.error"></tuxedo-input>
          <tuxedo-input :name="'number'" :label="$t('labels.phone')" v-model="resource.number" :error="slotProps.error"></tuxedo-input>
          <tuxedo-input :name="'email'" :label="$t('labels.email')" v-model="resource.email" :error="slotProps.error"></tuxedo-input>
          <tuxedo-radio :name="'type'" :label="$t('labels.type')" :options="{ contact: 'Contact', account: 'Account' }" v-model="resource.type" :error="slotProps.error"></tuxedo-radio>
          <text-field :name="'notes'" :label="$t('labels.notes')" v-model="resource.notes" :error="slotProps.error"></text-field>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'Contacts',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'id-card'],
      resource: {
        name: '',
        phone: '',
        email: '',
        notes: ''
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.contacts,
      resourceState: state => state.contactsState
    })
  },
  methods: {
    index (page, count, filters) {
      this.handleState(page, count, filters)
      this.loading = true
      this.$store.dispatch('index', { path: 'Clients', id: this.$route.params.client, tag: 'contacts', collection: this.$options.name }).then(() => {
        this.loading = false
      })
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { path: 'Clients', id: this.$route.params.client, tag: 'contacts', collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { path: 'Clients', id: this.$route.params.client, tag: 'contacts', tag_id: this.activeId, collection: this.$options.name }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
