<template>
  <bs-modal :name="formName" :label="labels.form" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.suspend')" @submit="$emit('submit', form)">
      <template v-slot:default="{ error }">
        <tuxedo-text :name="'suspend_reason'" :label="$t('labels.suspend_reason.label')"
                     :help="$t('labels.suspend_reason.help')" v-model="form.suspend_reason" :error="error" />
        <tuxedo-date :name="'suspend_to'" :label="$t('labels.suspend_to.label')" v-model="form.suspend_to"
                     :help="$t('labels.suspend_to.help')" :insistFormat="'isoDate'" :error="error" />
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { makeId } from '@/mixins/helpers'

export default {
  name: 'SuspendClientForm',
  mixins: [makeId],
  computed: {
    formName () { return 'suspend-client-' + this.makeId() }
  },
  data () {
    return {
      form: {
        id: null,
        suspend_reason: null,
        suspend_to: null
      },
      labels: {
        form: this.$t('labels.action_resource', { action: this.$t('labels.suspend'), resource: this.$tc('resource.client', 1) })
      }
    }
  },
  methods: {
    show (t) {
      this.form.id = t.id
      this.$modal.show(this.formName)
    },
    hide () {
      this.$modal.hide(this.formName)
    }
  }
}
</script>
