<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.upload }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="4">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.type')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.size')" class="text-right"></bs-th>
            <bs-th :label="$t('labels.expires_at')" class="text-right"></bs-th>
            <bs-th scope="col"></bs-th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ $t('labels.documents.' + r.name) }}</td>
          <td class="text-right">{{ r.size }}</td>
          <td class="text-right">
            <span v-if="r.expires_at"><date :date="r.expires_at" /></span>
          </td>
          <td class="t2">
            <bs-action :icon="['fal', 'download']" :tip="labels.download" class="btn-info" @click="download(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.upload">
        <bs-form :submit="$t('labels.submit')" @submit="upload">
          <template v-slot:default="{ error }">
            <tuxedo-select :name="'type'" :label="$t('labels.type')" :options="types" v-model="resource.name" :error="error" />
            <tuxedo-date :name="'expires_at'" :label="$t('labels.expires_at')" v-model="resource.expires_at" :error="error" />
            <file-field :name="'file'" :label="$t('labels.file')" v-model="resource.file" :accept="'application/pdf'" :help="$t('messages.pdf_only')" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'UserPrivateDocuments',
  props: ['user'],
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'cabinet-filing'],
      resource: {
        type: '',
        file: null,
        expires_at: null
      },
      types: [
        {
          id: 'insurance',
          label: this.$t('labels.documents.insurance')
        },
        {
          id: 'vaccination',
          label: this.$t('labels.documents.vaccination')
        }
      ]
    }
  },
  computed: {
    ...mapState({
      resources: state => state.userPrivateDocuments,
      resourceState: state => state.userPrivateDocumentsState
    }),
    apiTarget () { return ['users', this.user, 'documents'] }
  },
  methods: {
    beforeCreate () {
      this.resource.file = null
    },
    download (r) {
      this.$store.dispatch('download', {
        path: 'documents/' + r.id + '/download',
        accept: 'application/pdf'
      })
    }
  }
}
</script>
