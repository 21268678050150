import pluralize from 'pluralize'

export default {
  clearData (data) {
    for (const key in data) {
      if (data[key] !== null && data[key] instanceof File) {
        data[key] = null
      } else if (data[key] !== null && data[key] instanceof FileList) {
        data[key] = null
      } else if (data[key] !== null && typeof (data[key]) === 'object') {
        this.clearData(data[key])
      } else {
        data[key] = null
      }
    }
  },
  copyData (from, to) {
    this.clearData(to)
    if (to !== null && typeof to === 'object') {
      for (const key in to) {
        if (to[key] !== null && typeof (to[key]) === 'object' && from[key] !== null) {
          this.copyData(from[key], to[key])
        } else if (from[key] !== null) {
          to[key] = from[key]
        }
      }
    } else {
      to = from
    }
  },
  firstToLower (string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
  },
  firstToUpper (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },
  camelToKebab (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
  },
  camelToString (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  },
  kebabToCamel (string) {
    return string.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase()
    })
  },
  camelToSpace (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  },
  camelToSnake (string) {
    return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
  },
  snakeToCamel (string) {
    return string.replace(/(_\w)/g, function (m) {
      return m[1].toUpperCase()
    })
  },
  spaceToPlus (string) {
    return string.replace(/\s/g, '+')
  },
  parseURL (string) {
    return new URL(string)
  },
  randStr (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  key () {
    return this.randStr(8)
  },
  pluralize,
  singular (string) {
    return pluralize.singular(string)
  },
  base64ToFile (base64, filename) {
    const arr = base64.split(',')
    if (!arr[0] || !arr[1]) return null
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  },
  stringToSMS (message) {
    console.log(message)
    message = message.replaceAll(' ', '%20')
    message = message.replaceAll('\n', '%0a')
    message = message.replaceAll('\'', '%27')
    message = message.replaceAll('&#039;', '%27')
    return message
  }
}
