<template>
    <div class="card shadow-lg h-100" :class="cardClass">
        <header class="card-header d-flex justify-content-between align-content-center text-uppercase bg-light" :class="icon || label ? 'p-2' : 'p-0'">
            <span class="my-auto" v-if="icon || label">
                <fa-icon v-if="icon" :icon="icon"></fa-icon>
                <strong class="ml-1">{{ label }}</strong>
            </span>
            <slot name="header"></slot>
        </header>
        <main class="card-body p-0 position-relative" :class="mainClass">
            <span v-if="secure" class="secure-icon"><fa-icon :icon="['fas', 'lock']" /></span>
            <slot></slot>
        </main>
        <footer>
            <slot name="footer" class="card-footer"></slot>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'BSCard',
  props: ['icon', 'label', 'secure', 'allowOverflow'],
  computed: {
    cardClass () {
      let cl = ''
      if (this.secure) cl += ' secure'
      if (!this.allowOverflow) cl += ' overflow-hidden'
      return cl
    },
    mainClass () {
      let cl = ''
      if (!this.allowOverflow) cl += ' overflow-hidden'
      return cl
    }
  }
}
</script>

<style lang="scss">
    .card-header {
        font-size: 0.875rem;
    }

    .card-body {
        .secure-icon {
            svg {
                font-size: 7rem;
                position: absolute;
                bottom: 0;
                left: -30px;
                opacity: 0.2;
            }

            ~ * {
               position: relative;
               z-index: 1;
            }
        }
    }
</style>
