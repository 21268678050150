import { mapMutations } from 'vuex'
import helpers from '../helpers'
import { debounce } from 'debounce'

export const resourceControl = {
  data () {
    return {
      loading: false,
      dataLoading: false,
      activeId: null,
      labels: {
        name: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 0),
        create: this.$t('labels.action_resource', { action: this.$t('labels.create'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        edit: this.$t('labels.action_resource', { action: this.$t('labels.edit'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        delete: this.$t('labels.action_resource', { action: this.$t('labels.delete'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        send: this.$t('labels.action_resource', { action: this.$t('labels.send'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        view: this.$t('labels.action_resource', { action: this.$t('labels.view'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        assign: this.$t('labels.action_resource', { action: this.$t('labels.assign'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        download: this.$t('labels.action_resource', { action: this.$t('labels.download'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        upload: this.$t('labels.action_resource', { action: this.$t('labels.upload'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        email: this.$t('labels.action_resource', { action: this.$t('labels.email'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        text: this.$t('labels.action_resource', { action: this.$t('labels.text'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        generate: this.$t('labels.action_resource', { action: this.$t('labels.generate'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) })
      }
    }
  },
  computed: {
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    },
    searchPhrase: {
      get () {
        return this.resourceState.search ? this.resourceState.search.phrase : null
      },
      set (val) {
        this.applySearch({ active: true, phrase: val })
      }
    },
    config () {
      const config = { collection: this.$options.name, icon: this.icon }
      if (this.activeId) config.id = this.activeId
      if (this.apiTarget) {
        Object.assign(config, {
          path: this.apiTarget[0],
          id: this.apiTarget[1],
          tag: this.apiTarget[2]
        })
        if (this.activeId) config.tag_id = this.activeId
      }
      return config
    }
  },
  mounted () {
    this.search = debounce(this.search, 500)
    this.index(this.page)
  },
  methods: {
    ...mapMutations([
      'pushState',
      'pushFilter'
    ]),
    find (objects, match, value, key) {
      const obj = this._.find(objects, function (o) { return o[match] === value })
      if (obj && key && obj[key]) return obj[key]
      if (obj) return obj
      return null
    },
    search () {
      this.index(1)
    },
    applySort (val) {
      this.pushState({ view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    applySearch (val) {
      this.pushState({ view: this.$options.name, key: 'search', data: val })
      setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus()
        }
      }, 200)
      this.search(1)
    },
    handleState (page, count, filters) {
      if (page) this.pushState({ view: this.$options.name, key: 'page', data: page })
      if (count) this.pushState({ view: this.$options.name, key: 'tableRows', data: count })
      if (filters) this.pushFilter({ view: this.$options.name, data: filters })
    },
    index (page, count, filters) {
      this.handleState(page, count, filters)
      this.loading = true
      this.$store.dispatch('index', this.config).then(() => { this.loading = false })
    },
    beforeCreate () {},
    create () {
      this.beforeCreate()
      this.$store.commit('axiosError', null)
      helpers.clearData(this.resource)
      this.$modal.show('create-' + this.$options.name)
      this.afterCreate()
    },
    afterCreate () {},
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
        this.afterSubmit()
      })
    },
    upload () {
      this.$store.dispatch('chunk', {
        ...this.config,
        resource: this.resource,
        message: this.$t('messages.uploading_files'),
        callback: () => { this.index() }
      }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
        this.afterSubmit()
      })
    },
    afterSubmit () {},
    destruction () {
      this.$store.dispatch('destroy', this.config).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
      this.activeId = null
    }
  }
}
