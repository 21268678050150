<template>
  <span class="btn btn-spacer btn-sm shadow-sm btn-action rounded-circle" @click="$emit('click')" v-tooltip.left="tip">
    <slot>
      <fa-icon v-if="icon" :icon="icon"></fa-icon>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'BTNAction',
  props: ['icon', 'tip']
}
</script>

<style lang="scss">
.btn.btn-action {
  transition: all .15s ease;
  border-radius: 1rem;
  font-size: 0.69rem;
  padding: 0.25rem 0.43rem;
  width: 26px;
}

.shadow-sm {
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);

  &:hover {
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    transform: translateY(-1px);
  }
}
</style>
