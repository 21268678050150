<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.documents') }}</h3>
    </route-header>
    <div class="row">
      <div class="col-12">
        <expense-documents v-if="authCan('manage_expenses')" class="mb-3" />
        <documents />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'DocumentList',
  mixins: [authMethods],
  computed: {
    ...mapState({
      auth: state => state.auth
    })
  }
}
</script>
