<template>
    <div>
        <div v-if="address.data.length > 0">
            <bs-card v-for="add in address.data" :key="'add' + add.id" class="card-info" :icon="['fal', 'map-marker-alt']" :label="$t('labels.' + camelToSnake($options.name))">
                <template v-slot:header>
                    <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(add)"></bs-action>
                </template>
                <div class="card-body">
                    <p class="card-text">
                        <span v-if="add.name">{{ add.name }}<br></span>
                        <span v-if="add.phone1">{{ add.phone1 }}<br></span>
                        <span v-if="add.phone2">{{ add.phone2 }}<br></span>
                        <span v-if="add.address1">{{ add.address1 }}<br></span>
                        <span v-if="add.address2">{{ add.address2 }}<br></span>
                        <span v-if="add.address3">{{ add.address3 }}<br></span>
                        <span v-if="add.city">{{ add.city }}</span>
                    </p>
                </div>
            </bs-card>
        </div>
        <bs-card v-else class="card-info" :icon="['fal', 'map-marker-alt']" :label="$t('labels.' + camelToSnake($options.name))">
            <template v-slot:header>
                <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(null)"></bs-action>
            </template>
        </bs-card>
        <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
            <bs-form :submit="$t('labels.submit')" @submit="submit">
                <template v-slot:default="{ error }">
                    <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
                    <tuxedo-input :name="'phone1'" :label="$t('labels.phone')" v-model="resource.phone1" :error="error"></tuxedo-input>
                    <tuxedo-input :name="'phone2'" v-model="resource.phone2" :error="error"></tuxedo-input>
                    <tuxedo-input :name="'address1'" :label="$t('labels.address')" v-model="resource.address1" :error="error"></tuxedo-input>
                    <tuxedo-input :name="'address2'" v-model="resource.address2" :error="error"></tuxedo-input>
                    <tuxedo-input :name="'address3'" v-model="resource.address3" :error="error"></tuxedo-input>
                    <tuxedo-select :name="'division_id'" :label="$t('labels.state')" v-model="resource.division_id" :options="divisions.data" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
                    <tuxedo-select v-if="cities" :loading="cities_loading" :name="'city_id'" :label="$t('labels.suburb')" v-model="resource.city_id" :options="cities.data" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
                </template>
            </bs-form>
        </bs-modal>
        <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
            <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
                {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
            </bs-form>
        </bs-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Address',
  mounted () {
    this.index()
  },
  data () {
    return {
      resource: {
        id: null,
        name: '',
        phone1: '',
        phone2: '',
        address1: '',
        address2: '',
        address3: '',
        division_id: null,
        city_id: null,
        client_id: null
      },
      cities_loading: false
    }
  },
  watch: {
    'resource.division_id': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.showCities(newVal)
      }
    }
  },
  computed: {
    ...mapState({
      address: state => state.address,
      divisions: state => state.divisions,
      cities: state => state.cities,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.tableRows - length, 0)
    }
  },
  methods: {
    showCities (id) {
      this.cities_loading = true
      this.$store.dispatch('index', { collection: 'Cities', id })
        .then(() => {
          this.cities_loading = false
        })
    },
    showDivisions () {
      this.$store.dispatch('index', { collection: 'Divisions' })
    },
    applySort (val) {
      this.sort = val
      this.index()
    },
    index (page, count) {
      if (page) this.page = page
      else page = this.page
      if (count) this.tableRows = count
      else count = this.tableRows
      this.loading = true
      this.$store.dispatch('index', { collection: this.$options.name, id: this.$route.params.client }).then(() => {
        this.loading = false
      })
    },
    update (o) {
      this.$store.commit('axiosError', null)
      if (o) this.resource = JSON.parse(JSON.stringify(o))
      this.resource.client_id = this.$route.params.client
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { collection: this.$options.name, id: this.activeId }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }

}
</script>
