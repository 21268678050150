<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <div>
          <bs-button
              v-if="resourceState && resourceState.filters && resourceState.filters.client"
              class="btn-sm bg-secondary text-white mr-2"
              @click="accountStatement">{{ $t('labels.account_statement') }}</bs-button>
          <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
        </div>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="9">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.number')" :sortable="resourceState.sort" :col="'number'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.issued')" :sortable="resourceState.sort" :col="'issued_at'" @click="applySort" style="min-width:120px;"></bs-th>
            <bs-th :label="$t('labels.last_sent')" :sortable="resourceState.sort" :col="'last_sent'" @click="applySort" style="min-width:120px;"></bs-th>
            <bs-th :label="$t('labels.client')" :sortable="resourceState.sort" :col="'clients.name'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.payment_terms.label')" style="min-width:220px"></bs-th>
            <bs-th :label="$t('labels.avg_time_to_pay')"></bs-th>
            <bs-th :label="$t('labels.total')"></bs-th>
            <bs-th class="text-right" :label="$t('labels.state')" :sortable="resourceState.sort" :col="'state'" @click="applySort"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.number }}</td>
          <td><date v-if="r.issued_at" :date="r.issued_at" /></td>
          <td><time-ago v-if="r.last_sent" :date="r.last_sent" /></td>
          <td :class="authCan('manage_clients') ? 'clickable' : ''" @click="authCan('manage_clients') ? $router.push({ name: 'Client', params: { client: r.client_id }}) : null">
            {{ r.client }}
          </td>
          <td><span v-html="r.terms" /></td>
          <td><span v-if="r.time_to_pay > 0">{{ $t('labels.time_to_pay', { days: r.time_to_pay }) }}</span></td>
          <td>{{ $n(r.amount, 'currency') }}</td>
          <td class="text-right">
            <bs-badge :class="r.state.class">{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>
          <td class="t2">
            <bs-actions>
              <template v-slot:primary>
                <div class="d-inline-block mr-1" v-if="!['paid', 'cancelled'].includes(r.state.state)">
                  <bs-action v-if="['sent', 'seen', 'suspended'].includes(r.state.state)" :icon="['fas', 'dollar-sign']" :tip="$t('labels.mark_paid')" class="btn-secure" @click="paidPrompt(r)"></bs-action>
                  <bs-action v-if="['open'].includes(r.state.state)" :icon="['fas', 'paper-plane']" :tip="labels.send" class="btn-success" @click="prepare(r)"></bs-action>
                  <bs-action v-if="['overdue', 'partial'].includes(r.state.state)" :icon="['fas', 'paper-plane']" :tip="$t('labels.send_invoice_reminder')" class="btn-success" @click="prepareReminder(r)"></bs-action>
                  <bs-action v-if="['reminded'].includes(r.state.state)" :icon="['fas', 'paper-plane']" :tip="$t('labels.send_invoice_notice')" class="btn-success" @click="prepareNotice(r)"></bs-action>
                  <bs-action v-if="['notice'].includes(r.state.state)" :icon="['fas', 'paper-plane']" :tip="$t('labels.send_suspension_warning')" class="btn-warning" @click="prepareWarning(r)"></bs-action>
                  <bs-action v-if="['warning'].includes(r.state.state)" :icon="['fal', 'ban']" :tip="$t('labels.suspend_service')" class="btn-danger" @click="prepareSuspension(r)"></bs-action>
                </div>
                <bs-action v-else :icon="['fas', 'eye']" :tip="labels.view" class="btn-primary" @click="$router.push({ path: '/accounts/invoice/' + r.id })"></bs-action>
              </template>
              <template v-slot:secondary>
                <button class="dropdown-item" v-if="['reminded', 'notice'].includes(r.state.state)" @click="prepareReminder(r)">{{ $t('labels.send_invoice_reminder') }}</button>
                <button class="dropdown-item" v-if="!['partial', 'cancelled', 'paid', 'cash'].includes(r.state.state)" @click="$router.push({ path: '/accounts/invoice/' + r.id })">{{ labels.edit }}</button>
                <button class="dropdown-item" @click="prepare(r)">{{ labels.send }}</button>
                <button class="dropdown-item" v-if="!['cancelled', 'paid', 'cash'].includes(r.state.state)" @click="paidPrompt(r)">{{ $t('labels.mark_paid') }}</button>
                <button class="dropdown-item" v-if="!['partial', 'cancelled', 'paid', 'cash'].includes(r.state.state)" @click="destroy(r)">{{ labels.delete }}</button>
              </template>
            </bs-actions>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          <tuxedo-select :name="'client_id'" :label="$t('labels.client')" v-model="resource.client_id" :options="clients" :insistReturn="'id'" :insistLabel="'name'" :error="slotProps.error" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'send-' + $options.name" :label="labels.send">
      <bs-form :submit="$t('labels.send')" @submit="send">
        {{ $t('messages.send', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'send-reminder-' + $options.name" :label="$t('labels.send_invoice_reminder')">
      <bs-form :submit="$t('labels.send')" @submit="sendReminder">
        {{ $t('messages.send_reminder', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'send-notice-' + $options.name" :label="$t('labels.send_invoice_notice')">
      <bs-form :submit="$t('labels.send')" @submit="sendNotice">
        {{ $t('messages.send_notice', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'send-warning-' + $options.name" :label="$t('labels.send_suspension_warning')">
      <bs-form :destroy="$t('labels.send_suspension_warning')" @submit="sendWarning">
        {{ $t('messages.send_warning', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'suspend-service-' + $options.name" :label="$t('labels.suspend_service')">
      <bs-form :destroy="$t('labels.suspend_service')" @submit="suspendService">
        {{ $t('messages.suspend_service', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'paid-' + $options.name" :label="$t('labels.mark_paid')">
      <bs-form :submit="$t('labels.confirm')" @submit="paid">
        {{ $t('messages.confirm_paid', { invoice: activeId, amount: $n(activeAmount, 'currency') }) }}
      </bs-form>
    </bs-modal>
    <bs-modal :name="'statement-' + $options.name" :label="$t('labels.account_statement')">
      <bs-form :submit="$t('labels.submit')" @submit="generateStatement">
        <template v-slot:default="slotProps">
          <tuxedo-date :name="'range'" :label="$t('labels.date_range')" v-model="statement.range" :range="true" :shortcuts="shortcuts" :error="slotProps.error"></tuxedo-date>
          <tuxedo-radio :name="'action'" v-model="statement.action" :options="{ email: $t('labels.email'), download: $t('labels.download') }" :error="slotProps.error"></tuxedo-radio>
        </template>
      </bs-form>
    </bs-modal>
    <portal to="app-footer">
      <pagination :data="resources" :state="resourceState" @pushPage="index" :filters="filters"></pagination>
    </portal>
  </div>
</template>

<script>
import submit from '../../router/submit'
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import { financialShortcutDates } from '../../mixins/financialShortcutDates'
import { authMethods } from '../../mixins/authMethods'

export default {
  name: 'Invoices',
  mixins: [resourceControl, financialShortcutDates, authMethods],
  data () {
    return {
      icon: ['fal', 'books'],
      resource: {
        client_id: null
      },
      filters: {
        invoiceState: true
      },
      statement: {
        range: null,
        action: null
      },
      activeAmount: 0
    }
  },
  computed: {
    ...mapState({
      resources: state => state.invoices,
      resourceState: state => state.invoicesState,
      clients: state => state.clientsStore
    })
  },
  methods: {
    accountStatement () {
      if (!this.statement.account) this.statement.account = 'email'
      this.$modal.show('statement-' + this.$options.name)
    },
    generateStatement () {
      if (this.statement.action === 'download') {
        this.$store.dispatch('download', {
          path: 'clients/' + this.resourceState.filters.client + '/download-statement',
          resource: this.statement,
          accept: 'application/pdf'
        }).then(() => {
          this.$modal.hide('statement-' + this.$options.name)
        })
      } else if (this.statement.action === 'email') {
        this.$store.commit('submitting', true)
        submit.post('api/clients/' + this.resourceState.filters.client + '/send-statement', this.statement).then(() => {
          this.$store.commit('submitting', false)
          this.$modal.hide('statement-' + this.$options.name)
        })
      }
    },
    prepare (o) {
      this.activeId = o.id
      this.$modal.show('send-' + this.$options.name)
    },
    prepareReminder (o) {
      this.activeId = o.client_id
      this.$modal.show('send-reminder-' + this.$options.name)
    },
    prepareNotice (o) {
      this.activeId = o.client_id
      this.$modal.show('send-notice-' + this.$options.name)
    },
    prepareWarning (o) {
      this.activeId = o.client_id
      this.$modal.show('send-warning-' + this.$options.name)
    },
    prepareSuspension (o) {
      this.activeId = o.client_id
      this.$modal.show('suspend-service-' + this.$options.name)
    },
    send () {
      this.$store.commit('submitting', true)
      submit.get('api/invoices/' + this.activeId + '/send').then((res) => {
        this.$store.commit('processResponse', { collection: 'invoices', data: res.data })
        this.$store.commit('submitting', false)
        this.$modal.hide('send-' + this.$options.name)
      })
    },
    sendReminder () {
      this.$store.commit('submitting', true)
      submit.get('api/clients/' + this.activeId + '/send-outstanding-reminder').then((res) => {
        this.$store.commit('processResponse', { data: res.data, subset: 'invoices' })
        this.$store.commit('submitting', false)
        this.$modal.hide('send-reminder-' + this.$options.name)
      })
    },
    sendNotice () {
      this.$store.commit('submitting', true)
      submit.get('api/clients/' + this.activeId + '/send-notice-to-pay').then((res) => {
        this.$store.commit('processResponse', { data: res.data, subset: 'invoices' })
        this.$store.commit('submitting', false)
        this.$modal.hide('send-notice-' + this.$options.name)
      })
    },
    sendWarning () {
      this.$store.commit('submitting', true)
      submit.get('api/clients/' + this.activeId + '/send-suspension-warning').then((res) => {
        this.$store.commit('processResponse', { data: res.data, subset: 'invoices' })
        this.$store.commit('submitting', false)
        this.$modal.hide('send-warning-' + this.$options.name)
      })
    },
    suspendService () {
      this.$store.commit('submitting', true)
      submit.get('api/clients/' + this.activeId + '/suspend-service').then((res) => {
        this.$store.commit('processResponse', { data: res.data, subset: 'invoices' })
        this.$store.commit('submitting', false)
        this.$modal.hide('suspend-service-' + this.$options.name)
      })
    },
    paidPrompt (o) {
      this.activeId = o.id
      this.activeAmount = o.amount
      this.$modal.show('paid-' + this.$options.name)
    },
    paid () {
      this.$store.commit('submitting', true)
      submit.get('api/invoices/' + this.activeId + '/paid').then((res) => {
        this.$store.commit('processResponse', { collection: 'invoices', data: res.data })
        this.$store.commit('submitting', false)
        this.$modal.hide('paid-' + this.$options.name)
        this.activeId = null
      })
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then((res) => {
        this.$router.push({ path: '/accounts/invoice/' + res.data.data.id })
      })
    }
  }
}
</script>
