<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="4">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.date_range')" :sortable="resourceState.sort" :col="'start'" @click="applySort"></bs-th>
            <th scope="col">{{ $t('labels.length') }}</th>
            <th scope="col">{{ $t('labels.description') }}</th>
            <th scope="col"></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td><date :date="r.start" /> - <date :date="r.end" /></td>
          <td>{{ $tc('labels.count_days', r.length) }}</td>
          <td>{{ r.description }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="{ error }">
            <tuxedo-date :name="'dateRange'" :label="$t('labels.date_range')" v-model="resource.range" :insistFormat="'isoDate'" :range="true" :error="error" />
            <tuxedo-input :name="'description'" :label="$t('labels.description')" v-model="resource.description" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'AwayEvents',
  props: ['user'],
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'hiking'],
      resource: {
        user_id: null,
        range: [],
        description: ''
      }
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      resources: state => state.awayEvents,
      resourceState: state => state.awayEventsState
    }),
    apiTarget () { return ['users', this.user, 'away-events'] }
  },
  methods: {
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.resource.user_id = this.user
      this.$modal.show('create-' + this.$options.name)
    },
    afterSubmit () {
      this.$store.dispatch('index', {
        collection: 'ConflictingSchedules',
        path: 'users',
        id: this.user,
        tag: 'conflicting-schedules'
      })
    }
  }
}
</script>
