<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="4">
        <template v-slot:thead>
          <tr>
            <th scope="col">{{ $t('labels.name') }}</th>
            <th scope="col">{{ $t('labels.phone') }}</th>
            <th scope="col">{{ $tc('labels.address', 1) }}</th>
            <th scope="col"></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.name }}</td>
          <td>
            <div v-if="r.phone1">{{ r.phone1 }}</div>
            <div v-if="r.phone2">{{ r.phone2 }}</div>
          </td>
          <td>
            <span v-if="r.address1">{{ r.address1 }}<br></span>
            <span v-if="r.address2">{{ r.address2 }}<br></span>
            <span v-if="r.address3">{{ r.address3 }}<br></span>
            <span v-if="r.city">{{ r.city }}</span>
          </td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index" :counts="[5, 10]"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="{ error }">
            <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
            <tuxedo-input :name="'phone1'" :label="$t('labels.phone')" v-model="resource.phone1" :error="error"></tuxedo-input>
            <tuxedo-input :name="'phone2'" v-model="resource.phone2" :error="error"></tuxedo-input>
            <tuxedo-input :name="'address1'" :label="$tc('labels.address', 1)" v-model="resource.address1" :error="error"></tuxedo-input>
            <tuxedo-input :name="'address2'" v-model="resource.address2" :error="error"></tuxedo-input>
            <tuxedo-input :name="'address3'" v-model="resource.address3" :error="error"></tuxedo-input>
            <tuxedo-select :name="'region_id'" :label="$t('labels.state')" v-model="resource.region_id" :options="regions.data || []" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
            <tuxedo-select :name="'city_id'" :label="$t('labels.suburb')" v-model="resource.city_id" :options="filteredCities || []" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'Addresses',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'map-marker-alt'],
      resource: {
        id: null,
        name: '',
        phone1: '',
        phone2: '',
        address1: '',
        address2: '',
        address3: '',
        region_id: null,
        city_id: null,
        client_id: null
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.addresses,
      resourceState: state => state.addressesState,
      resourceStore: state => state.addressesStore,
      regions: state => state.regions,
      cities: state => state.suburbsStore
    }),
    filteredCities () {
      return this._.filter(this.cities, (c) => c.region_id === this.resource.region_id)
    }
  },
  methods: {
    index (page, count, filters) {
      this.handleState(page, count, filters)
      this.loading = true
      this.$store.dispatch('index', { path: 'Clients', id: this.$route.params.client, tag: 'addresses', collection: this.$options.name }).then(() => {
        this.loading = false
      })
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', {
        path: 'Clients',
        id: this.$route.params.client,
        tag: 'addresses',
        collection: this.$options.name,
        resource: this.resource
      }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { path: 'Clients', id: this.$route.params.client, tag: 'addresses', tag_id: this.activeId, collection: this.$options.name }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
