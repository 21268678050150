<template>
  <bs-modal :name="'create-' + $options.name" :label="labels.upload" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.submit')" @submit="upload">
      <template v-slot:default="{ error }">
        <tuxedo-input :name="'number'" :label="$t('labels.number')" v-model="resource.number" :error="error" />
        <tuxedo-date :name="'issued_at'" :label="$t('labels.issued')" v-model="resource.issued_at" :insistFormat="'isoDate'" :error="error" />
        <div class="row">
          <div class="col">
            <tuxedo-input :name="'total'" :label="$t('labels.total')" v-model="resource.total" :error="error" />
          </div>
          <div class="col">
            <tuxedo-input :name="'tax'" :label="$t('labels.tax_total')" :help="$t('tip.vendor_invoice_tax')" v-model="resource.tax" :error="error" />
          </div>
        </div>
        <file-field :name="'invoice'" :label="$tc('labels.invoice', 1)" v-model="resource.invoice" :accept="'application/pdf'" :help="$t('messages.pdf_only')" :error="error" />
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
export default {
  name: 'UploadInvoiceForm',
  data () {
    return {
      resource: {
        number: null,
        user_id: null,
        issued_at: null,
        invoice: null,
        total: null,
        tax: null
      },
      labels: {
        upload: this.$t('labels.action_resource', { action: this.$t('labels.upload'), resource: this.$tc('labels.invoice', 1) })
      }
    }
  },
  methods: {
    show (u) {
      this.resource.user_id = u.id
      this.$modal.show('create-' + this.$options.name)
    },
    upload () {
      this.$store.dispatch('chunk', {
        ...{ collection: 'VendorInvoices', icon: this.icon },
        resource: this.resource,
        message: this.$t('messages.uploading_files'),
        callback: () => { this.index() }
      }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
        this.$emit('reload')
      })
    }
  }
}
</script>
