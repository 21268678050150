<template>
  <span>{{ display }}</span>
</template>

<script>
export default {
  name: 'TimeAgo',
  props: ['date'],
  computed: {
    display () {
      let dt = this.date
      if (typeof this.date === 'string') dt = new Date(dt)

      const seconds = Math.floor((new Date() - dt) / 1000)
      let interval = seconds / 31536000

      if (interval > 1) {
        return this.$tc('datetime.distance.year', Math.floor(interval), { years: Math.floor(interval) })
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return this.$tc('datetime.distance.month', Math.floor(interval), { months: Math.floor(interval) })
      }
      interval = seconds / 86400
      if (interval > 1) {
        return this.$tc('datetime.distance.day', Math.floor(interval), { days: Math.floor(interval) })
      }
      interval = seconds / 3600
      if (interval > 1) {
        return this.$tc('datetime.distance.hour', Math.floor(interval), { hours: Math.floor(interval) })
      }
      interval = seconds / 60
      if (interval > 1) {
        return this.$tc('datetime.distance.minute', Math.floor(interval), { minutes: Math.floor(interval) })
      }
      return this.$tc('datetime.distance.second', Math.floor(seconds), { seconds: Math.floor(seconds) })
    }
  }
}
</script>
