<template>
  <div class="d-flex justify-content-between">
    <nav class="bs-pagination d-flex">
      <ul class="pagination my-2 bg-light d-none d-sm-flex" v-if="state && state.tableRows">
        <li class="page-item" v-for="c in displayCounts" :key="'c' + c" :class="{'active': c === state.tableRows}">
          <button class="page-link" @click="$emit('pushPage', 1, c)">{{ c }}</button>
        </li>
      </ul>
      <ul class="pagination my-2 ml-1 pl-2 border-left" v-if="filters && state">
        <li v-if="filters.after" class="page-item dropup" :class="state.filter ? 'active' : ''">
          <button class="page-link dropdown-toggle" @click="afterMenu = true" id="afterButton">
            <fa-icon :icon="['fal', 'calendar-check']"></fa-icon>
          </button>
          <portal to="app-modal">
            <tuxedo-secondary-menu v-if="afterMenu" @close="afterMenu = false" :target="'afterButton'" class="overflow-x-hidden">
              <button
                  v-for="filter in filterTypes.after.filters"
                  :key="'fa_' + filter"
                  @click="pushFilter(filter)"
                  class="dropdown-item"
              >
                {{ $t('labels.filters.' + filter) }}
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" @click="pushFilter('')">{{ $t('labels.clear') }}</button>
            </tuxedo-secondary-menu>
          </portal>
        </li>
      </ul>
      <ul class="pagination my-2" v-if="filters && state">
        <li v-if="filters.invoiceState" class="page-item dropup" :class="state.filters.state ? 'active' : ''">
          <button class="page-link dropdown-toggle" @click="invoiceMenu = true" id="invoiceButton">
            <fa-icon :icon="['fal', 'filter']"></fa-icon>
          </button>
          <portal to="app-modal">
            <tuxedo-secondary-menu v-if="invoiceMenu" @close="invoiceMenu = false" :target="'invoiceButton'" class="overflow-x-hidden">
              <button
                  v-for="filter in filterTypes.invoiceState.filters"
                  :key="'fa_' + filter"
                  @click="pushFilter({ state: filter })"
                  class="dropdown-item"
              >
                {{ $t('labels.' + filter) }}
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" @click="pushFilter({ state: null })">{{ $t('labels.clear') }}</button>
            </tuxedo-secondary-menu>
          </portal>
        </li>
      </ul>
      <ul class="pagination my-2" v-if="filters && state">
        <li v-if="filters.clientState" class="page-item dropup" :class="state.filters.state ? 'active' : ''">
          <button class="page-link dropdown-toggle" @click="clientMenu = true" id="clientButton">
            <fa-icon :icon="['fal', 'filter']"></fa-icon>
          </button>
          <portal to="app-modal">
            <tuxedo-secondary-menu v-if="clientMenu" @close="clientMenu = false" :target="'clientButton'" class="overflow-x-hidden">
              <button
                  v-for="filter in filterTypes.clientState.filters"
                  :key="'fa_' + filter"
                  @click="pushFilter({ state: filter })"
                  class="dropdown-item"
              >
                {{ $t('labels.' + filter) }}
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" @click="pushFilter({ state: null })">{{ $t('labels.clear') }}</button>
            </tuxedo-secondary-menu>
          </portal>
        </li>
      </ul>
      <ul class="pagination my-2" v-if="filters && state">
        <li v-if="filters.transactionDirection" class="page-item dropup" :class="state.filters.state ? 'active' : ''">
          <button class="page-link dropdown-toggle" @click="transactionMenu = true" id="transactionButton">
            <fa-icon :icon="['fal', 'exchange']"></fa-icon>
          </button>
          <portal to="app-modal">
            <tuxedo-secondary-menu v-if="transactionMenu" @close="transactionMenu = false" :target="'transactionButton'" class="overflow-x-hidden">
              <button
                  v-for="filter in filterTypes.transactionDirection.filters"
                  :key="'fa_' + filter"
                  @click="pushFilter({ state: filter })"
                  class="dropdown-item"
              >
                {{ $t('labels.' + filter) }}
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" @click="pushFilter({ state: null })">{{ $t('labels.clear') }}</button>
            </tuxedo-secondary-menu>
          </portal>
        </li>
      </ul>
    </nav>
    <nav class="bs-pagination">
      <ul class="pagination my-2" v-if="lastPage > 1">
        <li class="page-item" v-if="currentPage > 1">
          <button class="page-link" aria-label="Previous" @click="$emit('pushPage', 1)">
            <fa-icon :icon="['fal', 'chevron-left']"></fa-icon>
          </button>
        </li>
        <li class="page-item" v-for="page in pages" :key="'p' + page" :class="{'active': page === currentPage}">
          <button class="page-link" @click="$emit('pushPage', page)">{{ page }}</button>
        </li>
        <li class="page-item" v-if="currentPage < lastPage">
          <button class="page-link" aria-label="Next" @click="$emit('pushPage', lastPage)">
            <span aria-hidden="true"><fa-icon :icon="['fal', 'chevron-right']"></fa-icon></span>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['data', 'state', 'counts', 'filters'],
  data () {
    return {
      offset: 1,
      filterTypes: {
        after: {
          show: false,
          filters: [
            'from_today',
            'from_week',
            'from_last_month',
            'from_month',
            'from_last_quarter',
            'from_quarter',
            'from_financial_year',
            'from_year'
          ]
        },
        invoiceState: {
          show: false,
          filters: [
            'open',
            'sending',
            'sent',
            'seen',
            'overdue',
            'reminded',
            'notice',
            'warning',
            'partial',
            'paid',
            'cash',
            'unpaid',
            'cancelled'
          ]
        },
        clientState: {
          show: false,
          filters: [
            'active',
            'inactive',
            'suspended'
          ]
        },
        transactionDirection: {
          show: false,
          filters: [
            'incoming',
            'outgoing'
          ]
        }
      },
      invoiceMenu: false,
      transactionMenu: false,
      clientMenu: false,
      afterMenu: false
    }
  },
  computed: {
    displayCounts () {
      if (this.counts) return this.counts
      return [10, 20, 50, 100]
    },
    pages () {
      if (!this.data.meta || !this.data.meta.to) {
        return []
      }
      let from = this.data.meta.current_page - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + (this.offset * 2)
      if (to >= this.data.meta.last_page) {
        to = this.data.meta.last_page
      }
      const pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    },
    currentPage () {
      return this.data.meta ? this.data.meta.current_page : 0
    },
    lastPage () {
      return this.data.meta ? this.data.meta.last_page : 0
    }
  },
  methods: {
    pushFilter (filter) {
      this.invoiceMenu = false
      this.transactionMenu = false
      this.clientMenu = false
      this.afterMenu = false
      this.filterTypes.after.show = false
      this.$emit('pushPage', 1, null, filter)
    }
  }
}
</script>

<style lang="scss">
.bs-pagination {

  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    margin: 0 3px;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }

  .page-item.active > .page-link {
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
  }

  li.page-item:focus-within {

    > .dropdown-menu {
      display: block;
    }
  }
}
</style>
