<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <bs-table v-bind="{dataLength, loading}" :cols="5">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.due_at')"></bs-th>
            <bs-th :label="$t('labels.client')"></bs-th>
            <bs-th :label="$t('labels.task_group')"></bs-th>
            <bs-th :label="$t('labels.state')"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in orderedSchedules" :key="r.id">
          <td :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
            <date :date="r.due_at" />
          </td>
          <td :class="authCan('manage_clients') ? 'clickable' : ''" @click="authCan('manage_clients') ? $router.push({ name: 'Client', params: { client: r.client_id }}) : null">
            <span :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
              <span class="font-weight-bold">{{ r.client_name }}</span><br>
              <small v-if="r.address">{{ r.address.readable }}</small>
            </span>
          </td>
          <td :class="r.state.state === 'skipped' || !r.client_active ? 'text-muted' : ''">
            <span v-for="task in r.tasks" :key="'t' + task.id">
              {{ task.name }}
              <span v-if="authCan('task_price')">(<span v-html="$n(task.contractor_total, 'currency')"></span>)</span><br>
            </span>
          </td>
          <td>
            <bs-badge class="bg-danger text-light" v-if="!r.client_active">
              {{ $t('labels.inactive')}}
            </bs-badge>
            <bs-badge class="bg-warning text-light" v-else-if="r.state.state === 'skipped'">
              {{ $t('labels.skipped')}}
            </bs-badge>
            <bs-badge :class="r.state.class" v-else>{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>

          <td class="t2">
            <bs-action :icon="['fas', 'exchange-alt']" class="btn-primary" @click="update(r)"></bs-action>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'swap-contractor'" :label="$t('labels.swap_contractor')">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template>
            <tuxedo-select :name="'users'" :label="$tc('labels.contractor', 0)" v-model="resource.users" :options="users" :insistLabel="'name'" :multi="true" />
          </template>
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import { authMethods } from '../../mixins/authMethods'

export default {
  name: 'ConflictingSchedules',
  props: ['user', 'allUsers'],
  mixins: [resourceControl, authMethods],
  data () {
    return {
      icon: ['fal', 'exclamation-triangle'],
      resource: {
        id: null,
        users: null,
        locked: null,
        due_at: Date.now()
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.conflictingSchedules,
      resourceState: state => state.conflictingSchedulesState,
      users: state => state.usersStore
    }),
    orderedSchedules () {
      return this._.sortBy(this.resources.data, 'due_at')
    }
  },
  methods: {
    index (page, count, filters) {
      this.handleState(page, count, filters)
      this.loading = true
      this.$store.dispatch('index', {
        collection: this.$options.name,
        path: 'users',
        id: this.user,
        tag: 'conflicting-schedules',
        params: {
          allUsers: this.allUsers
        }
      }).then(() => {
        this.loading = false
      })
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.locked = true
      this.$store.dispatch('index', { collection: 'Users' })
      this.$modal.show('swap-contractor')
    },
    submit () {
      this.$store.dispatch('update', {
        collection: 'UserSchedules',
        resource: this.resource
      }).then(() => {
        this.$store.commit('processResponse', { collection: 'conflictingSchedules', data: { id: this.resource.id } })
        this.$modal.hide('swap-contractor')
      })
    }
  }
}
</script>
