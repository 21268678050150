export const state = {
  initialLoadCompleted: 3,
  initialLoadResources: {
    regions: null,
    suburbs: null
  },
  initialLoadResourcesWithCache: {
    users: null,
    clients: null,
    vendors: null,
    expenseCategories: null
  },
  auth: null,
  pingState: {
    data: {}
  },
  axiosError: null,
  submitting: false,
  online: true,
  axiosQueue: [],
  chunkState: {
    total: 0,
    processed: 0
  },
  axiosTiming: {},
  viewLoading: false,
  allContacts: {},
  client: {},
  clients: {},
  clientsStore: {},
  clientsState: {
    cacheTime: null,
    tableRows: 20,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    },
    filters: {},
    search: {
      active: false,
      phrase: ''
    }
  },
  clientConsumables: {},
  clientConsumablesState: {
    tableRows: 5,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  myobContacts: {},
  myobContactsStore: {},
  regions: {},
  cities: {},
  address: {},
  contacts: {},
  contactsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'id',
      dir: 'asc'
    }
  },
  addresses: {},
  addressesStore: [],
  addressesState: {
    tableRows: 5,
    page: 1,
    sort: {
      col: 'address1',
      dir: 'asc'
    }
  },
  notes: {},
  notesState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'updated_at',
      dir: 'asc'
    }
  },
  invoice: {},
  invoices: {},
  invoicesState: {
    tableRows: 20,
    page: 1,
    filters: {},
    sort: {
      col: 'number',
      dir: 'asc'
    }
  },
  transactions: {},
  transactionsState: {
    tableRows: 20,
    page: 1,
    filters: {},
    sort: {
      col: 'created_at',
      dir: 'desc'
    },
    search: {
      active: false,
      phrase: ''
    }
  },
  tasks: {},
  tasksStore: [],
  tasksState: {
    tableRows: 20,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  taskGroups: {},
  taskGroupsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  clientSchedules: {},
  clientSchedulesState: {
    tableRows: 10,
    page: 1,
    filter: null,
    sort: {
      col: 'due_at',
      dir: 'asc'
    }
  },
  userSchedules: {},
  userSchedulesState: {
    dateFrom: null,
    user: null,
    tableRows: 20,
    page: 1,
    sort: {
      col: 'due_at',
      dir: 'asc'
    }
  },
  activityLog: {},
  activityLogState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'created_at',
      dir: 'desc'
    }
  },
  userMonthlyProgress: {},
  userTasksToday: {},
  userTasksIncomplete: {},
  contractorMonthlyProgress: {},
  contractorMonthlyProgressState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  users: {},
  usersStore: [],
  usersState: {
    cacheTime: null,
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  vendors: {},
  vendorsStore: [],
  vendorsState: {
    cacheTime: null,
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  expenseCategories: {},
  expenseCategoriesStore: [],
  expenseCategoriesState: {
    cacheTime: null,
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  expenses: {},
  expensesState: {
    tableRows: 20,
    page: 1,
    sort: {
      col: 'created_at',
      dir: 'desc'
    },
    search: {
      active: false,
      phrase: ''
    }
  },
  expenseDocuments: {},
  expenseDocumentsStore: [],
  expenseDocumentsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'id',
      dir: 'desc'
    }
  },
  suburbs: {},
  suburbsStore: [],
  suburbsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'name',
      dir: 'asc'
    }
  },
  awayEvents: {},
  awayEventsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'start',
      dir: 'desc'
    }
  },
  conflictingSchedules: {},
  conflictingSchedulesState: {
    tableRows: 100,
    page: 1,
    sort: {
      col: 'id',
      dir: 'desc'
    }
  },
  userPrivateDocuments: {},
  userPrivateDocumentsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'id',
      dir: 'desc'
    }
  },
  documents: {},
  documentsStore: [],
  documentsState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'id',
      dir: 'desc'
    }
  },
  myEarningsState: {
    selected: [],
    month: new Date()
  },
  roles: {},
  accountActivity: {},
  accountActivityState: {
    tableRows: 10,
    page: 1,
    sort: {
      col: 'id',
      dir: 'desc'
    }
  },
  incomeByClient: [],
  expenseByVendor: [],
  taskGroupReport: {}
}
