import { i18n } from '../i18n'

export const makeId = {
  methods: {
    makeId () {
      let result = ''
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charLength = chars.length
      for (let i = 0; i < 8; i++) {
        result += chars.charAt(Math.floor(Math.random() *
                    charLength))
      }
      return 'tuxedo=' + result
    }
  }
}

export const watchWindowSize = {
  data () {
    return {
      mobileView: false
    }
  },
  created () {
    this.windowSize()
    window.addEventListener('resize', this.windowSize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowSize)
  },
  methods: {
    windowSize () {
      this.mobileView = window.innerWidth <= 512
    }
  }
}

export const getDay = {
  methods: {
    getDay (day) {
      const now = new Date()
      const newDate = new Date()
      let time = now.getTime()
      const currentDay = now.getDay()
      const diff = parseInt(day) - parseInt(currentDay)
      const milliSeconds = diff * 86400000
      time += milliSeconds
      newDate.setTime(time)
      const dt = new Intl.DateTimeFormat(navigator.language, { weekday: 'short' })
      return dt.format(newDate)
    }
  }
}

export const setISODate = {
  methods: {
    setISODate (date, dateObj) {
      if (!dateObj) dateObj = new Date()
      dateObj.setHours(0)
      dateObj.setMinutes(0)
      dateObj.setSeconds(0)
      dateObj.setMilliseconds(0)
      if (!date) {
        console.error('No date supplied to setISODate function')
        return dateObj
      } else if (date.length !== 10) {
        console.error('Incorrect date format supplied to setISODate function. Supplied \'' + date + '\' - should be in format YYYY-MM-DD')
        return dateObj
      }

      const parts = date.split('-')
      dateObj.setFullYear(parts[0])
      dateObj.setMonth(parseInt(parts[1]) - 1)
      dateObj.setDate(parts[2])
      return dateObj
    }
  }
}

export const getDateFormat = {
  methods: {
    getDateFormat (date) {
      const month = new Intl.DateTimeFormat(navigator.language, { month: 'short' })
      const year = new Intl.DateTimeFormat(navigator.language, { year: 'numeric' })
      const stringDate = date.getDate() + i18n.t('datetime.dateSuffix.' + date.getDate())
      const stringMonth = month.format(date)
      const stringYear = year.format(date)
      return i18n.t('datetime.default', { date: stringDate, month: stringMonth, year: stringYear })
    }
  }
}
