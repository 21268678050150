import axios from 'axios'
import submit from '../router/submit'
import router from '../router'
import queue from '../router/queue'
import helpers from '../helpers'
import _ from 'lodash'
import { Sec } from '../mixins/sec'
import { i18n } from '../i18n'

const buildEndpoint = (payload) => {
  let url = '/api/'
  if (payload.url) return payload.url
  else if (payload.object) {
    const object = helpers.camelToKebab(helpers.pluralize(payload.object))
    url += object
  } else {
    const collection = helpers.camelToKebab(payload.collection)
    url += (payload.path ? helpers.camelToKebab(payload.path) : collection)
  }
  if (payload.id) url += '/' + payload.id
  if (payload.tag) url += '/' + payload.tag
  if (payload.tag_id) url += '/' + payload.tag_id
  if (payload.resource && payload.resource.id) url += '/' + payload.resource.id

  return url
}

export const actions = {
  login ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/sanctum/csrf-cookie')
        .then(() => {
          submit.post('/login', payload)
            .then(() => {
              commit('removeFromQueue')
              dispatch('showUser')
              resolve()
            }).catch(error => reject(error))
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve) => {
      axios.post('/logout').then(response => {
        commit('auth', null)
        router.push({ name: 'Login' })
        resolve(response)
      })
    })
  },
  showUser ({ state, commit }) {
    if (state.online) {
      return new Promise((resolve, reject) => {
        axios.get('/api/user')
          .then(response => {
            commit('auth', response.data)
            resolve(response)
          }).catch(error => {
            commit('auth', false)
            reject(error)
          })
      })
    }
  },
  ping ({ commit }) {
    return new Promise((resolve) => {
      queue.get('/api/ping', { data: { message: 'Running ping on API' } })
        .then(response => {
          commit('pushState', { view: 'ping', key: 'data', data: response.data })
          resolve(response)
        })
    })
  },
  index ({ commit, state }, payload) {
    const collection = helpers.camelToKebab(payload.collection)
    const resourceState = helpers.firstToLower(helpers.kebabToCamel(collection)) + 'State'
    const resourceStore = helpers.firstToLower(helpers.kebabToCamel(collection)) + 'Store'

    // If we can auto-discover the resource state then use this to build the params
    const params = {}

    function query (store = null) {
      return new Promise((resolve) => {
        if (state.online) {
          queue.get(buildEndpoint(payload), config)
            .then(response => {
              commit('processResponse', {
                collection: helpers.kebabToCamel(store ?? collection),
                resource: store,
                data: store ? response.data.data : response.data
              })
              resolve(response)
            })
            .catch(error => {
              // If we get an unauthenticated code, then log the user out
              if (error && error.response && error.response.status === 401) {
                commit('auth', null)
                router.push({ name: 'Login' })
              }
              resolve(error)
            })
        } else {
          resolve()
        }
      })
    }

    function filter () {
      if (state[helpers.kebabToCamel(collection)]) {
        let data = state[resourceStore]
        if (state[resourceState] && state[resourceState].search && state[resourceState].search.active) {
          data = _.filter(data, function (o) {
            return JSON.stringify(o).toLowerCase().includes(state[resourceState].search.phrase.toLowerCase())
          })
        }
        data = _.sortBy(data, [function (o) {
          return o[params.sort]
        }])
        if (params.direction === 'desc') _.reverse(data)
        const pages = _.chunk(data, params.count)
        commit('processResponse', {
          collection: helpers.kebabToCamel(collection),
          data: {
            data: pages[params.page - 1],
            links: {
              first: '',
              last: '',
              next: '',
              prev: ''
            },
            meta: {
              current_page: params.page,
              from: 1,
              to: 20,
              last_page: pages.length,
              per_page: params.count,
              total: state[resourceStore].length
            }
          }
        })
      }
    }

    if (state[resourceState]) {
      Object.assign(params, {
        page: params.page ?? state[resourceState].page,
        count: params.tableRows ?? state[resourceState].tableRows,
        sort: params.sort && params.sort.col ? params.sort.col : state[resourceState].sort.col,
        direction: params.sort && params.sort.dir ? params.sort.dir : state[resourceState].sort.dir,
        year: params.year ?? state[resourceState].year,
        ...state[resourceState].filters
      })

      // If this resource doesn't have a store but we are performing a search then we need to pass the search phrase
      // to the controller
      if (state[resourceState].search && state[resourceState].search.active) {
        if (!state[resourceStore]) {
          Object.assign(params, {
            search: state[resourceState].search.phrase
          })
        }
      }
    }

    // If we have passed in custom params then we load them in here so they override any state
    if (payload.params) {
      Object.assign(params, {
        ...payload.params
      })
    }

    // Prepare the axios url and config
    const config = {
      params: params ? Object.assign({}, params) : payload.params,
      data: payload.data ?? null
    }

    // Prepare the queue message
    if (params) {
      let message = ''
      if (params.date) {
        message = i18n.t('messages.loading_with_month', {
          month: new Sec(params.date).format('F Y'),
          resource: helpers.camelToString(payload.collection)
        })
      } else if (params.year) {
        message = i18n.t('messages.loading_with_year', {
          year: params.year,
          resource: helpers.camelToString(payload.collection)
        })
      } else if (params.count) {
        message = i18n.t('messages.loading', {
          count: params.count,
          resource: helpers.camelToString(payload.collection),
          page: params.page ? params.page : 1
        })
      } else {
        message = i18n.t('messages.simple_loading', {
          resource: helpers.camelToString(payload.collection)
        })
      }
      if (!config.data) config.data = { message, icon: payload.icon }
      else config.data.message = message
    }

    // If we have a local collection for this resource
    if (state[resourceStore]) {
      if (config.params) {
        config.params.page = null
        config.params.count = null
      }
      if (state[resourceStore].length === 0) {
        // Query the API first then return the resources
        return query(resourceStore).then(() => {
          filter()
        })
      } else {
        // Return the resources and query afterwards
        filter()
        return query(resourceStore).then(() => {
          filter()
        })
      }
    } else if (!payload.noReset) {
      // Otherwise process it as usual
      // Clear the current data from the resource collection
      commit('processResponse', { collection: helpers.kebabToCamel(collection), data: null })

      return query()
    }
  },
  show ({ commit }, payload) {
    const object = helpers.camelToKebab(helpers.pluralize(payload.object))
    return new Promise((resolve) => {
      axios.get(buildEndpoint(payload))
        .then(response => {
          commit('processResponse', { resource: helpers.singular(helpers.kebabToCamel(object)), data: response.data })
          resolve(response)
        })
    })
  },
  store ({ commit }, payload) {
    commit('submitting', true)
    const collection = helpers.camelToKebab(payload.collection)
    return new Promise((resolve) => {
      submit.post(buildEndpoint(payload), payload.resource)
        .then(response => {
          commit('processResponse', { collection: helpers.kebabToCamel(collection), data: response.data })
          commit('submitting', false)
          resolve(response)
        })
    })
  },
  chunk ({ state, commit }, payload) {
    const CHUNK_SIZE = 640000 // About half a MB
    const FILES = []
    let CHUNK_TOTAL = 1
    let CHUNK_PROCESSED = 0
    const formData = {}

    for (const key in payload.resource) {
      if (payload.resource[key] instanceof File) {
        const file = payload.resource[key]
        const chunk = Math.ceil(file.size / CHUNK_SIZE)
        CHUNK_TOTAL += chunk
        const chunks = []

        for (let i = 0; i < chunk; i++) {
          chunks.push(file.slice(
            i * CHUNK_SIZE, Math.min(i * CHUNK_SIZE + CHUNK_SIZE, file.size), file.type
          ))
        }
        FILES.push({
          file,
          chunks
        })
      } else if (payload.resource[key] instanceof FileList) {
        Array.from(payload.resource[key]).forEach(file => {
          const chunk = Math.ceil(file.size / CHUNK_SIZE)
          CHUNK_TOTAL += chunk
          const chunks = []

          for (let i = 0; i < chunk; i++) {
            chunks.push(file.slice(
              i * CHUNK_SIZE, Math.min(i * CHUNK_SIZE + CHUNK_SIZE, file.size), file.type
            ))
          }
          FILES.push({
            file,
            chunks
          })
        })
      } else {
        formData[key] = payload.resource[key] ?? ''
      }
    }

    return new Promise((resolve) => {
      submit(buildEndpoint(payload), { method: 'POST', data: formData })
        .then(response => {
          commit('submitting', false)
          resolve(response)

          CHUNK_PROCESSED++
          commit('updateChunks', { total: CHUNK_TOTAL, processed: CHUNK_PROCESSED, icon: payload.icon, message: payload.message })
          do {
            const file = FILES[0]

            const upload = (file) => {
              if (!state.online) {
                setTimeout(() => {
                  return upload(file)
                })
              } else {
                const fileData = new FormData()
                if (response.data && response.data.data.id) fileData.set('id', response.data.data.id)
                fileData.set('is_last', file.chunks.length === 1)
                fileData.set('file', file.chunks[0], `${file.file.name}.part`)

                submit(buildEndpoint(payload), {
                  method: 'POST',
                  data: fileData,
                  headers: { 'Content-Type': 'application/octet-stream' }
                })
                  .then(() => {
                    CHUNK_PROCESSED++
                    commit('updateChunks', { processed: CHUNK_PROCESSED })
                    if (CHUNK_PROCESSED === CHUNK_TOTAL && payload.callback) {
                      payload.callback()
                    }
                    file.chunks.shift()
                    if (file.chunks.length > 0) return upload(file)
                  })
                  .catch(() => {
                    if (!state.online) return upload(file)
                  })
              }
            }

            upload(file)
            FILES.shift()
          } while (FILES.length > 0)
        })
    })
  },
  update ({ commit }, payload) {
    commit('submitting', true)
    const collection = helpers.camelToKebab(payload.collection)
    return new Promise((resolve) => {
      submit.put(buildEndpoint(payload), payload.resource)
        .then(response => {
          commit('processResponse', { collection: helpers.kebabToCamel(collection), data: response.data })
          commit('submitting', false)
          resolve(response)
        })
    })
  },
  destroy ({ commit }, payload) {
    commit('submitting', true)
    const collection = helpers.camelToKebab(payload.collection)
    return new Promise((resolve) => {
      let id = payload.id
      if (payload.tag_id) { id = payload.tag_id }
      axios.delete(buildEndpoint(payload))
        .then(response => {
          let data = { id }
          if (response.data) data = response.data
          commit('processResponse', { collection: helpers.kebabToCamel(collection), data })
          commit('submitting', false)
          resolve(response)
        })
    })
  },
  download ({ commit }, payload) {
    commit('submitting', true)
    return new Promise((resolve, reject) => {
      submit.post('/api/' + payload.path, payload.resource, {
        responseType: 'arraybuffer',
        headers: {
          Accept: payload.accept
        }
      })
        .then((response) => {
          const filename = response.headers['content-disposition'].split('=')

          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([response.data], { type: payload.accept })

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob)
          const link = document.createElement('a')
          link.href = data
          link.download = filename[1]
          link.click()
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
          }, 100)
          commit('submitting', false)
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  assumeUser ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/assume/' + payload.id)
        .then(() => {
          dispatch('showUser')
            .then(() => {
              commit('submitting', false)
              router.push({ name: 'Dashboard' })
            })
          resolve()
        }).catch(error => reject(error))
    })
  },
  indexTaskGroups ({ dispatch }, payload) {
    dispatch('index', {
      collection: 'TaskGroups',
      path: 'Clients',
      id: payload.id,
      tag: 'task-groups',
      params: {
        page: payload.page || 1,
        count: payload.count,
        sort: payload.sort ? payload.sort.col : null,
        direction: payload.sort ? payload.sort.dir : null
      }
    })
  },
  cacheCheck ({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      axios.get('api/cache-check').then((res) => {
        for (const [key, val] of Object.entries(res.data)) {
          commit('pushState', { view: key, key: 'cacheTime', data: val })
        }
        resolve(res)
      }).catch((error) => {
        // If we get an unauthenticated code, then log the user out
        if (error.response.status === 401) {
          commit('auth', null)
          router.push({ name: 'Login' })
        }
      })
    })
  },
  initialLoad ({ dispatch, commit, state }) {
    commit('initialLoad', 'reset')
    const promises = []

    for (const [resource, cacheTime] of Object.entries(state.initialLoadResourcesWithCache)) {
      promises.push(new Promise((resolve) => {
        if (parseInt(cacheTime) !== parseInt(state[resource + 'State'].cacheTime)) {
          dispatch('index', { collection: helpers.firstToUpper(resource) })
            .then(response => {
              state.initialLoadResourcesWithCache[resource] = state[resource + 'State'].cacheTime
              commit('initialLoad')
              resolve(response)
            }, error => {
              resolve(error)
            })
        } else resolve()
      }))
    }

    for (const [resource] of Object.entries(state.initialLoadResources)) {
      promises.push(new Promise((resolve) => {
        dispatch('index', { collection: helpers.firstToUpper(resource) })
          .then(response => {
            commit('initialLoad')
            resolve(response)
          }, error => {
            resolve(error)
          })
      }))
    }

    return Promise.all(promises)
  }
}
