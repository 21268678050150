<template>
  <div id="app" class="d-flex align-items-stretch h-100">
    <div v-if="contentShown === 'app'">
      <portal-target name="app-modal" multiple></portal-target>
      <app-navigation></app-navigation>
      <div id="app-main">
        <app-header></app-header>
        <div id="app-content" class="pt-3" :class="chatOpen ? chatType : ''">
          <router-view :class="chatOpen ? chatType : ''" />
        </div>
        <div id="app-footer" class="bg-light border-top px-3" :class="chatOpen ? chatType : ''">
          <portal-target name="app-footer"></portal-target>
        </div>
        <div id="queue" class="position-absolute">
          <queue v-for="(next, key) in axiosQueue" :key="'queue' + key" :queue="next" />
          <chunk-process />
        </div>
      </div>
    </div>
    <div v-else class="container-fluid">
      <router-view :class="fadeLogin ? 'fade' : ''" />
    </div>
    <div v-if="background" id="background" class="d-flex justify-content-center align-items-end text-center"
         :class="fadeBackground ? 'fade' : ''"
         :style="'z-index: ' + backgroundIndex + ';background-image: url(\'' + appURL + '/img/login-background.jpg\');'"
    >
      <div class="bg-glass lead mb-5" :class="fadeLogin ? '' : 'fade'">
        <div class="pt-2 pb-1 px-4">{{ $t('messages.initial_load') }}</div>
        <div class="progress" style="height: 2px;">
          <div class="progress-bar" role="progressbar" :style="'width: ' + percLoaded + '%;'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      appURL: process.env.VUE_APP_ASSET_URL,
      fadeLogin: false,
      contentShown: 'login',
      backgroundIndex: -1,
      fadeBackground: false,
      background: true
    }
  },
  computed: {
    ...mapState({
      initialLoadCompleted: state => state.initialLoadCompleted,
      auth: state => state.auth,
      chatOpen: state => state.chatOpen,
      chatType: state => state.chatType,
      axiosQueue: state => state.axiosQueue
    }),
    percLoaded () {
      return ((this.initialLoadCompleted / 4) * 100).toFixed()
    }
  },
  watch: {
    '$store.state.auth': function (newVal, oldVal) {
      if (newVal === null) {
        this.out()
      } else if (oldVal === null) {
        this.showContent()
      }
    }
  },
  mounted () {
    window.addEventListener('online', () => this.$store.commit('online'))
    window.addEventListener('offline', () => this.$store.commit('offline'))
    setInterval(() => {
      if (this.auth && this.$store.state.online) {
        this.$store.dispatch('ping')
      }
    }, 120000)

    if (this.auth) this.showContent()
    else this.out()
  },
  methods: {
    showContent () {
      this.fadeLogin = true
      setTimeout(() => {
        this.backgroundIndex = 100
        this.contentShown = 'app'
      }, 400)
      this.$store.dispatch('cacheCheck').then(() => {
        this.$store.dispatch('initialLoad').then(() => {
          console.log('initial completed')
          if (this.$router.currentRoute.name !== 'Dashboard') {
            this.$router.replace({ name: 'Dashboard' })
          }
          setTimeout(() => {
            this.fadeBackground = true
          }, 1500)
          setTimeout(() => {
            this.background = false
            this.$store.commit('initialLoad', 'reset')
          }, 1900)
        })
      })
    },
    out () {
      this.fadeLogin = false
      this.contentShown = 'login'
      this.backgroundIndex = -1
      this.fadeBackground = false
      this.background = true
    }
  }
}
</script>

<style lang="scss">
#nav {
  z-index: 1;
}

#app-main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.2s ease-out left;
}

#app-content {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  overflow-y: scroll;
  bottom: 0;
  padding-bottom: 65px;

  &.chat-fixed {
    margin-right: 300px;
  }

  > div {
    transition: 0.2s opacity;

    &.hidden {
      opacity: 0;
      transition: 0s ease-out opacity;
    }
  }
}

#app-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.2s ease-out left;
  z-index: 5;
  min-height: 51px;

  &.chat-fixed {
    margin-right: 300px;
  }
}

#queue {
  bottom: 57px;
  left: 15px;
  z-index: 10;
}

#background {
  position: fixed;
  background-color: #0e365a;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  /*background: #efefef url('/img/bg1.jpg') no-repeat center;*/
  background-size: cover;
  z-index: -1;
}

@media (min-width: 768px) {

  #app-main, #app-footer {
    left: 240px;
  }

  #app-content {
  }

  #nav.collapsed {

    ~ #app-main {
      left: 50px;

      #app-footer {
        left: 50px;
      }
    }

  }
}

@media (min-width: 1200px) {
  #app-content {

    &.chat-fixed {
      margin-right: 500px;
    }
  }

  #app-footer {

    &.chat-fixed {
      margin-right: 500px;
    }
  }

}
</style>
