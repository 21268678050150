<template>
  <bs-modal :name="formName" :label="labels.form" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.confirm')" @submit="$emit('submit', form)">
      <template v-slot:default="{ error }">
        <bs-alert class="alert-info">
          Please confirm these are the contractors who attended the job on this occasion. <strong>This will impact how the earnings from this job are distributed.</strong>
        </bs-alert>
        <div v-for="user in form.users" :key="'b' + form.id + 'u' + user.id">
          <avatar :user="user" class="mb-2" showName />
        </div>
        <tuxedo-select :label="$tc('resource.contractor', 0)" :name="'users'" :options="users" :insistLabel="'name'" :multi="true" v-model="form.users" :error="error" />
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { mapState } from 'vuex'
import { makeId } from '@/mixins/helpers'

export default {
  name: 'ConfirmAssignedContractorsForm',
  mixins: [makeId],
  computed: {
    ...mapState({
      users: state => state.usersStore
    }),
    formName () { return 'confirm-assigned-contractors-' + this.makeId() }
  },
  data () {
    return {
      form: {
        id: null,
        due_at: null,
        users: []
      },
      labels: {
        form: this.$t('labels.action_resource', { action: this.$t('labels.confirm'), resource: this.$tc('resource.contractor', 0) })
      }
    }
  },
  methods: {
    show (s) {
      this.form.id = s.id
      this.form.due_at = s.due_at
      this.form.users = s.users
      this.$modal.show(this.formName)
    },
    hide () {
      this.$modal.hide(this.formName)
    }
  }
}
</script>
