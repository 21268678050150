<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ $t('labels.nav.settings') }}</h3>
        </route-header>
        <div class="row">
            <div class="col">
                <nav-secondary-settings></nav-secondary-settings>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-4" v-if="authCan('users')">
                <users />
            </div>
            <div class="col-12 col-sm-6" v-if="authCan('roles')">
                <roles />
            </div>
            <div class="col-12 col-sm-6" v-if="authCan('suburbs')">
                <suburbs />
            </div>
        </div>
    </div>
</template>

<script>
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'Settings',
  mixins: [authMethods]
}
</script>
