<template>
  <div>
    <bs-card class="card-info" :icon="['fal', 'calendar-week']" :label="$t('labels.' + camelToSnake($options.name))">
      <template v-slot:header>
        <div>
          <bs-button v-if="regenLoading" class="btn-sm btn-outline-warning">
            <fa-icon :icon="['fal', 'sync']" class="fa-spin" />
          </bs-button>
          <bs-button v-else class="btn-sm btn-warning" @click="regen">
            <fa-icon :icon="['fal', 'sync']" />
            {{ $t('labels.regenerate_client_schedule') }}
          </bs-button>
          <bs-button class="btn-sm btn-info ml-2" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
        </div>
      </template>
      <bs-table class="table-striped">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.due_at')" :sortable="resourceState.sort" :col="'due_at'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.task_group')"></bs-th>
            <bs-th :label="$tc('labels.contractor', 0)"></bs-th>
            <bs-th class="text-center">
              <span>
                <fa-icon :icon="['fas', 'info-circle']" class="tip mr-1" v-tooltip.top="$t('labels.locked.help', { resource: 'booking' })" style="position:relative;top:1px" />
                <span>{{ $t('labels.locked.label') }}</span>
              </span>
              /
              <span>
                <span>{{ $t('labels.skip.label') }}</span>
                <fa-icon :icon="['fas', 'info-circle']" class="tip ml-1" v-tooltip.top="$t('labels.skip.help', { resource: 'booking' })" style="position:relative;top:1px" />
              </span>
            </bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td :class="r.skip ? 'text-muted' : ''">
            <date :date="r.due_at" />
          </td>
          <td :class="r.skip ? 'text-muted' : ''">
            <span class="font-weight-bold">{{ r.task_group }}</span><br>
            <small class="pl-2" v-for="task in r.tasks" :key="'t' + task.id">
              {{ task.name }} (<span v-html="$n(task.contractor_total, 'currency')"></span>)<br>
            </small>
          </td>
          <td :class="r.skip ? 'text-muted' : ''">
            <div v-for="u in r.users" :key="'s' + r.id + 'u' + u.id">
              <avatar :user="u" showName />
            </div>
          </td>
          <td class="text-center" v-if="['pending', 'open', 'skipped'].includes(r.state.state)">
            <span>
              <bs-action v-if="lockLoading" :icon="['fal', 'atom-alt']" class="btn-outline-dark fa-spin"></bs-action>
              <bs-action v-else-if="r.locked" :icon="['fal', 'lock']" class="btn-success" @click="locked(false, r)"></bs-action>
              <bs-action v-else :icon="['fal', 'unlock']" class="btn-outline-dark" @click="locked(true, r)"></bs-action>
            </span>
            <span class="ml-2">
              <bs-action v-if="skipLoading" :icon="['fal', 'atom-alt']" class="btn-outline-dark fa-spin"></bs-action>
              <bs-action v-else-if="r.skip" :icon="['fal', 'store-slash']" class="btn-warning" @click="skip(false, r)"></bs-action>
              <bs-action v-else :icon="['fal', 'store']" class="btn-outline-success" @click="skip(true, r)"></bs-action>
            </span>
          </td>
          <td class="text-center" v-else>
            <bs-badge :class="r.state.class">{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>
          <td class="t2">
            <span v-if="!r.complete">
              <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="$modal.show('task-group-card-' + r.id)"></bs-action>
              <bs-action :icon="['fal', 'exchange']" class="btn-info" @click="update(r)"></bs-action>
              <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
            </span>
            <bs-button v-else class="btn-sm btn-info" @click="viewReport(r)">View Report</bs-button>
          </td>
          <portal to="app-modal">
            <bs-modal :name="'task-group-card-' + r.id" :label="r.client_name">
              <task-group-card :visit="r" :collection="'userSchedules'" hideLabel />
            </bs-modal>
          </portal>
        </tr>
        <tr v-for="blank in dataLength" :key="'b' + blank">
          <td colspan="5" class="blank">
            <div class="loading" v-if="loading"></div>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="'Swap Booking Settings'">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="slotProps" v-if="!formLoading">
            <tuxedo-date :name="'due_at'" :label="$t('labels.due_at')" v-model="resource.due_at" :insistFormat="'isoDate'" :error="slotProps.error"></tuxedo-date>
            <tuxedo-select :name="'task_group'" :label="$t('labels.task_group')" v-model="resource.task_group_id" :options="taskGroups" :insistReturn="'id'" :insistLabel="'name'" :error="slotProps.error" />
            <tuxedo-select :label="$tc('labels.contractor', 0)" :name="'users'" :options="users" v-model="resource.users" :insistLabel="'name'" :multi="true" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
      <bs-modal :name="'report-task-group'" :label="$t('labels.report')">
        <task-group-report :report="activeGroup" />
      </bs-modal>
    </portal>
    <portal to="app-footer">
      <pagination :data="resources" :state="resourceState" :filters="filters" @pushPage="index"></pagination>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'ClientSchedules',
  props: ['id'],
  mixins: [resourceControl],
  data () {
    return {
      loading: false,
      formLoading: false,
      lockLoading: false,
      skipLoading: false,
      regenLoading: false,
      activeId: null,
      activeGroup: null,
      resource: {
        id: null,
        due_at: null,
        task_group_id: null,
        users: null,
        locked: null
      },
      filters: {
        after: true
      }
    }
  },
  computed: {
    ...mapState({
      resourceState: state => state.clientSchedulesState,
      resources: state => state.clientSchedules,
      submitting: state => state.submitting,
      taskGroups: state => state.taskGroups.data,
      users: state => state.usersStore
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    viewReport (r) {
      this.activeGroup = r
      this.$modal.show('report-task-group')
    },
    regen () {
      this.regenLoading = true
      axios.get('api/clients/' + this.id + '/client-schedules/regenerate')
        .then(() => {
          this.index(this.resourceState.page)
        })
    },
    locked (val, r) {
      this.lockLoading = true
      this.resource = JSON.parse(JSON.stringify(r))
      this.resource.locked = val
      this.submit()
    },
    skip (val, r) {
      this.skipLoading = true
      this.resource = JSON.parse(JSON.stringify(r))
      this.resource.skip = val
      this.submit()
    },
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    index (page, count, filter = null) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      if (filter !== null) this.$store.commit('pushState', { view: this.$options.name, key: 'filter', data: filter })
      else filter = this.resourceState.filter
      this.loading = true
      this.$store.dispatch('index', {
        path: 'Clients',
        id: this.id,
        tag: 'client-schedules',
        collection: this.$options.name,
        params: {
          page,
          count,
          sort: this.resourceState.sort.col,
          direction: this.resourceState.sort.dir,
          filter
        }
      })
        .then(() => {
          this.loading = false
          this.regenLoading = false
        })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.resource.locked = true
      this.formLoading = true
      this.$store.dispatch('index', { collection: 'Users' })
      this.$store.dispatch('indexTaskGroups', { id: this.id })
        .then(() => {
          this.formLoading = false
        })
      this.$modal.show('create-' + this.$options.name)
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.locked = true
      this.formLoading = true
      this.$store.dispatch('index', { collection: 'Users' })
      this.$store.dispatch('indexTaskGroups', { id: this.id })
        .then(() => {
          this.formLoading = false
        })
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', {
        collection: this.$options.name,
        path: 'Clients',
        id: this.id,
        tag: 'client-schedules',
        resource: this.resource
      }).then(() => {
        this.lockLoading = false
        this.skipLoading = false
        this.$modal.hide('create-' + this.$options.name)
      })
    }
  }
}
</script>
