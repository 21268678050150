<template>
  <div>
    <bs-card v-if="resource" class="card-info" style="max-width:800px;margin:0 auto;">
      <div class="p-4">
      <table cellpadding="0" cellspacing="0" class="w-100">
        <tr class="top">
          <td colspan="6" class="text-center border-bottom">
            <bs-badge :class="resource.state.class + ' float-right'">{{ $t('labels.' + resource.state.state) }}</bs-badge>
            <h1><strong>{{ $t('labels.tax_invoice') }}</strong></h1>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center pt-3 pb-2">
            <img :src="resource.config.logo" style="width:100%; max-width:150px;">
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center">
            <h3><strong>{{ resource.config.body.header_title }}</strong></h3>
            <span v-html="resource.config.body.header_content" />
          </td>
        </tr>

        <tr class="information">
          <td colspan="3" class="pb-3">
            <strong>{{ $t('labels.bill_to') }}:</strong><br>
            <div>{{ resource.client }}</div>
            <div v-if="resource.address">
            <div v-if="resource.address.address1">{{ resource.address.address1 }}</div>
            <div v-if="resource.address.address2">{{ resource.address.address2 }}</div>
            <div v-if="resource.address.address3">{{ resource.address.address3 }}</div>
            <div v-if="resource.address.city">{{ resource.address.city }}</div>
            </div>
          </td>
          <td colspan="3" class="pb-3">
            <strong>{{ $t('labels.issued') }}:</strong> <span v-if="resource.issued_at"><date :date="resource.issued_at" /></span><br>
            <strong>{{ $tc('labels.invoice', 1) }}:</strong> {{ resource.number ? resource.number : invoice }}<br>
            <strong>{{ $t('labels.terms') }}</strong>: {{ $t('labels.term_length', { days: resource.terms }) }}
          </td>
        </tr>

        <tr class="heading bg-light border-top">
          <td class="py-1 px-2"><strong>{{ $t('labels.service') }}</strong></td>
          <td class="py-1 px-2 text-right" style="width:120px"><strong>{{ $t('labels.unit_cost') }}</strong></td>
          <td class="py-1 px-2 text-right" style="width:100px"><strong>{{ $t('labels.quantity') }}</strong></td>
          <td class="py-1 px-2 text-right" style="width:120px"><strong>{{ $t('labels.gst') }}</strong></td>
          <td class="py-1 px-2 text-right" style="width:120px"><strong>{{ $t('labels.price') }}</strong></td>
          <td style="width:40px"></td>
        </tr>

        <tr class="item" v-for="line in lines" :key="'il' + line.id">
          <td class="py-1 px-2 line-input"
              contenteditable="true"
              v-if="editable"
              @blur="updateLine($event, line)">
            {{ line.label }}
          </td>
          <td class="py-1 px-2 line-input" v-else>
            {{ line.label }}
          </td>
          <td class="py-1 px-2 line-input text-right">
            $<input class="text-right" v-model="line.unit_price" @blur="updateLine($event, line)" :disabled="!editable" />
          </td>
          <td class="py-1 px-2 line-input text-right">
            <input class="text-right" type="number" v-model="line.quantity" @blur="updateLine($event, line)" :disabled="!editable" />
          </td>
          <td class="py-1 px-2 line-input text-right">
            {{ $n(calcTax(line), 'currency') }}
          </td>
          <td class="py-1 px-2 line-input text-right">
            {{ $n(calcTotal(line), 'currency') }}
          </td>
          <td class="py-1 px-2 line-input text-right">
            <bs-action v-if="editable" :icon="['fal', 'trash']" class="btn-danger" @click="destruction(line.id)"></bs-action>
          </td>
        </tr>

        <tr v-if="editable">
          <td colspan="4" class="py-1 px-2">
            <bs-button class="btn-primary btn-sm" @click="addLine">{{ $t('labels.add_row') }}</bs-button>
          </td>
        </tr>

        <tr>
          <td class="py-5" colspan="6">
            <h5>{{ $t('labels.notes') }}</h5>
            <div class="bg-light p-3 border"
                 contenteditable="true"
                 v-if="editable"
                 @blur="updateNote($event)">{{ resource.note }}</div>
            <div class="bg-light p-3 border" v-else>{{ resource.note }}</div>
          </td>
        </tr>

        <tr class="total">
          <td colspan="3" class="bg-light p-3 border-left border-top border-bottom">
            <h3>{{ resource.config.body.footer_title }}</h3>
            <p v-html="resource.config.body.footer_content"></p>
          </td>
          <td colspan="3" class="bg-light p-3 border-top border-right border-bottom">
            <table class="w-100">
              <tr>
                <td class="pb-3 pr-3"><strong>{{ $t('labels.gst') }}:</strong></td>
                <td class="text-right pb-3">{{ $n(summary.tax, 'currency') }}</td>
              </tr>
              <tr>
                <td class="pb-3 pr-3"><strong>{{ $t('labels.total_incl') }}:</strong></td>
                <td class="text-right pb-3">{{ $n(summary.total, 'currency') }}</td>
              </tr>
              <tr>
                <td class="pb-3 pr-3"><strong>{{ $t('labels.amount_applied') }}:</strong></td>
                <td class="text-right pb-3">{{ $n(summary.paid, 'currency') }}</td>
              </tr>
              <tr>
                <td class="pb-3 pr-3"><strong>{{ $t('labels.balance_due') }}:</strong></td>
                <td class="text-right pb-3">{{ $n(summary.due, 'currency') }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </div>
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import submit from '../../router/submit'

export default {
  name: 'InvoiceEditor',
  props: ['invoice'],
  data () {
    return {
      lines: [],
      summary: {
        tax: 0,
        total: 0,
        paid: 0,
        due: 0
      }
    }
  },
  computed: {
    ...mapState({
      resource: state => state.invoice.data
    }),
    total () {
      return 0
    },
    editable () {
      return ['open', 'sent', 'seen', 'overdue', 'notice', 'reminded', 'suspended', 'warning'].includes(this.resource.state.state)
    }
  },
  mounted () {
    this.show()
  },
  watch: {
    invoice: function () {
      this.show()
    },
    lines: {
      deep: true,
      handler (newVal) {
        let tax = 0
        let sub = 0
        newVal.forEach((l) => {
          tax += this.calcTax(l)
          sub += this.calcSubTotal(l)
        })
        this.summary.tax = tax
        this.summary.total = sub + tax
        this.summary.due = (sub + tax) - this.summary.paid
      }
    }
  },
  methods: {
    calcTax (r) {
      return this.calcSubTotal(r) * 0.1
    },
    calcSubTotal (r) {
      return parseFloat(r.unit_price) * parseFloat(r.quantity)
    },
    calcTotal (r) {
      return this.calcSubTotal(r) + this.calcTax(r)
    },
    show () {
      this.loading = true
      this.$store.commit('viewLoading', true)
      this.$store.dispatch('show', { object: 'Invoice', id: this.invoice }).then((res) => {
        this.lines = Object.assign([], res.data.data.lines)
        this.summary.paid = res.data.data.paid
        this.summary.tax = res.data.data.tax
        this.summary.total = res.data.data.total
        this.summary.due = res.data.data.total
        this.$store.commit('viewLoading', false)
        this.loading = false
      })
    },
    addLine () {
      submit.post('/api/invoices/' + this.invoice + '/lines', { invoice: this.invoice, quantity: 0, unit_price: 0 })
        .then((res) => {
          this.lines.push(res.data.data)
        })
    },
    updateLine (e, line) {
      if (this.editable) {
        if (e.target.tagName === 'TD') {
          line.label = e.target.innerText
        }
        submit.put('/api/invoices/' + this.invoice + '/lines/' + line.id, line)
      }
    },
    updateNote (e) {
      if (this.editable) {
        this.resource.note = e.target.innerText
        submit.put('/api/invoices/' + this.invoice, this.resource)
      }
    },
    destruction (id) {
      if (this.editable) {
        axios.delete('/api/invoices/' + this.invoice + '/lines/' + id).then(() => {
          this.$delete(this.lines, this.lines.findIndex(l => l.id === id))
        })
      }
    }
  }
}
</script>

<style>
td.line-input {
  border-bottom: 1px solid lightgrey;
}

.line-input input {
  border: 0;
  padding: 0;
  width: 90%;
}

.line-input input:focus-visible {
  outline: none;
}
</style>
