export class Sec {
  constructor (time) {
    time = time || Date.now()
    this.time = new Date(time)

    // if the 'time' that has been passed in couldn't be parsed by the browsers default behaviour then
    // we attempt it using the Date parse method
    if (!this.isValidDate()) this.time = new Date(Date.parse(time))
    if (!this.isValidDate()) this.time = this.parseFromLocalISO(time)
    if (!this.isValidDate()) console.error('Sec error: Failed to parse ' + time.toString() + ' into a Date object')

    return this
  }

  // Setters
  applyTimeString (time) {
    if (time) {
      const parts = time.split(':')
      this.time.setHours(parts.shift())
      this.time.setMinutes(parts.shift())
    } else {
      console.error('Sec error: No time string supplied')
    }
    return this
  }

  hour (hour) {
    this.time.setHours(hour)
    return this
  }

  minute (minute) {
    this.time.setMinutes(minute)
    return this
  }

  // Attributes
  dayOfWeek (day) {
    if (day !== undefined) {
      const difference = day - this.time.getDay()
      this.time.setDate(this.time.getDate() + difference)
      return this
    }
    return this.time.getDay()
  }

  JSDate () {
    return this.time
  }

  YmdDate () {
    const year = this.time.toLocaleDateString([], { year: 'numeric' })
    const month = this.time.toLocaleDateString([], { month: 'numeric' }).padStart(2, '0')
    const date = this.time.toLocaleDateString([], { day: 'numeric' }).padStart(2, '0')
    return `${year}-${month}-${date}`
  }

  ISODate () {
    return this.time.toISOString()
  }

  LocalDateTime () {
    const year = this.time.toLocaleDateString([], { year: 'numeric' })
    const month = this.time.toLocaleDateString([], { month: 'numeric' }).padStart(2, '0')
    const date = this.time.toLocaleDateString([], { day: 'numeric' }).padStart(2, '0')
    const hours = this.time.getHours().toString().padStart(2, '0')
    const minutes = this.time.toLocaleTimeString([], { minute: '2-digit' }).padStart(2, '0')

    return `${year}-${month}-${date}T${hours}:${minutes}`
  }

  // Calculations
  add (count, denomination) {
    switch (denomination) {
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
        this.addMinutes(count)
        return this
      case 'h':
      case 'hour':
      case 'hours':
        this.addHours(count)
        return this
      case 'd':
      case 'day':
      case 'days':
        this.addDays(count)
        return this
      case 'month':
      case 'months':
        this.addMonths(count)
        return this
      case 'q':
      case 'quarter':
      case 'quarters':
        this.addQuarters(count)
        return this
      case 'y':
      case 'year':
      case 'years':
        this.addYears(count)
        return this
    }
  }

  subtract (count, denomination) {
    switch (denomination) {
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
        this.subtractMinutes(count)
        return this
      case 'h':
      case 'hour':
      case 'hours':
        this.subtractHours(count)
        return this
      case 'd':
      case 'day':
      case 'days':
        this.subtractDays(count)
        return this
      case 'month':
      case 'months':
        this.subtractMonths(count)
        return this
      case 'q':
      case 'quarter':
      case 'quarters':
        this.subtractQuarters(count)
        return this
      case 'y':
      case 'year':
      case 'years':
        this.subtractYears(count)
        return this
    }
  }

  subtractMinutes (minutes) {
    this.time = new Date(this.time.getTime() - minutes * 60000)
  }

  addMinutes (minutes) {
    this.time = new Date(this.time.getTime() + minutes * 60000)
  }

  subtractHours (hours) {
    this.time = new Date(this.time.getTime() - hours * 1000)
  }

  addHours (hours) {
    this.time = new Date(this.time.getTime() + hours * 1000)
  }

  subtractDays (days) {
    this.time = new Date(this.time.setDate(this.time.getDate() - days))
  }

  addDays (days) {
    this.time = new Date(this.time.setDate(this.time.getDate() + days))
  }

  subtractMonths (months) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() - months))
  }

  addMonths (months) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() + months))
  }

  subtractQuarters (quarters) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() - (quarters * 3)))
  }

  addQuarters (quarters) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() + (quarters * 3)))
  }

  subtractYears (years) {
    this.time = new Date(this.time.setYear(this.time.getFullYear() - years))
  }

  addYears (years) {
    this.time = new Date(this.time.setYear(this.time.getFullYear() + years))
  }

  startOf (denomination) {
    switch (denomination) {
      case 'month':
        this.startOfMonth()
        return this
      case 'q':
      case 'quarter':
        this.startOfQuarter()
        return this
      case 'y':
      case 'year':
        this.startOfYear()
        return this
    }
  }

  endOf (denomination) {
    switch (denomination) {
      case 'month':
        this.endOfMonth()
        return this
      case 'q':
      case 'quarter':
        this.endOfQuarter()
        return this
      case 'y':
      case 'year':
        this.endOfYear()
        return this
    }
  }

  startOfMonth () {
    this.time = new Date(this.time.getFullYear(), this.time.getMonth(), 1, 0, 0, 0)
  }

  endOfMonth () {
    this.time = new Date(this.time.getFullYear(), this.time.getMonth() + 1, 0, 23, 59, 59)
  }

  startOfQuarter () {
    switch (this.time.getMonth()) {
      case 0:
      case 1:
      case 2:
        this.time.setMonth(0)
        return this.startOf('month')
      case 3:
      case 4:
      case 5:
        this.time.setMonth(3)
        return this.startOf('month')
      case 6:
      case 7:
      case 8:
        this.time.setMonth(6)
        return this.startOf('month')
      case 9:
      case 10:
      case 11:
        this.time.setMonth(9)
        return this.startOf('month')
    }
  }

  endOfQuarter () {
    switch (this.time.getMonth()) {
      case 0:
      case 1:
      case 2:
        this.time.setMonth(2)
        return this.endOf('month')
      case 3:
      case 4:
      case 5:
        this.time.setMonth(5)
        return this.endOf('month')
      case 6:
      case 7:
      case 8:
        this.time.setMonth(8)
        return this.endOf('month')
      case 9:
      case 10:
      case 11:
        this.time.setMonth(11)
        return this.endOf('month')
    }
  }

  startOfYear () {
    this.time = new Date(this.time.getFullYear(), 0, 1, 0, 0, 0)
  }

  endOfYear () {
    this.time = new Date(this.time.getFullYear(), 11, 31, 23, 59, 59)
  }

  // Comparison methods
  isBefore (time) {
    return this.time < time.time
  }

  isAfter (time) {
    return this.time > time.time
  }

  isSame (time) {
    return this.time.getTime() === time.time.getTime()
  }

  // Helpers
  isValidDate () {
    return this.time instanceof Date && !isNaN(this.time)
  }

  format (string) {
    if (string === 'locale') {
      return this.time.toLocaleDateString([], {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: true, hour: 'numeric', minute: '2-digit'
      })
    }

    string = string.replaceAll('d', '[x1]') // Day of the month, 2 digits with leading zeros
    string = string.replaceAll('D', '[x2]') // A textual representation of a day, three letters
    string = string.replaceAll('j', '[x3]') // Day of the month without leading zeros
    string = string.replaceAll('l', '[x4]') // A full textual representation of the day of the week
    string = string.replaceAll('N', '[x5]') // ISO 8601 numeric representation of the day of the week
    string = string.replaceAll('S', '[x6]') // English ordinal suffix for the day of the month, 2 characters
    string = string.replaceAll('w', '[x7]') // Numeric representation of the day of the week
    string = string.replaceAll('z', '[x8]') // The day of the year (starting from 0)
    string = string.replaceAll('W', '[x9]') // ISO 8601 week number of year, weeks starting on Monday
    string = string.replaceAll('F', '[x10]') // A full textual representation of a month, such as January or March
    string = string.replaceAll('m', '[x11]') // Numeric representation of a month, with leading zeros
    string = string.replaceAll('M', '[x12]') // A short textual representation of a month, three letters
    string = string.replaceAll('n', '[x13]') // Numeric representation of a month, without leading zeros
    string = string.replaceAll('t', '[x14]') // Number of days in the given month
    string = string.replaceAll('L', '[x15]') // Whether it's a leap year
    string = string.replaceAll('o', '[x16]') // ISO 8601 week-numbering year. This has the same value as Y, except that if the ISO week number (W) belongs to the previous or next year, that year is used instead.
    string = string.replaceAll('Y', '[x17]') // A full numeric representation of a year, at least 4 digits, with - for years BCE.
    string = string.replaceAll('y', '[x18]') // A two digit representation of a year
    string = string.replaceAll('a', '[x19]') // Lowercase Ante meridiem and Post meridiem
    string = string.replaceAll('A', '[x20]') // Uppercase Ante meridiem and Post meridiem
    string = string.replaceAll('B', '[x21]') // Swatch Internet time
    string = string.replaceAll('g', '[x22]') // 12-hour format of an hour without leading zeros
    string = string.replaceAll('G', '[x23]') // 24-hour format of an hour without leading zeros
    string = string.replaceAll('h', '[x24]') // 12-hour format of an hour with leading zeros
    string = string.replaceAll('H', '[x25]') // 24-hour format of an hour with leading zeros
    string = string.replaceAll('i', '[x26]') // Minutes with leading zeros
    string = string.replaceAll('s', '[x27]') // Seconds with leading zeros
    string = string.replaceAll('u', '[x28]') // Microseconds.
    string = string.replaceAll('v', '[x29]') // Milliseconds.
    string = string.replaceAll('e', '[x30]') // Timezone identifier
    string = string.replaceAll('I', '[x31]') // Whether or not the date is in daylight saving time
    string = string.replaceAll('O', '[x32]') // Difference to Greenwich time (GMT) without colon between hours and minutes
    string = string.replaceAll('P', '[x33]') // Difference to Greenwich time (GMT) with colon between hours and minutes
    string = string.replaceAll('p', '[x34]') // The same as P, but returns Z instead of +00:00
    string = string.replaceAll('T', '[x35]') // Timezone abbreviation, if known; otherwise the GMT offset.
    string = string.replaceAll('Z', '[x36]') // Timezone offset in seconds.
    string = string.replaceAll('c', '[x37]') // ISO 8601 date
    string = string.replaceAll('r', '[x38]') // » RFC 2822/» RFC 5322 formatted date
    string = string.replaceAll('U', '[x39]') // Seconds since the Unix Epoch (January 1 1970 00:00:00 GMT)

    string = string.replaceAll('[x3]', this.time.toLocaleDateString([], { day: 'numeric' }))
    string = string.replaceAll('[x4]', this.time.toLocaleDateString([], { weekday: 'long' }))
    string = string.replaceAll('[x6]', this.ordinalText())
    string = string.replaceAll('[x10]', this.time.toLocaleDateString([], { month: 'long' }))
    string = string.replaceAll('[x12]', this.time.toLocaleDateString([], { month: 'short' }))
    string = string.replaceAll('[x17]', this.time.toLocaleDateString([], { year: 'numeric' }))
    let h = parseInt(this.time.toLocaleTimeString([], { hour: 'numeric' }))
    if (h > 12) h -= 12
    string = string.replaceAll('[x24]', this.time.getHours().toString().padStart(2, '0'))
    string = string.replaceAll('[x19]', this.time.toLocaleTimeString([], { hour12: true, hour: '2-digit' })
      .replace('00', '12').replace(/^0/, '').replace(' ', ''))
    string = string.replaceAll('[x22]', h)
    string = string.replaceAll('[x26]', this.time.toLocaleTimeString([], { minute: '2-digit' }).padStart(2, '0'))
    string = string.replaceAll('[x19]', this.time.toLocaleTimeString([], { hour12: true })
      .replace(/[0-9:]+/, '').replace(' ', ''))

    return string
  }

  ordinalText () {
    const day = parseInt(this.time.toLocaleDateString([], { day: 'numeric' }))
    if (day === 1 || day === 21 || day === 31) return 'st'
    if (day === 2 || day === 22) return 'nd'
    if (day === 3 || day === 23) return 'rd'
    return 'th'
  }

  parseFromLocalISO (string) {
    const dt = string.split(' ')
    let date = false
    let time = false

    if (dt[0] && dt[0].includes('-')) date = dt[0].split('-')
    if (dt[0] && dt[0].includes(':')) time = dt[0].split(':')
    if (dt[1] && dt[1].includes('-')) date = dt[1].split('-')
    if (dt[1] && dt[1].includes(':')) time = dt[1].split(':')

    if (date && time) return new Date(date[0], date[1], date[2], time[0], time[1], time[1])
    if (date) return new Date(date[0], date[1], date[2])
    if (time) return new Date(null, null, null, time[0], time[1], time[1])
  }
}
