<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="user.name">
      <template v-slot:header>
        <div v-if="mobileView" class="text-right">
          <bs-button class="btn-sm btn-warning mb-1" @click="$refs.uploadInvoice.show(user)">{{ $t('labels.action_resource', { action: $t('labels.upload'), resource: $tc('labels.invoice', 1) }) }}</bs-button><br>
          <bs-button class="btn-sm btn-info" @click="expanded = !expanded">{{ expanded ? $t('labels.contract_weeks') : $t('labels.expand_weeks') }}</bs-button>
        </div>
        <div v-else>
          <bs-button class="btn-sm btn-warning mr-2" v-if="user.vendor" @click="$refs.uploadInvoice.show(user)">{{ $t('labels.action_resource', { action: $t('labels.upload'), resource: $tc('labels.invoice', 1) }) }}</bs-button>
          <bs-button class="btn-sm btn-info" @click="expanded = !expanded">{{ expanded ? $t('labels.contract_weeks') : $t('labels.expand_weeks') }}</bs-button>
        </div>
      </template>
      <div class="table-responsive-sm">
        <table class="table table-sm table-bs mb-0">
          <thead>
          <tr>
            <th scope="col" style="width: 120px">{{ $t('labels.date') }}</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">{{ $t('labels.task') }}</th>
            <th scope="col" class="text-center" style="width: 150px"></th>
            <th scope="col" class="text-right" style="width: 120px">{{ $t('labels.earnings') }}</th>
            <td style="width:45px"></td>
          </tr>
          </thead>
          <tbody v-for="week in [1, 2, 3, 4, 5]" :key="'week' + week">
          <tr class="bg-light">
            <th colspan="3">{{ $t('labels.week_number',  { number: week }) }}</th>
            <th>{{ tasksCompletedForWeek(week) }}</th>
            <td class="text-right">{{ $t('labels.earnings') }}</td>
            <td class="text-right">
              {{ $n(earningsForWeek(week), 'currency') }}<br>
            </td>
            <td></td>
          </tr>
          <tr class="bg-light">
            <td colspan="3">Starting: {{ weekStartDate(week) }}</td>
            <th></th>
            <td class="text-right">{{ $t('labels.cash') }}</td>
            <td class="text-right">
              {{ $n(cashPayoutsForWeek(week), 'currency') }}<br>
            </td>
            <td></td>
          </tr>
          <tr class="bg-light" v-for="invoice in invoicesForWeek(week)" :key="'invoice' + invoice.id">
            <td colspan="3">Issued: {{ getDateFormat(setISODate(invoice.issued_at)) }}</td>
            <th></th>
            <td class="text-right">{{ $t('labels.nav.invoice', { no: invoice.number}) }}</td>
            <td class="text-right">
              {{ $n(invoice.total, 'currency') }}<br>
            </td>
            <td style="width:40px">
              <bs-actions>
                <template v-slot:secondary>
                  <button class="dropdown-item" @click="view(invoice)">{{ $t('labels.view') }}</button>
                  <button class="dropdown-item" @click="downloadInvoice(invoice)">{{ $t('labels.download') }}</button>
                  <button class="dropdown-item" @click="destroyInvoice(invoice)">{{ $t('labels.delete') }}</button>
                </template>
              </bs-actions>
            </td>
          </tr>
          <tr class="bg-light" v-if="user.vendor && user.vendor.gst_registered">
            <th colspan="4"></th>
            <th class="text-right">{{ $t('labels.gst') }}</th>
            <th class="text-right">
              {{ $n(gstForWeek(week), 'currency') }}
            </th>
            <td></td>
          </tr>
          <tr class="bg-light">
            <th colspan="4"></th>
            <th class="text-right">{{ $t('labels.total') }}</th>
            <th class="text-right">
              {{ $n(totalForWeek(week), 'currency') }}<br>
            </th>
            <td></td>
          </tr>
          <tr v-for="r in resources.data['week' + week]" :key="r.id" v-show="expanded">
            <td v-if="mobileView" colspan="7">
              <div class="d-flex justify-content-between">
                <small><date :date="r.schedule.due_at" /></small>
                <div class="text-right">
                  {{ $n(taskComplete(r) ? r.contractor_total : 0, 'currency') }}<br>
                  <small v-if="r.invoice_state === 'cash'">({{ $t('labels.cash_paid', { amount: $n(r.invoice_paid, 'currency') }) }})</small>
                </div>
              </div>
              <span>{{ r.client ? r.client.name : '' }}</span><br>
              <div class="d-flex">
                <avatar v-for="id in r.schedule.users" :key="r.id + 'u' + id" :user="find(users, 'id', id)" class="mr-1" />
              </div>
              <span>{{ r.task ? r.task.task.name : '' }}</span><br>
              <div class="d-flex justify-content-between">
                <div>
                  <bs-badge class="bg-danger text-light" v-if="!r.client.active">
                    {{ $t('labels.inactive') }}
                  </bs-badge>
                  <bs-badge class="bg-warning text-light" v-else-if="r.schedule.state.state === 'skipped'">
                    {{ $t('labels.skipped') }}
                  </bs-badge>
                  <bs-badge class="bg-purple text-light" v-else-if="r.schedule.state.state === 'complete' && r.assets < 1 && !r.schedule.ignore_missing_photos">
                    <span>{{ $t('labels.photos_missing') }}</span>
                    <fa-icon :icon="['fas', 'check']" v-if="authCan('manage_invoices')" class="ml-1 clickable" v-tooltip="'Ignore Missing Photos'" @click="ignoreMissingPhotos(r.schedule.id)"></fa-icon>
                  </bs-badge>
                  <bs-badge :class="r.schedule.state.class" v-else>
                    {{ $t('labels.' + r.schedule.state.state) }}
                  </bs-badge>
                </div>
                <bs-actions v-if="r.schedule.state.state === 'pending' && r.client.active && authCan('manage_invoices')">
                  <template v-slot:secondary>
                    <button class="dropdown-item" @click="complete(r.schedule.id)">{{ $t('labels.complete') }}</button>
                  </template>
                </bs-actions>
              </div>
            </td>
            <td v-if="!mobileView"><date :date="r.schedule.due_at" /></td>
            <td v-if="!mobileView">{{ r.client ? r.client.name : '' }}</td>
            <td v-if="!mobileView">
              <div class="d-inline-block mr-1" v-for="id in r.schedule.users" :key="r.id + 'u' + id">
                <avatar :user="find(users, 'id', id)" />
              </div>
            </td>
            <td v-if="!mobileView">
              {{ r.task ? r.task.task.name : '' }}
              <span v-if="r.task && r.task.task.consumable" class="bg-yellow font-weight-bold px-1">x{{ r.quantity }}</span>
            </td>
            <td v-if="!mobileView" class="text-center">
              <bs-badge class="bg-danger text-light" v-if="!r.client.active">
                {{ $t('labels.inactive') }}
              </bs-badge>
              <bs-badge class="bg-warning text-light" v-else-if="r.schedule.state.state === 'skipped'">
                {{ $t('labels.skipped') }}
              </bs-badge>
              <bs-badge class="bg-purple text-light" v-else-if="r.schedule.state.state === 'complete' && r.assets < 1 && !r.schedule.ignore_missing_photos">
                <span>{{ $t('labels.photos_missing') }}</span>
                <fa-icon :icon="['fas', 'check']" v-if="authCan('manage_invoices')" class="ml-1 clickable" v-tooltip="'Ignore Missing Photos'" @click="ignoreMissingPhotos(r.schedule.id)"></fa-icon>
              </bs-badge>
              <bs-badge :class="r.schedule.state.class" v-else>
                {{ $t('labels.' + r.schedule.state.state) }}
              </bs-badge>
            </td>
            <td v-if="!mobileView" class="text-right">
              {{ $n(taskComplete(r) ? r.contractor_total : 0, 'currency') }}<br>
              <small v-if="r.invoice_state === 'cash'">({{ $t('labels.cash_paid', { amount: $n(r.invoice_paid, 'currency') }) }})</small>
            </td>
            <td v-if="!mobileView">
              <bs-actions v-if="r.schedule.state.state === 'pending' && r.client.active && authCan('manage_invoices')">
                <template v-slot:secondary>
                  <button class="dropdown-item" @click="complete(r.schedule.id)">{{ $t('labels.complete') }}</button>
                </template>
              </bs-actions>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </bs-card>
    <portal to="app-modal">
      <upload-invoice-form ref="uploadInvoice" @reload="index" />
      <bs-modal :name="'destroy-VendorInvoice'" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destructionInvoice">
          {{ $t('messages.delete', { resource: camelToSpace(singular('VendorInvoice')) }) }}
        </bs-form>
      </bs-modal>
      <bs-modal
          :name="'file-view-' + user.id"
          :label="labels.view" @closed="closeFileView"
          :fullScreen="true"
      >
        <div v-if="!fileSource">Retrieving File</div>
        <img v-else-if="activeFile && activeFile.is_image" :src="fileSource" style="max-width:100%;max-height:100%" />
        <embed v-else-if="activeFile" :src="fileSource" style="width: 100%;height: 100%" />
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import queue from '../../router/queue.js'
import { Sec } from '../../mixins/sec'
import { authMethods } from '../../mixins/authMethods'
import { watchWindowSize, setISODate, getDateFormat } from '../../mixins/helpers'
import UploadInvoiceForm from '../forms/UploadInvoiceForm'
import submit from '../../router/submit'

export default {
  name: 'ContractorEarnings',
  props: ['user', 'month'],
  mixins: [resourceControl, authMethods, watchWindowSize, setISODate, getDateFormat],
  components: {
    'upload-invoice-form': UploadInvoiceForm
  },
  data () {
    return {
      icon: ['fal', 'hands-usd'],
      expanded: false,
      resources: {
        data: {
          week1: [],
          week2: [],
          week3: [],
          week4: [],
          week5: []
        }
      },
      activeFile: null,
      fileSource: null
    }
  },
  computed: {
    ...mapState({
      users: state => state.usersStore
    })
  },
  watch: {
    month (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.index()
      }
    }
  },
  methods: {
    index (page, count, filters) {
      queue.get('/api/contractor-earnings/' + this.user.id, {
        params: {
          month: new Sec(this.month).YmdDate()
        },
        data: {
          message: this.$t('messages.simple_loading', {
            resource: this.user.name
          })
        }
      })
        .then(res => {
          this.resources = res.data
        })
    },
    ignoreMissingPhotos (id) {
      this.$store.dispatch('update', {
        collection: 'UserSchedules',
        url: '/api/schedule/' + id + '/ignore-missing-photos'
      }).then(res => {
        this.mergeUpdatedSchedule(id, res)
      })
    },
    view (invoice) {
      this.activeFile = invoice.document_id
      this.$modal.show('file-view-' + this.user.id)
      submit.get('api/documents/' + invoice.document_id + '/stream').then((res) => {
        this.fileSource = res.data
      })
    },
    closeFileView () {
      this.fileSource = null
    },
    complete (id) {
      this.$store.dispatch('update', {
        collection: 'Schedules',
        url: '/api/schedule/' + id + '/complete'
      }).then(res => {
        this.mergeUpdatedSchedule(id, res)
      })
    },
    downloadInvoice (i) {
      this.$store.dispatch('download', {
        path: 'vendor-invoices/' + i.id + '/download',
        accept: 'application/pdf'
      })
    },
    destroyInvoice (i) {
      this.activeId = i.id
      this.$modal.show('destroy-VendorInvoice')
    },
    destructionInvoice () {
      this.$store.dispatch('destroy', { collection: 'VendorInvoices', id: this.activeId }).then(() => {
        this.$modal.hide('destroy-VendorInvoice')
        this.index()
      })
      this.activeId = null
    },
    mergeUpdatedSchedule (id, res) {
      for (let week = 1; week < 6; week++) {
        this.resources.data['week' + week].forEach(task => {
          if (task.schedule.id === id) task.schedule = res.data.data
        })
      }
    },
    earningsForWeek (week) {
      let payout = 0
      this.resources.data['week' + week].forEach(t => {
        payout += parseFloat(this.taskComplete(t) ? t.contractor_total : 0)
      })
      return payout
    },
    cashPayoutsForWeek (week) {
      let payout = 0
      const tasks = this._.uniqBy(this.resources.data['week' + week], 'invoice_id')
      tasks.forEach(t => {
        if (t.invoice_state === 'cash') {
          payout += parseFloat(this.taskComplete(t) ? t.invoice_paid : 0)
        }
      })
      return payout
    },
    invoicesForWeek (week) {
      return this.resources.data['week' + week + 'Invoices']
    },
    taskComplete (task) {
      return task.schedule.state.state === 'complete' && (task.assets > 0 || task.schedule.ignore_missing_photos)
    },
    subTotalForWeek (week) {
      return this.earningsForWeek(week) - this.cashPayoutsForWeek(week)
    },
    gstForWeek (week) {
      if (this.user.vendor && this.user.vendor.gst_registered) {
        return (this.earningsForWeek(week) - this.cashPayoutsForWeek(week)) * 0.1
      }
      return 0
    },
    totalForWeek (week) {
      return this.subTotalForWeek(week) + this.gstForWeek(week)
    },
    tasksCompletedForWeek (week) {
      let total = 0
      let completed = 0
      this.resources.data['week' + week].forEach(t => {
        total += 1
        completed += t.contractor_total > 0 ? 1 : 0
      })
      return completed + ' / ' + total
    },
    weekStartDate (week) {
      const iso = this.resources.data['week' + week + 'Start']
      if (iso) {
        const date = this.setISODate(iso)
        return this.getDateFormat(date)
      }
      return null
    }
  }
}
</script>
