<template>
    <th scope="col" :class="sortable ? 'clickable' : ''" @click="sort">
        <slot>
            <fa-icon v-if="tip" :icon="['fas', 'info-circle']" class="tip mr-1" v-tooltip.top="tip" style="position:relative;top:1px" />
            <span>{{ label }}</span>
            <span v-if="sortable && sortable.col === col" class="ml-2">
                <fa-icon v-if="sortable.dir === 'desc'" class="sortable-icon" :icon="['fas', 'long-arrow-alt-up']"></fa-icon>
                <fa-icon v-if="sortable.dir === 'asc'" class="sortable-icon" :icon="['fas', 'long-arrow-alt-down']"></fa-icon>
            </span>
        </slot>
    </th>
</template>

<script>
export default {
  name: 'BSTableHead',
  props: ['label', 'sortable', 'col', 'tip'],
  methods: {
    sort () {
      if (this.sortable) {
        let dir = this.sortable.dir
        if (this.sortable.col === this.col) {
          dir = dir === 'asc' ? 'desc' : 'asc'
        }
        this.$emit('click', { col: this.col, dir })
      }
    }
  }
}
</script>

<style lang="scss">
    .sortable-icon {
        position: relative;
        top: -1px;
    }
</style>
