<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.my_profile') }}</h3>
    </route-header>
    <div class="row mt-3">
      <div class="col-12 col-sm-6 col-lg-4 mb-3">
        <bs-card class="card-info text-center">
          <img v-if="profile.avatar" :src="appURL + '/storage/' + profile.avatar" height="180" class="img-circle my-4" />
          <h4>{{ profile.name }}</h4>
          <p class="text-uppercase"><small><strong>{{ profile.account }}</strong></small></p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-left">
              <small class="text-uppercase">{{ $t('labels.role') }}:</small>
              <span> {{ profile.role.name }}</span>
            </li>
            <li class="list-group-item text-left">
              <small class="text-uppercase">{{ $t('labels.email') }}:</small>
              <span> {{ profile.email }}</span>
            </li>
          </ul>
          <template v-slot:footer>
            <div class="m-3" v-if="auth.id === profile.id">
              <bs-button class="btn-sm btn-info" @click="update">{{ $t('labels.update') }}</bs-button>
            </div>
          </template>
        </bs-card>
      </div>
      <div class="col-12 col-sm-6 col-lg-8 mb-3">
        <user-private-documents :user="profile.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <away-events :user="profile.id" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <conflicting-schedules :user="profile.id" />
      </div>
    </div>
    <bs-modal :name="'create-Profile'" :label="$t('labels.update_user')">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="{ error }">
          <file-field :name="'picture'" :label="$t('labels.profile_picture')" v-model="resource.picture" :error="error"></file-field>
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
          <tuxedo-input :type="'email'" :name="'email'" :label="$t('labels.email')" v-model="resource.email" :error="error"></tuxedo-input>
          <tuxedo-input :type="'password'" :name="'password'" :label="$t('labels.password')" :help="$t('messages.password_not_required')" v-model="resource.password" :error="error"></tuxedo-input>
          <tuxedo-input :name="'abn'" :label="$t('labels.abn')" v-model="resource.abn" :error="error"></tuxedo-input>
          <tuxedo-input :name="'bank_bsb'" :label="$t('labels.bsb')" v-model="resource.bank_bsb" :error="error"></tuxedo-input>
          <tuxedo-input :name="'bank_account'" :label="$t('labels.bank_account')" v-model="resource.bank_account" :error="error"></tuxedo-input>
          <tuxedo-input :name="'legal_name'" :label="$t('labels.legal_name')" v-model="resource.legal_name" :error="error"></tuxedo-input>
          <tuxedo-input :name="'legal_business_name'" :label="$t('labels.legal_business_name')" v-model="resource.legal_business_name" :error="error"></tuxedo-input>
        </template>
      </bs-form>
    </bs-modal>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import submit from '../router/submit'

export default {
  name: 'Profile',
  props: ['user'],
  data () {
    return {
      appURL: process.env.VUE_APP_ASSET_URL,
      resource: {
        picture: '',
        name: '',
        email: '',
        password: '',
        abn: '',
        bank_bsb: '',
        bank_account: '',
        legal_name: '',
        legal_business_name: ''
      },
      notifications: {
        never: this.$t('labels.resource_state.never'),
        instant: this.$t('labels.resource_state.instant'),
        daily: this.$t('labels.resource_state.daily'),
        weekly: this.$t('labels.resource_state.weekly')
      }
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      users: state => state.usersStore
    }),
    profile () {
      if (this.user) return this._.find(this.users, u => u.id === parseInt(this.user))
      return this.auth
    }
  },
  created () {
    this.resource.picture = null
    this.resource.name = this.profile.name
    this.resource.email = this.profile.email
    this.resource.abn = this.profile.abn
    this.resource.bank_bsb = this.profile.bank_bsb
    this.resource.bank_account = this.profile.bank_account
    this.resource.legal_name = this.profile.legal_name
    this.resource.legal_business_name = this.profile.legal_business_name
    this.resource.password = null
  },
  methods: {
    update () {
      this.$store.commit('axiosError', null)
      this.$modal.show('create-Profile')
    },
    submit () {
      this.$store.commit('submitting', true)
      const formData = new FormData()
      for (const key in this.resource) {
        formData.append(key, this.resource[key] ?? '')
      }
      submit.post('/api/profile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.$store.dispatch('showUser')
          this.$modal.hide('create-Profile')
          this.$store.commit('submitting', false)
        })
    }

  }
}
</script>
