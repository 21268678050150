<template>
  <div class="h-100">
    <div class="row justify-content-center bg-primary" style="height: 50px;">
      <img class="h-100" src="/img/logo.png" />
    </div>
    <div id="login" class="d-flex justify-content-center align-items-center" :style="'background-image: url(\'' + appURL + '/img/login-background.jpg\');'">
      <div class="h-auto">
        <bs-card class="card-info" :label="$t('labels.sign_in')" :icon="['fal', 'user-lock']">
          <bs-form style="min-width: 380px;" @submit="login" :submitting="submitting">
            <template v-slot:default="slotProps">
              <tuxedo-input :name="'email'" :type="'email'" :label="'Email Address'" v-model="form.email" :error="slotProps.error"></tuxedo-input>
              <tuxedo-input :name="'password'" :type="'password'" :label="'Password'" v-model="form.password" :error="slotProps.error"></tuxedo-input>
            </template>
            <template v-slot:footer>
              <div class="d-flex justify-content-between align-items-center">
                <bs-button type="submit" class="btn-info" :submitting="submitting">
                  <span>{{ $t('labels.sign_in') }}</span>
                </bs-button>
                <div>
                  <a href="#"><small>{{ $t('labels.forgot_password') }}</small></a>
                </div>
              </div>
            </template>
          </bs-form>
        </bs-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      appURL: process.env.VUE_APP_ASSET_URL,
      submitting: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      this.submitting = true
      this.$store.dispatch('login', this.form).then(() => {
        this.submitting = false
      }).catch(() => { this.submitting = false })
    }
  }
}
</script>

<style lang="scss">
#login {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;

  .card {
    max-width: 500px;
  }
}
</style>
