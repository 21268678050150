import Vue from 'vue'

// Framework
import AppHeader from './framework/Header'
import HeaderButton from './framework/HeaderButton'
import AppNavigation from './framework/Navigation'
import NavItem from './framework/NavItem'

// Forms
import BSForm from './forms/BSForm'
import CheckboxField from './forms/CheckboxField'
import SelectField from './forms/SelectField'
import ToggleField from './forms/ToggleField'
import FileField from './forms/FileField'
import RadioSlider from './forms/RadioSlider'
import CheckboxSlider from './forms/CheckboxSlider'
import TextField from './forms/TextField'
import MultiImageUpload from './forms/MultiImageUpload'
import TaskForm from './forms/TaskForm'

// Fields
import TaskField from './fields/TaskField'

// Elements
import RouteHeader from './elements/RouteHeader'
import RouteNavigation from './elements/RouteNavigation'
import Avatar from './elements/Avatar'
import BSButton from './elements/BSButton'
import BSAction from './elements/BSAction'
import BSActions from './elements/BSActions'
import BSBadge from './elements/BSBadge'
import BSCard from './elements/BSCard'
import BSTable from './elements/BSTable'
import BSTableHead from './elements/BSTableHead'
import BSAlert from './elements/BSAlert'
import BSModal from './elements/BSModal'
import Pagination from './elements/Pagination'
import Loading from './elements/Loading'
import APIQueue from './elements/APIQueue'
import ChunkProcess from './elements/ChunkProcess'
import CardLegend from './elements/CardLegend'
import DateTime from './elements/DateTime'
import Date from './elements/Date'
import TimeAgo from './elements/TimeAgo'

// Content
import NavSecondarySettings from './content/NavSecondarySettings'
import NavSecondaryClient from './content/NavSecondaryClient'
import NavSecondaryAccounts from './content/NavSecondaryAccounts'
import Clients from './content/Clients'
import Users from './content/Users'
import Roles from './content/Roles'
import Suburbs from './content/Suburbs'
import Address from './content/Address'
import Addresses from './content/Addresses'
import Contacts from './content/Contacts'
import Notes from './content/Notes'
import Tasks from './content/Tasks'
import TaskGroups from './content/TaskGroups'
import ClientSchedules from './content/ClientSchedules'
import UserSchedules from './content/UserSchedules'
import UserMonthlyProgress from './content/UserMonthlyProgress'
import ContractorMonthlyProgress from './content/ContractorMonthlyProgress'
import TaskGroupCard from './content/TaskGroupCard'
import TaskGroupReport from './content/TaskGroupReport'
import ActivityLog from './content/ActivityLog'
import Invoices from './content/Invoices'
import InvoiceEditor from './content/InvoiceEditor'
import Transactions from './content/Transactions'
import Vendors from './content/Vendors'
import ExpenseCategories from './content/ExpenseCategories'
import Expenses from './content/Expenses'
import ClientSuspension from './content/ClientSuspension'
import ContractorEarnings from './content/ContractorEarnings'
import AwayEvents from './content/AwayEvents'
import ConflictingSchedules from './content/ConflictingSchedules'
import AwayEventsCalendar from './content/AwayEventsCalendar'
import UserPrivateDocuments from './content/UserPrivateDocuments'
import Documents from './content/Documents'
import ExpenseDocuments from './content/ExpenseDocuments'
import AccountActivity from './content/AccountActivity'
import IncomeChart from './content/IncomeChart'
import ExpenseChart from './content/ExpenseChart'
import ClientConsumables from './content/ClientConsumables'

import { TuxedoInput, TuxedoText, TuxedoRadio, TuxedoSelect, TuxedoDate, TuxedoTime, TuxedoToggle, TuxedoSecondaryMenu } from 'tuxedo-elements'
import VueApexCharts from 'vue-apexcharts'

const components = {
  TuxedoInput,
  TuxedoText,
  TuxedoRadio,
  TuxedoSelect,
  TuxedoDate,
  TuxedoTime,
  TuxedoToggle,
  TuxedoSecondaryMenu
}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.component('app-header', AppHeader)
Vue.component('header-button', HeaderButton)
Vue.component('app-navigation', AppNavigation)
Vue.component('nav-item', NavItem)

// Forms
Vue.component('bs-form', BSForm)
Vue.component('checkbox-field', CheckboxField)
Vue.component('select-field', SelectField)
Vue.component('toggle-field', ToggleField)
Vue.component('file-field', FileField)
Vue.component('radio-slider', RadioSlider)
Vue.component('checkbox-slider', CheckboxSlider)
Vue.component('text-field', TextField)
Vue.component('multi-image-upload', MultiImageUpload)
Vue.component('task-form', TaskForm)

// Fields
Vue.component('task-field', TaskField)

Vue.component('route-header', RouteHeader)
Vue.component('route-navigation', RouteNavigation)
Vue.component('avatar', Avatar)
Vue.component('bs-button', BSButton)
Vue.component('bs-action', BSAction)
Vue.component('bs-actions', BSActions)
Vue.component('bs-badge', BSBadge)
Vue.component('bs-card', BSCard)
Vue.component('bs-table', BSTable)
Vue.component('bs-th', BSTableHead)
Vue.component('bs-alert', BSAlert)
Vue.component('bs-modal', BSModal)
Vue.component('pagination', Pagination)
Vue.component('loading', Loading)
Vue.component('queue', APIQueue)
Vue.component('chunk-process', ChunkProcess)
Vue.component('card-legend', CardLegend)
Vue.component('date-time', DateTime)
Vue.component('date', Date)
Vue.component('time-ago', TimeAgo)

Vue.component('nav-secondary-settings', NavSecondarySettings)
Vue.component('nav-secondary-client', NavSecondaryClient)
Vue.component('nav-secondary-accounts', NavSecondaryAccounts)
Vue.component('clients', Clients)
Vue.component('users', Users)
Vue.component('roles', Roles)
Vue.component('suburbs', Suburbs)
Vue.component('bs-address', Address)
Vue.component('addresses', Addresses)
Vue.component('contacts', Contacts)
Vue.component('notes', Notes)
Vue.component('tasks', Tasks)
Vue.component('task-groups', TaskGroups)
Vue.component('client-schedules', ClientSchedules)
Vue.component('user-schedules', UserSchedules)
Vue.component('user-monthly-progress', UserMonthlyProgress)
Vue.component('contractor-monthly-progress', ContractorMonthlyProgress)
Vue.component('task-group-card', TaskGroupCard)
Vue.component('task-group-report', TaskGroupReport)
Vue.component('activity-log', ActivityLog)
Vue.component('invoices', Invoices)
Vue.component('invoice-editor', InvoiceEditor)
Vue.component('transactions', Transactions)
Vue.component('vendors', Vendors)
Vue.component('expense-categories', ExpenseCategories)
Vue.component('expenses', Expenses)
Vue.component('client-suspension', ClientSuspension)
Vue.component('contractor-earnings', ContractorEarnings)
Vue.component('away-events', AwayEvents)
Vue.component('conflicting-schedules', ConflictingSchedules)
Vue.component('away-events-calendar', AwayEventsCalendar)
Vue.component('user-private-documents', UserPrivateDocuments)
Vue.component('documents', Documents)
Vue.component('expense-documents', ExpenseDocuments)
Vue.component('account-activity', AccountActivity)
Vue.component('income-chart', IncomeChart)
Vue.component('expense-chart', ExpenseChart)
Vue.component('client-consumables', ClientConsumables)
