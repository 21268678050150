<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <bs-table v-bind="{dataLength, loading}" :cols="4">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.task')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th class="text-right" :label="$t('labels.default_price.label')"></bs-th>
            <bs-th class="text-right" :label="$t('labels.average_use.label')" :tip="$t('labels.average_use.help')"></bs-th>
            <bs-th class="text-right" :label="$t('labels.mode_use.label')" :tip="$t('labels.mode_use.help')"></bs-th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.name }}</td>
          <td class="text-right">{{ $n(r.default_price, 'currency') }}</td>
          <td class="text-right">{{ r.average }}</td>
          <td class="text-right">{{ r.mode }}</td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" :counts="[2, 5]" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'ClientConsumables',
  mixins: [resourceControl],
  props: ['invoice'],
  data () {
    return {
      icon: ['fal', 'toilet-paper']
    }
  },
  computed: {
    ...mapState({
      resources: state => state.clientConsumables,
      resourceState: state => state.clientConsumablesState
    }),
    apiTarget () {
      return ['client-consumables', this.invoice]
    }
  }
}
</script>
