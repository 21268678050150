<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <apexchart type="treemap" :options="chartOptions" :series="[{ data: series }]"></apexchart>
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ExpenseChart',
  data () {
    return {
      icon: ['fal', 'chart-line-down'],
      labels: {
        name: 'Expenses'
      },
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      expenseByVendor: state => state.expenseByVendor.data
    }),
    series () {
      if (this.expenseByVendor) {
        return this.expenseByVendor.map(elem => {
          return { x: elem.label, y: elem.total }
        })
      }
      return {}
    }
  },
  mounted () {
    this.$store.dispatch('index', { collection: 'expenseByVendor' })
  }
}
</script>
