<template>
    <div class="m-2 p-1 bg-light d-flex flex-wrap card-legend">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'CardLegend'
}
</script>

<style lang="scss">

    .card-legend {
        font-size: 0.875rem;
    }
</style>
