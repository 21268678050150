<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <bs-table v-bind="{dataLength, loading}" :cols="10">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.year')"></bs-th>
            <bs-th :label="$t('labels.quarter')"></bs-th>
            <bs-th :label="$t('labels.incoming')"></bs-th>
            <bs-th :label="$t('labels.incoming_tax')"></bs-th>
            <bs-th :label="$t('labels.outgoing')"></bs-th>
            <bs-th :label="$t('labels.outgoing_tax')"></bs-th>
            <bs-th :label="$t('labels.tax_total')"></bs-th>
            <bs-th :label="$t('labels.payg')"></bs-th>
            <bs-th scope="col"></bs-th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tbody v-for="(year, y) in financialYears" :key="'year' + y">
          <tr>
            <th>{{ year[0].year }}</th>
            <th></th>
            <th>{{ $n(_.sumBy(year, 'incoming'), 'currency') }}</th>
            <th>{{ $n(_.sumBy(year, 'incoming_tax'), 'currency') }}</th>
            <th>{{ $n(_.sumBy(year, 'outgoing'), 'currency') }}</th>
            <th>{{ $n(_.sumBy(year, 'outgoing_tax'), 'currency') }}</th>
            <th>{{ $n(_.sumBy(year, 'incoming_tax') - _.sumBy(year, 'outgoing_tax'), 'currency') }}</th>
            <th>{{ $n(_.sumBy(year, 'payg'), 'currency') }}</th>
            <td></td>
            <td>
              <bs-actions>
                <template v-slot:secondary>
                  <button class="dropdown-item" @click="downloadYearProfitLoss(year[0].year)">{{ $t('labels.action_resource', { action: $t('labels.download'), resource: $t('labels.profit_loss') }) }}</button>
                </template>
              </bs-actions>
            </td>
          </tr>
          <tr v-for="r in year" :key="r.id">
            <td>{{ $t('labels.activity_year', { from: r.year - 1, to: r.year }) }}</td>
            <td>{{ $t('labels.activity_quarter', { quarter: r.quarter }) }}</td>
            <td>{{ $n(r.incoming, 'currency') }}</td>
            <td>{{ $n(r.incoming_tax, 'currency') }}</td>
            <td>{{ $n(r.outgoing, 'currency') }}</td>
            <td>{{ $n(r.outgoing_tax, 'currency') }}</td>
            <td>{{ $n(r.incoming_tax - r.outgoing_tax, 'currency') }}</td>
            <td>{{ $n(r.payg, 'currency') }}</td>
            <td>
              <bs-badge v-if="r.bas_complete" class="badge-success">{{ $t('labels.complete') }}</bs-badge>
            </td>
            <td class="t2">
              <bs-actions>
                <template v-slot:primary>
                  <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
                </template>
                <template v-slot:secondary>
                  <button class="dropdown-item" @click="promptEmail(r)">{{ $t('labels.action_resource', { action: $t('labels.email'), resource: $tc('labels.account_activity', 1) }) }}</button>
                  <button class="dropdown-item" @click="downloadProfitLoss(r)">{{ $t('labels.action_resource', { action: $t('labels.download'), resource: $t('labels.profit_loss') }) }}</button>
                  <button class="dropdown-item" @click="displayProfitLoss(r)">{{ $t('labels.action_resource', { action: $t('labels.display'), resource: $t('labels.profit_loss') }) }}</button>
                  <button v-if="false" class="dropdown-item" @click="destroy(r)">{{ labels.email }}</button>
                </template>
              </bs-actions>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="{ error }">
            <table class="table table-striped table-sm">
              <thead>
              <tr>
                <th colspan="2">
                  <span>{{ $t('labels.activity_year', { from: resource.year - 1, to: resource.year }) }}</span>
                  <small class="ml-2">{{ $t('labels.activity_quarter', { quarter: resource.quarter }) }}</small>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>
                  <small>{{ $t('labels.incoming') }} (Incl. GST)</small>
                </th>
                <td class="text-right">{{ $n(resource.incoming, 'currency') }}</td>
              </tr>
              <tr>
                <th>
                  <small>{{ $t('labels.incoming_tax') }}</small>
                </th>
                <td class="text-right">{{ $n(resource.incoming_tax, 'currency') }}</td>
              </tr>
              <tr>
                <th>
                  <small>{{ $t('labels.outgoing_tax') }}</small>
                </th>
                <td class="text-right">{{ $n(resource.outgoing_tax, 'currency') }}</td>
              </tr>
              </tbody>
            </table>
            <tuxedo-input :type="'number'" :name="'payg'" :label="$t('labels.payg_contribution')" v-model="resource.payg" :error="error" />
            <tuxedo-toggle :name="'bas_complete'" :label="$t('labels.mark_bas')" v-model="resource.bas_complete" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'email-' + $options.name" :label="labels.send">
        <bs-form :submit="$t('labels.submit')" @submit="sendEmail">
          <template v-slot:default="{ error }">
            <tuxedo-select :options="users" :insistLabel="'name'" :multi="true" v-model="email.users" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import submit from '../../router/submit'
import { groupBy } from 'lodash'

export default {
  name: 'AccountActivity',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'chart-pie'],
      resource: {
        year: 0,
        quarter: 0,
        incoming: 0,
        incoming_tax: 0,
        outgoing_tax: 0,
        payg: 0,
        bas_complete: false
      },
      email: {
        users: []
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.accountActivity,
      resourceState: state => state.accountActivityState,
      users: state => state.usersStore
    }),
    financialYears () {
      const data = groupBy(this.resources.data, r => r.year)
      return Object.keys(data).sort().reverse().map(k => data[k])
    }
  },
  methods: {
    promptEmail (o) {
      this.$store.commit('axiosError', null)
      this.activeId = o.id
      this.email.users = []
      this.$modal.show('email-' + this.$options.name)
    },
    sendEmail () {
      this.$store.commit('submitting', true)
      submit.post('/api/account-activity/' + this.activeId + '/email', this.email).then(() => {
        this.$store.commit('submitting', false)
        this.activeId = null
        this.$modal.hide('email-' + this.$options.name)
      })
    },
    downloadProfitLoss (r) {
      this.$store.dispatch('download', {
        path: 'account-activity/' + r.id + '/download',
        accept: 'application/pdf'
      })
    },
    downloadYearProfitLoss (y) {
      this.$store.dispatch('download', {
        path: 'account-activity-year/' + y + '/download',
        accept: 'application/pdf'
      })
    },
    displayProfitLoss (r) {
      this.$store.dispatch('index', { collection: 'incomeByClient', id: r.id })
      this.$store.dispatch('index', { collection: 'expenseByVendor', id: r.id })
    }
  }
}
</script>
