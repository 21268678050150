import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import axiosConfig from './router/axios'
import VModal from 'vue-js-modal'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import helpers from './helpers'
import VTooltip from 'v-tooltip'
import { i18n } from './i18n'

require('./bugsnag')

require('./components/components')
require('./icons')

Vue.config.productionTip = false

Vue.use(PortalVue)

axiosConfig()

Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(VueLodash, { lodash })
Vue.use(VTooltip)
Vue.mixin({ methods: helpers })

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
