import { mapState } from 'vuex'

export const authMethods = {
  computed: {
    ...mapState({
      auth: state => state.auth
    })
  },
  methods: {
    authCan (permission) {
      return this.auth && this.auth.can && this.auth.can[permission]
    }
  }
}
