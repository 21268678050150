<template>
  <div>
    <bs-card class="card-info" :icon="['fal', 'city']" :label="$t('labels.' + camelToSnake($options.name))">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="3">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.name')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.state')" :sortable="resourceState.sort" :col="'region_id'" @click="applySort"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.name }}</td>
          <td>{{ r.region }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="slotProps.error"></tuxedo-input>
          <tuxedo-select :name="'region_id'" :label="$t('labels.state')" v-model="resource.region_id" :options="regions.data" :insistReturn="'id'" :insistLabel="'name'" :error="slotProps.error" />
          <tuxedo-input :name="'code'" :label="$t('labels.postcode')" v-model="resource.code" :error="slotProps.error"></tuxedo-input>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { resourceControl } from '../../mixins/resourceControl'
import { mapState } from 'vuex'

export default {
  name: 'Suburbs',
  mixins: [resourceControl],
  data () {
    return {
      loading: false,
      dataLoading: false,
      activeId: null,
      resource: {
        name: '',
        region_id: null,
        code: ''
      }
    }
  },
  computed: {
    ...mapState({
      regions: state => state.regions,
      resourceState: state => state.suburbsState,
      resourceStore: state => state.suburbsStore,
      resources: state => state.suburbs
    })
  }
}
</script>
