<template>
  <form class="p-3 bg-light" @submit.prevent="$emit('submit')">
    <slot v-bind:error="errors">
      <div class="d-flex justify-content-center my-5" id="dataLoading">
        <fa-icon :icon="['fal', 'atom-alt']" class="loading fa-spin"></fa-icon>
      </div>
    </slot>
    <bs-alert v-if="axiosError" class="alert-danger">
      <span>{{ axiosError.message }}</span><br>
      <ul class="pl-4 mb-1">
        <li v-for="(error, name) in axiosError.errors" :key="name">{{ error[0] }}</li>
      </ul>
    </bs-alert>
    <slot name="footer">
      <div class="d-flex justify-content-end align-items-center">
        <bs-button v-if="submit" type="submit" class="btn-info" :submitting="submitting" >
          <span>{{ submit }}</span>
        </bs-button>
        <bs-button v-if="destroy" type="submit" class="btn-danger" :submitting="submitting" >
          <span>{{ destroy }}</span>
        </bs-button>
      </div>
    </slot>
  </form>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BSForm',
  props: ['submit', 'destroy'],
  computed: {
    ...mapState({
      axiosError: state => state.axiosError,
      submitting: state => state.submitting
    }),
    errors () {
      if (this.axiosError) return this.axiosError.errors
      return null
    }
  }
}
</script>

<style lang="scss">
#dataLoading {
  font-size: 2rem;
}
</style>
