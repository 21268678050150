<template>
  <div class="row" v-if="resource.suspended">
    <div class="col">
      <bs-alert class="bg-danger text-white d-flex justify-content-between align-items-center mb-2 shadow-sm">
        <h5 class="mb-0">{{ $t('labels.suspended') }}</h5>
        <bs-button class="btn-sm bg-light" @click="prepareUnsuspension">{{ $t('labels.unsuspend') }}</bs-button>
      </bs-alert>
    </div>
    <bs-modal :name="'unsuspend-service-' + $options.name" :label="$t('labels.unsuspend')">
      <bs-form :submit="$t('labels.unsuspend')" @submit="unsuspendService">
        {{ $t('messages.unsuspend_service', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import submit from '../../router/submit.js'

export default {
  name: 'ClientSuspension',
  computed: {
    ...mapState({
      resource: state => state.client.data
    })
  },
  methods: {
    prepareUnsuspension () {
      this.$modal.show('unsuspend-service-' + this.$options.name)
    },
    unsuspendService () {
      this.$store.commit('submitting', true)
      submit.get('api/clients/' + this.resource.id + '/unsuspend-service').then((res) => {
        this.$store.commit('processResponse', { resource: 'client', data: res.data })
        this.$store.commit('submitting', false)
        this.$modal.hide('unsuspend-service-' + this.$options.name)
      })
    }
  }
}
</script>
