import axios from 'axios'
import submit from './submit'
import queue from './queue'

export default function axiosConfig () {
  axios.defaults.withCredentials = true
  axios.defaults.baseURL = process.env.VUE_APP_URL
  submit.defaults.withCredentials = axios.defaults.withCredentials
  submit.defaults.baseURL = axios.defaults.baseURL
  queue.defaults.withCredentials = axios.defaults.withCredentials
  queue.defaults.baseURL = axios.defaults.baseURL
}
