<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.accounts') }}</h3>
      <nav-secondary-accounts />
    </route-header>
    <div class="row">
      <div class="col-12 mb-4"
           v-if="authCan('manage_transactions')">
        <transactions />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'AccountsTransactions',
  mixins: [authMethods],
  data () {
    return {
    }
  },
  methods: {
    ...mapMutations([
      'pushFilter'
    ])
  }
}
</script>
