<template>
  <div class="d-flex justify-content-end">
    <slot name="primary"></slot>
    <div class="d-inline-block" :id="id">
      <button class="btn btn-sm shadow-sm btn-action bg-gray-400 text-gray-600" @click="secondaryMenu = true">
          <fa-icon :icon="['fal', 'ellipsis-h-alt']"></fa-icon>
      </button>
      <portal to="app-modal">
        <tuxedo-secondary-menu v-if="secondaryMenu" @close="secondaryMenu = false" :target="id" class="overflow-x-hidden">
          <slot name="secondary">
          </slot>
        </tuxedo-secondary-menu>
      </portal>
    </div>
  </div>
</template>

<script>
import { makeId } from '../../mixins/helpers'

export default {
  name: 'BTNActions',
  props: ['icon', 'tip'],
  mixins: [makeId],
  computed: {
    id () {
      return this.makeId()
    }
  },
  data () {
    return {
      secondaryMenu: false
    }
  }
}
</script>

<style lang="scss">
.overflow-x-hidden .tuxedo-secondary-menu-options .options {
  overflow-x: hidden;
}

button.btn.btn-action {
  transition: all .15s ease;
  border-radius: 1rem;
  font-size: 0.69rem;
  padding: 0.25rem 0.43rem;
  width: 26px;
}

button.shadow-sm {
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);

  &:hover {
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    transform: translateY(-1px);
  }
}

.dropleft:focus-within {

  > .dropdown-menu {
    display: block;
  }
}

tr:last-child .dropleft .dropdown-menu {
  top: auto;
  bottom: 0;
}

tr:nth-last-child(2) .dropleft .dropdown-menu {
  top: auto;
  bottom: 0;
}
</style>
