<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.clients') }}</h3>
      <div class="d-flex align-items-center">
        <bs-button class="btn-sm btn-warning mr-2" v-if="authCan('manage_clients')" @click="exportClients">{{ $t('labels.action_resource', { action: $t('labels.export'), resource: $tc('resource.client', 0) }) }}</bs-button>
        <bs-button class="btn-sm btn-info" @click="$refs.clients.create()">{{ $t('labels.create_client') }}</bs-button>
      </div>
    </route-header>
    <div class="row">
      <div class="col-12" v-if="authCan('view_clients')">
        <clients ref="clients"></clients>
      </div>
    </div>
    <portal to="app-modal">
      <bs-modal :name="'export-' + $options.name" :label="$t('labels.action_resource', { action: $t('labels.export'), resource: $tc('resource.client', 0) })">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="">
            <tuxedo-select
                :name="'clients'"
                :label="$tc('resource.client', 0)"
                :options="filteredClients"
                :insistLabel="'name'"
                v-model="resource.clients"
                :multi="true" />
          </template>
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '../helpers.js'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'ClientList',
  mixins: [authMethods],
  data () {
    return {
      resource: {
        clients: []
      },
      loading: false
    }
  },
  computed: {
    ...mapState({
      clients: state => state.clientsStore
    }),
    filteredClients () {
      return this._.filter(this.clients, c => c.active)
    }
  },
  methods: {
    exportClients () {
      this.$store.commit('axiosError', null)
      helpers.clearData(this.resource)
      this.$modal.show('export-' + this.$options.name)
    },
    submit () {
      this.loading = true
      this.$store.dispatch('download', { path: 'clients/export', resource: this.resource }).then(() => { this.loading = false })
    }
  }
}
</script>
