<template>
  <div class="d-flex align-items-center flex-wrap">
    <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/invoices' })">{{ $tc('labels.invoice', 0) }}</bs-button>
    <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/expenses' })">{{ $tc('labels.expense', 0) }}</bs-button>
    <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/transactions' })">{{ $t('labels.nav.transactions') }}</bs-button>
    <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/activity' })">{{ $t('labels.nav.account_activity') }}</bs-button>
  </div>
</template>

<script>
export default {
  name: 'NavSecondaryAccounts'
}
</script>
