import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faUserLock, faAtomAlt, faTachometerAltSlow, faCogs, faBooks, faPencil, faTrash, faUserFriends, faAddressBook,
  faChevronLeft, faChevronRight, faCity, faMapMarkerAlt, faIdCard, faTasks, faClipboardList, faUser, faLock, faUnlock,
  faCalendarWeek, faSync, faSearch, faStore, faStoreSlash, faCalendarAlt, faCamera, faChevronDoubleLeft,
  faChevronDoubleRight, faExclamationSquare, faSignIn, faBalanceScale, faCalendarCheck, faCrosshairs, faInboxOut,
  faFilter, faExchange, faLink, faHandHoldingUsd, faEnvelopeOpenDollar, faEllipsisHAlt, faBan, faHandsUsd, faHiking,
  faExclamationTriangle, faCabinetFiling, faDownload, faChartPie, faChartLine, faChartLineDown, faToiletPaper,
  faFileAlt
} from '@fortawesome/pro-light-svg-icons'

import {
  faInfoCircle, faSignOutAlt, faTimes, faLongArrowAltDown, faLongArrowAltUp, faStickyNote, faPlus, faCheckCircle,
  faCheck, faEye, faExchangeAlt, faPaperPlane, faDollarSign, faPause, faPlay
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserLock, faAtomAlt, faTachometerAltSlow, faInfoCircle, faSignOutAlt, faTimes, faCogs, faBooks, faPencil,
  faTrash, faUserFriends, faLongArrowAltDown, faLongArrowAltUp, faAddressBook, faChevronLeft, faChevronRight, faCity,
  faMapMarkerAlt, faStickyNote, faIdCard, faTasks, faClipboardList, faUser, faLock, faUnlock, faCalendarWeek, faSync,
  faSearch, faStore, faStoreSlash, faCalendarAlt, faCamera, faPlus, faChevronDoubleLeft, faChevronDoubleRight,
  faCheckCircle, faCheck, faExclamationSquare, faSignIn, faEye, faExchangeAlt, faBalanceScale, faCalendarCheck,
  faCrosshairs, faInboxOut, faPaperPlane, faFilter, faExchange, faLink, faDollarSign, faHandHoldingUsd,
  faEnvelopeOpenDollar, faEllipsisHAlt, faBan, faHandsUsd, faHiking, faExclamationTriangle, faCabinetFiling, faDownload,
  faChartPie, faChartLine, faChartLineDown, faToiletPaper, faPause, faPlay, faFileAlt)

Vue.component('fa-icon', FontAwesomeIcon)
