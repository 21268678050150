<template>
  <bs-modal :name="formName" :label="labels.form" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.confirm')" @submit="$emit('submit', form)">
      <template v-slot:default="{ error }">
        <radio-slider :name="'rotation'" :label="$t('labels.schedule_rotation.label')" :help="$t('labels.schedule_rotation.help')" v-model="form.rotation" :options="rotations" :error="error" />
        <tuxedo-select v-if="form.rotation === 'weekly'" :name="'frequency'" :label="$t('labels.schedule_frequency.label')" :help="$t('labels.schedule_frequency.help')" v-model="form.frequency" :options="weeklyFrequencies" :insistReturn="'id'" :error="error" />
        <tuxedo-select v-if="form.rotation === 'monthly'" :name="'frequency'" :label="$t('labels.schedule_frequency.label')" :help="$t('labels.schedule_frequency.help')" v-model="form.frequency" :options="monthlyFrequencies" :insistReturn="'id'" :error="error" />
        <tuxedo-select v-if="form.rotation === 'monthly'" :name="'week_of_month'" :label="$t('labels.week_of_month.label')" :help="$t('labels.week_of_month.help')" v-model="form.week_of_month" :options="weeksOfMonth" :insistReturn="'id'" :error="error" />
        <checkbox-slider v-if="form.rotation === 'daily'" :name="'dsow'" :label="$t('labels.day_of_week.label')" :help="$t('labels.day_of_week.help')" v-model="form.days_of_week" :options="{0: getDay(0), 1: getDay(1), 2: getDay(2), 3: getDay(3), 4: getDay(4), 5: getDay(5), 6: getDay(6)}" />
        <radio-slider v-else-if="form.rotation !== 'non'" :name="'dow'" :label="$t('labels.day_of_week.label')" :help="$t('labels.day_of_week.help')" v-model="form.day_of_week" :options="[getDay(0), getDay(1), getDay(2), getDay(3), getDay(4), getDay(5), getDay(6)]" />
        <tuxedo-date v-if="form.rotation !== 'non'" :name="'start_at'" :label="$t('labels.booking_start.label')" v-model="form.start_at" :help="$t('labels.booking_start.help')" :insistFormat="'isoDate'" />
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { makeId, getDay } from '@/mixins/helpers'

export default {
  name: 'AdjustTaskGroupFrequencyForm',
  mixins: [makeId, getDay],
  computed: {
    formName () { return this.makeId() },
    rotations () {
      return {
        non: this.$t('labels.schedule_rotation.non'),
        daily: this.$t('labels.schedule_rotation.daily'),
        weekly: this.$t('labels.schedule_rotation.weekly'),
        monthly: this.$t('labels.schedule_rotation.monthly')
      }
    },
    weeklyFrequencies () {
      return [
        {
          id: 1,
          label: this.$t('labels.schedule_frequency.weekly_1')
        },
        {
          id: 2,
          label: this.$t('labels.schedule_frequency.weekly_2')
        },
        {
          id: 4,
          label: this.$t('labels.schedule_frequency.weekly_4')
        },
        {
          id: 6,
          label: this.$t('labels.schedule_frequency.weekly_6')
        },
        {
          id: 8,
          label: this.$t('labels.schedule_frequency.weekly_8')
        },
        {
          id: 12,
          label: this.$t('labels.schedule_frequency.weekly_12')
        },
        {
          id: 24,
          label: this.$t('labels.schedule_frequency.weekly_24')
        }
      ]
    },
    monthlyFrequencies () {
      return [
        {
          id: 1,
          label: this.$t('labels.schedule_frequency.monthly_1')
        },
        {
          id: 2,
          label: this.$t('labels.schedule_frequency.monthly_2')
        },
        {
          id: 3,
          label: this.$t('labels.schedule_frequency.monthly_3')
        },
        {
          id: 4,
          label: this.$t('labels.schedule_frequency.monthly_4')
        },
        {
          id: 6,
          label: this.$t('labels.schedule_frequency.monthly_6')
        },
        {
          id: 12,
          label: this.$t('labels.schedule_frequency.monthly_12')
        }
      ]
    },
    weeksOfMonth () {
      return [
        {
          id: 1,
          label: this.$t('labels.week_of_month.1')
        },
        {
          id: 2,
          label: this.$t('labels.week_of_month.2')
        },
        {
          id: 3,
          label: this.$t('labels.week_of_month.3')
        },
        {
          id: 4,
          label: this.$t('labels.week_of_month.4')
        }
      ]
    }
  },
  data () {
    return {
      form: {
        id: null,
        rotation: null,
        frequency: null,
        week_of_month: null,
        day_of_week: null,
        days_of_week: [],
        start_at: null
      },
      labels: {
        form: this.$t('labels.action_resource', { action: this.$t('labels.edit'), resource: this.$tc('resource.rotation', 1) })
      }
    }
  },
  methods: {
    show (t) {
      this.form.id = t.task_group_id
      const url = 'api/clients/' + t.client_id + '/task-groups/' + t.task_group_id
      this.$store.dispatch('show', { url, object: 'TaskGroup', id: t.task_group_id }).then(res => {
        this.form.rotation = res.data.data.rotation
        this.form.frequency = res.data.data.frequency
        this.form.week_of_month = res.data.data.week_of_month
        this.form.day_of_week = res.data.data.day_of_week
        this.form.days_of_week = res.data.data.days_of_week
        this.form.start_at = res.data.data.start_at
      })
      this.$modal.show(this.formName)
    },
    hide () {
      this.$modal.hide(this.formName)
    }
  }
}
</script>
