<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $tc('labels.contractor', 0) }}</h3>
    </route-header>
    <div class="row mb-4">
      <div class="col-12 px-0 px-md-3" v-if="authCan('users')">
        <away-events-calendar />
      </div>
    </div>
  </div>
</template>

<script>
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'ContractorAwayEvents',
  mixins: [authMethods]
}
</script>
