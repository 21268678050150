<template>
  <div class="table-responsive-sm">
    <table class="table table-sm table-bs mb-0"
           @dragenter.prevent="dragOn"
           @dragover.prevent @drop.stop.prevent="drop">
      <thead>
      <slot name="thead"></slot>
      </thead>
      <tbody v-if="!dragActive">
      <slot></slot>
      <tr v-for="blank in dataLength" :key="'b' + blank">
        <td :colspan="cols" class="blank">
          <div class="loading" v-if="loading"></div>
          <span v-else>&nbsp;</span>
        </td>
      </tr>
      </tbody>
      <tbody v-if="dropZone && dragActive" @dragleave.prevent="dragActive = false">
      <tr>
        <td class="text-center text-muted" :colspan="cols" style="height:150px;">
          Drop Files Here
        </td>
      </tr>
      </tbody>
      <slot name="tbody"></slot>
      <tfoot class="border-top bg-light">
      <tr>
        <td :colspan="cols">
          <slot name="tfoot"></slot>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>

</template>

<script>
export default {
  name: 'BSTable',
  props: ['dataLength', 'loading', 'cols', 'dropZone'],
  data () {
    return {
      dragActive: false
    }
  },
  methods: {
    dragOn () {
      if (this.dropZone) this.dragActive = true
    },
    drop (e) {
      this.dragActive = false
      this.$emit('drop', e.dataTransfer.files)
    }
  }
}
</script>

<style lang="scss">
table.table-bs {

  thead {

    th {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  th:first-child, td:first-child {
    padding-left: 0.5rem;
  }

  th:last-child, td:last-child {
    padding-right: 0.5rem;
  }

  tbody {
    font-size: 0.875rem;

    td {
      vertical-align: middle;

      &.blank {
        height: 35px;
      }

      .loading {
        width: 100%;
        height: 18px;
        animation: placeHolderShimmer 3s infinite;
      }
    }
  }
}

.t1, .t2, .t3, .t4 {
  text-align: right;
  min-width: 45px;

  .btn-spacer {
    margin-right: 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.t2 {
  min-width: 70px;
}

.t3 {
  min-width: 100px;
}

.t4 {
  min-width: 130px;
}

@keyframes placeHolderShimmer {
  0% {
    background: #ececec;
  }
  30% {
    background: #F7F7F7;
  }
  50% {
    background: #ececec;
  }
  80% {
    background: #F7F7F7;
  }
  100% {
    background: #ececec;
  }
}
</style>
