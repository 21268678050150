<template>
  <router-link :to="to" class="d-flex justify-content-between align-items-center w-100 px-3">
    <fa-icon :icon="['fal', icon]"></fa-icon>
    <span><slot></slot></span>
    <div class="position-absolute translate-middle badge rounded-pill bg-pink" style="left: 2px; top: 0">
      <slot name="count"></slot>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavItem',
  props: ['to', 'icon']
}
</script>
