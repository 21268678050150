<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.tasks') }}</h3>
      <div class="d-flex align-items-center">
        <bs-button class="btn-sm btn-warning mr-2" @click="showReportForm">{{ $t('labels.stock_report') }}</bs-button>
        <bs-button class="btn-sm btn-info" @click="$refs.tasks.create()">{{ $t('labels.create_task') }}</bs-button>
      </div>
    </route-header>
    <div class="row">
      <div class="col-12" v-if="authCan('manage_tasks')">
        <tasks ref="tasks" />
      </div>
    </div>
    <bs-modal :name="'stockReportForm'" :label="$t('labels.stock_report')">
      <bs-form :submit="$t('labels.export')" @submit="exportStockReport">
        <template>
          <tuxedo-date :label="$t('labels.month')" :name="'month'" :type="'month'" v-model="month" :insistFormat="'isoDate'" />
        </template>
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'GlobalTasks',
  mixins: [authMethods],
  data () {
    return {
      month: null
    }
  },
  methods: {
    showReportForm () {
      this.$modal.show('stockReportForm')
    },
    exportStockReport () {
      this.$store.dispatch('download', {
        path: 'stock-report',
        resource: { month: this.month },
        accept: 'application/pdf'
      })
    }
  }
}
</script>
