<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ resource.name }}</h3>
      <nav-secondary-client :client="client" />
    </route-header>
    <client-suspension />
    <div class="row">
      <div class="col-12">
        <invoices />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ClientInvoices',
  props: ['client'],
  data () {
    return {
      domain: null
    }
  },
  computed: {
    ...mapState({
      resource: state => state.client.data
    })
  },
  beforeMount () {
    this.pushFilter({ view: 'Invoices', data: { client: this.client } })
  },
  methods: {
    ...mapMutations([
      'pushFilter'
    ])
  }
}
</script>
