<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="3">
        <template v-slot:thead>
          <tr>
            <th scope="col">{{ $t('labels.name') }}</th>
            <th scope="col">{{ $t('labels.accounting_number') }}</th>
            <th scope="col"></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>
            <span class="d-flex">
              <span :class="'sm-swatch bg-' + r.color"></span>
              <span class="pl-2">{{ r.name }}</span>
            </span>
          </td>
          <td>{{ r.number }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="slotProps.error"></tuxedo-input>
          <tuxedo-input :name="'number'" :label="$t('labels.accounting_number')" v-model="resource.number" :error="slotProps.error"></tuxedo-input>
          <tuxedo-select :name="'color'" :label="$t('labels.color')" v-model="resource.color" :options="colors" :insistReturn="'color'" :error="slotProps.error" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'ExpenseCategories',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'envelope-open-dollar'],
      resource: {
        name: '',
        number: '',
        color: ''
      },
      colors: [
        { color: 'blue', label: 'Blue' },
        { color: 'indigo', label: 'Indigo' },
        { color: 'purple', label: 'Purple' },
        { color: 'pink', label: 'Pink' },
        { color: 'red', label: 'Red' },
        { color: 'light-red', label: 'Light Red' },
        { color: 'orange', label: 'Orange' },
        { color: 'light-orange', label: 'Light Orange' },
        { color: 'yellow', label: 'Yellow' },
        { color: 'light-yellow', label: 'Light Yellow' },
        { color: 'green', label: 'Green' },
        { color: 'teal', label: 'Teal' },
        { color: 'cyan', label: 'Cyan' }
      ]
    }
  },
  computed: {
    ...mapState({
      resources: state => state.expenseCategories,
      resourceState: state => state.expenseCategoriesState,
      resourceStore: state => state.expenseCategoriesStore
    })
  }
}
</script>
