import { Sec } from '../mixins/sec'

export const financialShortcutDates = {
  data () {
    return {
      shortcuts: [
        {
          text: this.$t('labels.date_selection.last_month'),
          onClick () {
            const s = new Sec().startOf('month').subtract(1, 'month').JSDate()
            const e = new Sec().startOf('month').subtract(1, 'month').endOf('month').JSDate()
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_30'),
          onClick () {
            const s = new Sec().subtract(30, 'days').JSDate()
            const e = new Sec().JSDate()
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_quarter'),
          onClick () {
            const s = new Sec().startOf('quarter').subtract(1, 'quarter').JSDate()
            const e = new Sec().startOf('quarter').subtract(1, 'quarter').endOf('quarter').JSDate()
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_90'),
          onClick () {
            const s = new Sec().subtract(90, 'days').JSDate()
            const e = new Sec().JSDate()
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_financial_year'),
          onClick () {
            const now = new Date()
            let s = new Sec().startOf('year').subtract(1, 'year').subtract(2, 'quarters').JSDate()
            let e = new Sec().startOf('year').subtract(1, 'year').add(1, 'quarter').endOf('quarter').JSDate()
            if (now.getMonth() >= 6) {
              s = new Sec().startOf('year').subtract(2, 'quarters').JSDate()
              e = new Sec().startOf('year').add(1, 'quarter').endOf('quarter').JSDate()
            }
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_year'),
          onClick () {
            const s = new Sec().startOf('year').subtract(1, 'year').JSDate()
            const e = new Sec().startOf('year').subtract(1, 'year').endOf('year').JSDate()
            return [s, e]
          }
        },
        {
          text: this.$t('labels.date_selection.last_365'),
          onClick () {
            const s = new Sec().subtract(365, 'days').JSDate()
            const e = new Sec().JSDate()
            return [s, e]
          }
        }
      ]
    }
  }
}
