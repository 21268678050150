<template>
  <div id="nav" class="h-100 bg-secondary position-relative" :class="headerClass">
    <div id="nav-logo" @click="navCollapsed = !navCollapsed" class="bg-primary">
      <img src="/img/logo.png" />
    </div>
    <div class="d-flex flex-column align-items-end pt-2 pb-4">
      <nav-item :to="'/'" :icon="'tachometer-alt-slow'">
        {{ $t('labels.nav.dashboard') }}
      </nav-item>
      <nav-item :to="'/my-jobs'" :icon="'calendar-alt'">
        {{ $t('labels.nav.my_jobs') }}
      </nav-item>
      <nav-item :to="'/my-earnings'" :icon="'hands-usd'">
        {{ $t('labels.nav.my_earnings') }}
      </nav-item>
      <nav-item v-if="authCan('manage_accounts')" :to="'/invoices'" :icon="'books'">
        {{ $t('labels.nav.invoices') }}
        <template v-if="ping.openInvoices" v-slot:count>
          {{ ping.openInvoices }}
        </template>
      </nav-item>
      <nav-item v-if="authCan('manage_expenses')" :to="'/expenses'" :icon="'envelope-open-dollar'">
        {{ $tc('labels.expense', 0) }}
      </nav-item>
      <nav-item v-if="authCan('manage_transactions')" :to="'/transactions'" :icon="'exchange'">
        {{ $t('labels.nav.transactions') }}
        <template v-if="ping.transactionsToMatch" v-slot:count>
          {{ ping.transactionsToMatch }}
        </template>
      </nav-item>
      <nav-item v-if="authCan('manage_accounts')" :to="'/activity'" :icon="'chart-pie'">
        {{ $t('labels.nav.account_activity') }}
      </nav-item>
      <nav-item v-if="authCan('manage_clients')" :to="'/clients'" :icon="'address-book'">
        {{ $t('labels.nav.clients') }}
      </nav-item>
      <nav-item v-if="authCan('manage_tasks')" :to="'/tasks'" :icon="'tasks'">
        {{ $t('labels.nav.tasks') }}
      </nav-item>
      <nav-item :to="'/documents'" :icon="'cabinet-filing'">
        {{ $t('labels.nav.documents') }}
      </nav-item>
      <nav-item v-if="authCan('users')" :to="'/away-events'" :icon="'hiking'">
        {{ $tc('labels.away_event', 0) }}
      </nav-item>
      <nav-item v-if="authCan('settings')" :to="'/settings'" :icon="'cogs'">
        {{ $t('labels.nav.settings') }}
      </nav-item>
      <nav-item :to="'/my-profile'" :icon="'user'">
        {{ $t('labels.nav.my_profile') }}
      </nav-item>
    </div>
    <div class="position-absolute nav-chevron text-light clickable" @click="navCollapsed = !navCollapsed">
      <fa-icon :icon="['fal', 'chevron-double-left']" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authMethods } from '../../mixins/authMethods'

export default {
  mixins: [authMethods],
  data () {
    return {
      navHidden: true,
      navCollapsed: true
    }
  },
  computed: {
    ...mapState({
      ping: state => state.pingState.data
    }),
    headerClass () {
      let cl = ''
      if (this.navHidden) cl += 'hidden '
      if (this.navCollapsed) cl += 'collapsed '
      return cl
    }
  },
  watch: {
    $route (to, from) {
      if (window.outerWidth < 768) {
        this.navCollapsed = true
      }
    }
  }

}
</script>

<style lang="scss">

#nav {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 240px;
  transition: 0.2s ease-out width;
  padding-top: 50px;

  #nav-logo {
    position: absolute;
    width: 240px;
    top: 0;
    height: 50px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s ease-out width;

    img {
      transition: 0.2s ease-out right;
      position: absolute;
      height: 40px;
      top: 5px;
      right: 0;
    }
  }

  a {
    height: 40px;
    white-space: nowrap;
  }

  .nav-chevron {
    bottom: 0;
    right: 0;
    line-height: 50px;
    width: 50px;
    text-align: center;
  }

  &.collapsed {
    width: 0;

    #nav-logo {
      width: 40px;
    }

    a span {
      display: none;
    }

    .nav-chevron {
      transform: rotate(180deg);
    }
  }
}

@media(min-width: 768px) {
  #nav {
    position: relative;
    padding-top: 0;

    #nav-logo {
      position: relative;
      left: auto;
      overflow: visible;
    }

    &.collapsed {
      width: 50px;

      #nav-logo {
        width: 50px;
      }

    }
  }
}
</style>
