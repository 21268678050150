<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ $t('labels.nav.dashboard') }}</h3>
        </route-header>
        <user-monthly-progress />
        <activity-log class="mb-4" v-if="authCan('activity_log')" />
        <route-header v-if="incomplete && incomplete.length > 0">
            <h3>{{ $t('labels.nav.incomplete_tasks') }}</h3>
        </route-header>
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4 mb-3" v-for="visit in incomplete" :key="'i' + visit.id">
                <task-group-card :visit="visit" :collection="'userTasksIncomplete'" @refresh="index" />
            </div>
        </div>
        <route-header v-if="schedule && schedule.length > 0">
            <h3>{{ $t('labels.nav.todays_tasks') }}</h3>
        </route-header>
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4 mb-3" v-for="visit in orderedSchedule" :key="'v' + visit.id">
                <task-group-card :visit="visit" :collection="'userTasksToday'" @refresh="index" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'Dashboard',
  mixins: [authMethods],
  mounted () {
    this.index()
    this.indexTasksIncomplete()
    // this.indexContractorProgress()
  },
  computed: {
    ...mapState({
      schedule: state => state.userTasksToday.data,
      incomplete: state => state.userTasksIncomplete.data,
      contractors: state => state.contractorMonthlyProgress.data
    }),
    orderedSchedule () {
      return this._.orderBy(this.schedule, s => s.address ? s.address.city : null)
    }
  },
  created () {
    this.$store.dispatch('showUser')
  },
  methods: {
    index () {
      this.loading = true
      this.$store.dispatch('index', {
        collection: 'userTasksToday'
      }).then(() => {
        this.loading = false
      })
    },
    indexTasksIncomplete () {
      this.$store.dispatch('index', { collection: 'userTasksIncomplete' })
    },
    indexContractorProgress () {
      this.$store.dispatch('index', { collection: 'contractorMonthlyProgress' })
    }
  }
}
</script>
