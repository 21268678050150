<template>

    <div class="form-group bs-form-group">
        <label v-if="label" :for="name" class="text-uppercase mb-0">{{ label }}</label>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
        <div class="radio-slider mb-5">
            <ul class="d-flex justify-content-around my-3">
                <li class="note-slider" v-for="(option, key) in options" :key="key">
                    <span class="connector"></span>
                    <input type="radio" :id="'r'+key+'-'+name" :name="name" :value="key" @click="updateValue($event.target.value)" :checked="(value == key || (!value && key == 0))">
                    <span class="checked"></span>
                    <label :for="'r'+key+'-'+name"></label>
                    <span class="radio-label"><small class="font-weight-bold">{{ option }}</small></span>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>

export default {
  name: 'RadioSlider',
  props: ['label', 'name', 'help', 'value', 'options', 'error'],
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
    .radio-slider {
        height: 40px;
        margin-left: -16px;
        margin-right: -16px;

        ul {
            list-style: none;
            padding: 0;
            height: 40px;

            li {
                float: left;
                text-align: center;
                height: 40px;
                width: 100%;
                position: relative;
                margin: 0;

                .connector {
                    display: block;
                    position: relative;
                    height: 1px;
                    width: 100%;
                    background: #b7b7b7;
                    top: 20px;
                }

                .checked {
                    display: block;
                    width: 0;
                    height: 0;
                    border-radius: 26px;
                    background: rgba(53, 152, 220, 0.4);
                    left: 0;
                    right: 0;
                    top: 20px;
                    margin: 0 auto;
                    position: absolute;
                    transition: 0.2s all linear;
                }

                label {
                    position: absolute;
                    display: block;
                    width: 24px;
                    height: 24px;
                    border-radius: 20px;
                    background: rgba(0, 0, 0, 0.1);
                    left: 0;
                    right: 0;
                    top: 8px;
                    margin: 0 auto;
                    transition: 0.2s all linear;
                    cursor: pointer;
                }

                input {
                    display: none;

                    &:checked {

                        + .checked {
                            height: 26px;
                            width: 26px;
                            top: 7px;

                            + label {
                                width: 16px;
                                height: 16px;
                                top: 12px;
                                background: #3598DC;
                            }
                        }
                    }
                }

                .radio-label {
                    position: relative;
                    top: 34px;
                }
            }
        }
    }

</style>
