<template>
    <span class="badge badge-pill" @click="$emit('click')">
        <slot></slot>
    </span>
</template>

<script>
export default {
  name: 'BSBadge'
}
</script>

<style lang="scss">
    .badge-pill {
        padding: 0.35rem 0.375rem;
        border-radius: 0.25rem;
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
    }
</style>
