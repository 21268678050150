<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ resource.name }}</h3>
            <nav-secondary-client :client="client" />
        </route-header>
        <client-suspension />
        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <task-groups :type="'Clients'" :id="client" />
            </div>
            <div class="col-12 col-md-6">
                <notes :type="'Clients'" :id="client" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../mixins/resourceControl'

export default {
  name: 'ClientTasks',
  mixins: [resourceControl],
  props: ['client'],
  data () {
    return {
      domain: null
    }
  },
  computed: {
    ...mapState({
      resource: state => state.client.data
    })
  },
  mounted () {
    this.index()
  },
  methods: {
    index (page, count, filters) {
      this.handleState(page, count, filters)
      this.loading = true
      this.$store.dispatch('index', {
        path: 'Clients',
        id: this.$route.params.client,
        tag: 'addresses',
        collection: 'Addresses'
      }).then(() => {
        this.loading = false
      })
    }
  }
}
</script>
