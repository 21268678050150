<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.my_earnings') }}</h3>
      <div v-if="mobileView">
        <tuxedo-date class="mb-1" :name="'month'" v-model="resourceState.month" :format="'MMM YYYY'" :shortcuts="[]" :type="'month'" />
        <tuxedo-select v-if="authCan('users')" :name="'user'" v-model="resourceState.selected" :options="users" :insistLabel="'name'" :multi="true" class="mb-1" />
      </div>
      <div v-else class="d-flex">
        <tuxedo-date class="mb-0" :name="'month'" v-model="resourceState.month" :format="'MMM YYYY'" :shortcuts="[]" :type="'month'" />
        <tuxedo-select v-if="authCan('users')" :name="'user'" v-model="resourceState.selected" :options="users" :insistLabel="'name'" :multi="true" class="ml-3 mb-0" />
      </div>
    </route-header>
    <div class="row">
      <div class="col-12">
        <contractor-earnings class="mb-3" v-for="u in resourceState.selected" :key="'su' + u.id" :user="u" :month="resourceState.month" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authMethods } from '../mixins/authMethods'
import { watchWindowSize } from '@/mixins/helpers'

export default {
  name: 'MyEarnings',
  mixins: [watchWindowSize, authMethods],
  data () {
    return {
    }
  },
  created () {
    if ((this.resourceState && this.resourceState.selected.length === 0) || !this.authCan('users')) {
      this.resourceState.selected = [this.auth]
    }
  },
  watch: {
    month: function (val) {
      this.$store.commit('pushState', { view: 'userSchedules', key: 'dateFrom', data: val })
    },
    user: function (val) {
      this.$store.commit('pushState', { view: 'userSchedules', key: 'user', data: val })
    }
  },
  computed: {
    ...mapState({
      users: state => state.usersStore,
      resourceState: state => state.myEarningsState
    })
  }
}
</script>
