<template>
  <bs-modal :name="formName" :label="labels.form" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.pause')" @submit="$emit('submit', form)">
      <template v-slot:default="{ error }">
        <tuxedo-date :name="'pause_to'" :label="$t('labels.pause_to_date.label')" v-model="form.pause_to"
                     :help="$t('labels.pause_to_date.help', { resource: $tc('resource.task', 1) })"
                     :insistFormat="'isoDate'" :error="error" />
        <text-field :name="'pause_reason'" :label="$t('labels.pause_reason')" v-model="form.pause_reason" :error="error" />
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { makeId } from '@/mixins/helpers'

export default {
  name: 'PauseTaskForm',
  mixins: [makeId],
  computed: {
    formName () { return this.makeId() }
  },
  data () {
    return {
      form: {
        id: null,
        paused: true,
        pause_to: null,
        pause_reason: null
      },
      labels: {
        form: this.$t('labels.action_resource', { action: this.$t('labels.pause'), resource: this.$tc('resource.task', 1) })
      }
    }
  },
  methods: {
    show (t) {
      this.form.id = t.id
      this.$modal.show(this.formName)
    },
    hide () {
      this.$modal.hide(this.formName)
    }
  }
}
</script>
