export default {
  en: {
    permits: {
      settings: 'Manage Settings',
      users: 'Manage Users',
      roles: 'Manage Roles',
      vendors: 'Manage Vendors',
      expense_categories: 'Manage Expense Categories',
      view_clients: 'View Clients',
      manage_clients: 'Manage Clients',
      suspend_clients: 'Suspend Clients',
      manage_accounts: 'Manage Accounts',
      suburbs: 'Manage Suburbs',
      manage_notes: 'Manage Notes',
      manage_tasks: 'Manage Tasks',
      manage_invoices: 'Manage Invoices',
      manage_expenses: 'Manage Expenses',
      manage_transactions: 'Manage Transactions',
      manage_documents: 'Manage Documents',
      task_price: 'See Task Price',
      activity_log: 'View Activity Log',
      manage_schedules: 'Manage Schedules',
      reschedule: 'Reschedule Bookings',
      reschedule_all: 'Reschedule Task Groups'
    },
    pagination: {
      previous: '&laquo; Previous',
      next: 'Next &raquo;'
    },
    tip: {
      vendor_contractor: 'If this vendor is also a contractor, select the contractor from the list to link the two.',
      vendor_invoice_tax: 'Enter the included tax amount for this invoice.',
      pause_task: 'Pause this task from needing to be done for a period of time.',
      resume_task: 'Resume this task from today onwards, removing any pause effects.'
    },
    passwords: {
      reset: 'Your password has been reset!',
      sent: 'We have emailed your password reset link!',
      throttled: 'Please wait before retrying.',
      token: 'This password reset token is invalid.',
      user: "We can't find a user with that email address."
    },
    formats: {
      percent: '{amount}%',
      price: '{dollars}.{cents}'
    },
    auth: {
      failed: 'These credentials do not match our records.',
      throttle: 'Too many login attempts. Please try again in {seconds} seconds.'
    },
    messages: {
      initial_load: "We're preparing a few things for you... In the meantime, enjoy the scenery",
      simple_loading: 'Loading {resource}',
      loading: 'Loading {count} {resource} from page {page}',
      loading_with_year: 'Loading {resource} from {year}',
      loading_with_month: 'Loading {resource} from {month}',
      uploading_image: 'Uploading Image',
      uploading_files: 'Uploading Files',
      password_not_required: 'Only include a password here if you want to set or change it.',
      delete: 'Are you sure you want to delete this {resource}?',
      disconnect: 'Are you sure you want to disconnect this {resource}?',
      send: 'Are you sure you want to send this {resource}?',
      confirm_paid: 'Are you sure you want to mark Invoice {invoice} for {amount} as Paid?',
      last_updated: 'Last Updated: {time}',
      daily_rotation: 'Every ',
      weekly_1_rotation: 'Each week on {day}',
      weekly_2_rotation: 'Each fortnight on {day}',
      weekly_4_rotation: 'Every four weeks on {day}',
      weekly_6_rotation: 'Every six weeks on {day}',
      weekly_8_rotation: 'Every eight weeks on {day}',
      weekly_12_rotation: 'Every twelve weeks on {day}',
      weekly_24_rotation: 'Every twenty four weeks on {day}',
      monthly_1_rotation: 'Every month on {day} of the {week} week',
      monthly_2_rotation: 'Every two months on {day} of the {week} week',
      monthly_3_rotation: 'Every three months on {day} of the {week} week',
      monthly_4_rotation: 'Every four months on {day} of the {week} week',
      monthly_6_rotation: 'Every six months on {day} of the {week} week',
      monthly_12_rotation: 'Every twelve months on {day} of the {week} week',
      non_rotation: 'No Rotation',
      consumable: 'This is a consumable item. You will need to indicate the quantity supplied to the customer before it can be completed.',
      skip_visit: 'Please skip this visit as it is not required on this run.',
      visit_inactive: 'Please skip this visit as this client is currently inactive.',
      collect_cash: 'This client pays with cash, please print and provide them their invoice and collect payment on your run.',
      difference_amount: 'There is a difference in the amounts being matched.',
      send_reminder: 'This will collate all overdue and unpaid invoices along with an account statement and email to the client with a reminder to pay, do you wish to continue?',
      send_notice: 'This will collate all overdue and unpaid invoices along with an account statement and email to the client with a notice to pay, do you wish to continue?',
      send_warning: "This will collate all overdue and unpaid invoices along with an account statement and email to the client with a warning of suspension if they don't pay soon, do you wish to continue?",
      suspend_service: 'This will suspend this clients cleaning service and send them an email notifying them of the suspension. Any contractors who clean for the client will also be notified of the suspension. Do you wish to continue?',
      unsuspend_service: 'This will unsuspend this clients cleaning service and send both them and any relevant contractors an email notifying them of the reactivation. Do you wish to continue?',
      service_suspended: 'Service suspended on {date} due to non-payment.',
      service_reactivated: 'Service reactivated on {date}.',
      pdf_only: 'PDF files only',
      play_task: 'Are you sure you would like to resume this task in future bookings?',
      invoice: {
        remittance: 'Please send remittance to:',
        bsb: '064 203',
        account: '10651045',
        contact: {
          name: 'Ross Whiffen',
          number: '0421 659 862',
          email: 'accounts@arcommercialcleaning.com'
        },
        enquiries: {
          text: 'Account enquires please call: ',
          contact: '0421 659 862'
        }
      },
      ai: {
        assistant: 'You are a book keeper. You will be asked to examine and summarise invoice and receipt files.',
        document_in_image: 'In a single word, either Yes or No, tell me if this image file ({id}) contains a picture of a document.',
        document_type: 'Tell me if the the file {id} is {types} or Other. If you determine the file is "Other", then explain the content of the file, otherwise only use a single word from the provided terms without further explanation.',
        double_check_doc_type: "Are you absolutely sure you've identified the file with the ID {id} as the correct document type, we expect it to be one of these: {types} . If you're certain it's not one of those please explain why, otherwise only use a single word from the provided terms without further explanation.",
        summarise: 'Summarise the contents of the file with ID: {id} as a json response, find the most appropriate data in the file that corresponds to these specific keys: {keys} .',
        format: 'Response format instructions: If a key cannot be found use "NA" in the field. The response must be a single JSON object. There will be no text or other characters outside the JSON string.',
        double_check: "You've answered \"NA\" for these keys: {keys}. Please double check the file with ID: {id} to find the most appropriate data that corresponds to these specific keys: {keys} .",
        image: 'Summarise the contents of this image as a json response with at least these specific keys: {keys}'
      }
    },
    datetime: {
      default: '{date} {month} {year}',
      dateSuffix: [
        '',
        'st',
        'nd',
        'rd',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'st',
        'nd',
        'rd',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'th',
        'st'
      ],
      distance: {
        year: '{years} year ago|{years} years ago',
        month: '{months} month ago|{months} months ago',
        day: '{days} day ago|{days} days ago',
        hour: '{hours} hour ago|{hours} hours ago',
        minute: '{minutes} minute ago|{minutes} minutes ago',
        second: '{seconds} second ago|{seconds} seconds ago'
      }
    },
    subject: {
      account_activity: 'Account Activity {from} - {to}',
      account_statement: 'Account Statement - {name}',
      invoice: 'Invoice {number} - {name}',
      invoices: 'Invoices - {name}',
      outstanding: 'Overdue Account - {name}',
      notice_to_pay: 'Please Reconcile Account - {name}',
      suspension_warning: 'Please Pay Account - {name}',
      service_suspended: 'Service Suspended - {name}',
      service_reactivated: 'Service Reactivated - {name}',
      service_suspended_contractor: 'Important Notice - {name}',
      insurance_expiration: 'Insurance Expiry Notice',
      schedule_report: 'Job Report - {name}'
    },
    resource: {
      client: 'Client|Clients',
      contractor: 'Contractor|Contractors',
      credit: 'Credit|Credits',
      job_card: 'Job Card|Job Cards',
      rotation: 'Rotation|Rotations',
      task: 'Task|Tasks'
    },
    validation: {
      accepted: 'The {attribute} must be accepted.',
      active_url: 'The {attribute} is not a valid URL.',
      after: 'The {attribute} must be a date after {date}.',
      after_or_equal: 'The {attribute} must be a date after or equal to {date}.',
      alpha: 'The {attribute} may only contain letters.',
      alpha_dash: 'The {attribute} may only contain letters, numbers, dashes and underscores.',
      alpha_num: 'The {attribute} may only contain letters and numbers.',
      array: 'The {attribute} must be an array.',
      before: 'The {attribute} must be a date before {date}.',
      before_or_equal: 'The {attribute} must be a date before or equal to {date}.',
      between: {
        numeric: 'The {attribute} must be between {min} and {max}.',
        file: 'The {attribute} must be between {min} and {max} kilobytes.',
        string: 'The {attribute} must be between {min} and {max} characters.',
        array: 'The {attribute} must have between {min} and {max} items.'
      },
      boolean: 'The {attribute} field must be true or false.',
      confirmed: 'The {attribute} confirmation does not match.',
      date: 'The {attribute} is not a valid date.',
      date_equals: 'The {attribute} must be a date equal to {date}.',
      date_format: 'The {attribute} does not match the format {format}.',
      different: 'The {attribute} and {other} must be different.',
      digits: 'The {attribute} must be {digits} digits.',
      digits_between: 'The {attribute} must be between {min} and {max} digits.',
      dimensions: 'The {attribute} has invalid image dimensions.',
      distinct: 'The {attribute} field has a duplicate value.',
      email: 'The {attribute} must be a valid email address.',
      ends_with: 'The {attribute} must end with one of the following: {values}.',
      exists: 'The selected {attribute} is invalid.',
      file: 'The {attribute} must be a file.',
      filled: 'The {attribute} field must have a value.',
      gt: {
        numeric: 'The {attribute} must be greater than {value}.',
        file: 'The {attribute} must be greater than {value} kilobytes.',
        string: 'The {attribute} must be greater than {value} characters.',
        array: 'The {attribute} must have more than {value} items.'
      },
      gte: {
        numeric: 'The {attribute} must be greater than or equal {value}.',
        file: 'The {attribute} must be greater than or equal {value} kilobytes.',
        string: 'The {attribute} must be greater than or equal {value} characters.',
        array: 'The {attribute} must have {value} items or more.'
      },
      image: 'The {attribute} must be an image.',
      in: 'The selected {attribute} is invalid.',
      in_array: 'The {attribute} field does not exist in {other}.',
      integer: 'The {attribute} must be an integer.',
      ip: 'The {attribute} must be a valid IP address.',
      ipv4: 'The {attribute} must be a valid IPv4 address.',
      ipv6: 'The {attribute} must be a valid IPv6 address.',
      json: 'The {attribute} must be a valid JSON string.',
      lt: {
        numeric: 'The {attribute} must be less than {value}.',
        file: 'The {attribute} must be less than {value} kilobytes.',
        string: 'The {attribute} must be less than {value} characters.',
        array: 'The {attribute} must have less than {value} items.'
      },
      lte: {
        numeric: 'The {attribute} must be less than or equal {value}.',
        file: 'The {attribute} must be less than or equal {value} kilobytes.',
        string: 'The {attribute} must be less than or equal {value} characters.',
        array: 'The {attribute} must not have more than {value} items.'
      },
      max: {
        numeric: 'The {attribute} may not be greater than {max}.',
        file: 'The {attribute} may not be greater than {max} kilobytes.',
        string: 'The {attribute} may not be greater than {max} characters.',
        array: 'The {attribute} may not have more than {max} items.'
      },
      mimes: 'The {attribute} must be a file of type: {values}.',
      mimetypes: 'The {attribute} must be a file of type: {values}.',
      min: {
        numeric: 'The {attribute} must be at least {min}.',
        file: 'The {attribute} must be at least {min} kilobytes.',
        string: 'The {attribute} must be at least {min} characters.',
        array: 'The {attribute} must have at least {min} items.'
      },
      not_in: 'The selected {attribute} is invalid.',
      not_regex: 'The {attribute} format is invalid.',
      numeric: 'The {attribute} must be a number.',
      password: 'The password is incorrect.',
      present: 'The {attribute} field must be present.',
      regex: 'The {attribute} format is invalid.',
      required: 'The {attribute} field is required.',
      required_if: 'The {attribute} field is required when {other} is {value}.',
      required_unless: 'The {attribute} field is required unless {other} is in {values}.',
      required_with: 'The {attribute} field is required when {values} is present.',
      required_with_all: 'The {attribute} field is required when {values} are present.',
      required_without: 'The {attribute} field is required when {values} is not present.',
      required_without_all: 'The {attribute} field is required when none of {values} are present.',
      same: 'The {attribute} and {other} must match.',
      size: {
        numeric: 'The {attribute} must be {size}.',
        file: 'The {attribute} must be {size} kilobytes.',
        string: 'The {attribute} must be {size} characters.',
        array: 'The {attribute} must contain {size} items.'
      },
      starts_with: 'The {attribute} must start with one of the following: {values}.',
      string: 'The {attribute} must be a string.',
      timezone: 'The {attribute} must be a valid zone.',
      unique: 'The {attribute} has already been taken.',
      uploaded: 'The {attribute} failed to upload.',
      url: 'The {attribute} format is invalid.',
      uuid: 'The {attribute} must be a valid UUID.',
      custom: {
        'attribute-name': {
          'rule-name': 'custom-message'
        }
      },
      attributes: []
    },
    labels: {
      nav: {
        dashboard: 'Dashboard',
        my_profile: 'My Profile',
        my_jobs: 'My Jobs',
        my_earnings: 'My Earnings',
        accounts: 'Accounts',
        invoices: 'Invoices',
        transactions: 'Transactions',
        clients: 'Clients',
        tasks: 'Tasks',
        settings: 'Settings',
        general: 'General',
        todays_tasks: "Today's Tasks",
        incomplete_tasks: 'Incomplete Tasks',
        contractor_progress: 'Contractor Progress this Month',
        invoice: 'Invoice #{no}',
        documents: 'Documents',
        account_activity: 'Account Activity'
      },
      all_resource: 'All {resource}',
      action_resource: '{action} {resource}',
      view: 'View',
      create: 'Create',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      assume: 'Assume',
      connect: 'Connect',
      disconnect: 'Disconnect',
      export: 'Export',
      select: 'Select',
      send: 'Send',
      suspend: 'Suspend',
      confirm: 'Confirm',
      assign: 'Assign',
      generate: 'Generate',
      upload: 'Upload',
      download: 'Download',
      unsuspend: 'Unsuspend',
      reschedule: 'Reschedule',
      unskip: 'Unskip',
      display: 'Display',
      credit: 'Credit',
      pause: 'Pause',
      resume: 'Resume',
      invoice: 'Invoice|Invoices',
      expense: 'Expense|Expenses',
      expense_document: 'Expense Document|Expense Documents',
      transaction: 'Transaction|Transactions',
      contact: 'Contact|Contacts',
      account: 'Account|Accounts',
      address: 'Address|Addresses',
      contractor: 'Contractor|Contractors',
      contractor_earning: 'Contractor Earning|Contractor Earnings',
      user_schedule: 'Schedule|Schedules',
      vendor: 'Vendor|Vendors',
      expense_category: 'Expense Category|Expense Categories',
      away_event: 'Away Event|Away Events',
      conflicting_schedule: 'Conflicting Schedule|Conflicting Schedules',
      user_private_document: 'My Document|My Documents',
      document: 'Document|Documents',
      account_activity: 'Account Activity|Account Activity',
      profit: 'Profit|Profits',
      loss: 'Loss|Losses',
      client_consumable: 'Client Consumable|Client Consumables',
      profit_loss: 'Profit/Loss',
      today: 'Today',
      sending: 'Sending',
      sent: 'Sent',
      seen: 'Seen',
      partial: 'Partially Paid',
      overdue: 'Overdue',
      reminded: 'Reminded',
      notice: 'Notice Sent',
      warning: 'Suspension Warning',
      suspended: 'Service Suspended',
      invoiced: 'Invoiced',
      mark_paid: 'Mark Invoice as Paid',
      next: 'Next',
      id: 'ID',
      open: 'Open',
      clear: 'Clear',
      sign_in: 'Sign In',
      sign_out: 'Sign Out',
      forgot_password: 'Forgot Password',
      create_account: 'Create Account',
      role: 'Role',
      email: 'Email',
      text: 'Text',
      update: 'Update',
      profile_picture: 'Profile Picture',
      processed: 'Processed',
      attempted: 'Attempted',
      reconciled: 'Reconciled',
      documented: 'Documented',
      failed: 'Failed',
      processing: 'Processing',
      gst_registered: 'GST Registered',
      name: 'Name',
      password: 'Password',
      submit: 'Submit',
      choose_image: 'Choose Image',
      choose_file: 'Choose File',
      browse: 'Browse',
      update_user: 'Update User',
      roles: 'Roles',
      create_role: 'Create Role',
      users: 'Users',
      create_user: 'Create User',
      create_client: 'Create Client',
      active: 'Active',
      client: 'Client',
      suburbs: 'Suburbs',
      suburb: 'Suburb',
      create_suburb: 'Create Suburb',
      state: 'State',
      destroy_suburb: 'Delete Suburb',
      destroy: 'Delete',
      create_address: 'Edit Address',
      phone: 'Phone Number',
      postcode: 'Postcode',
      notes: 'Notes',
      number: 'Number',
      create_note: 'Create Note',
      destroy_note: 'Delete Note',
      trading_name: 'Trading Name',
      tasks: 'Tasks',
      create_task: 'Create Task',
      add_task: 'Add Task',
      task: 'Task',
      task_groups: 'Task Groups',
      create_task_group: 'Create Task Group',
      destroy_task: 'Delete Task',
      price: 'Price',
      percentage: 'Percentage',
      fixed: 'Fixed',
      description: 'Description',
      contractor_percentage: 'Contractor Percentage',
      contractor_monthly_progress: 'Contractor Progress',
      total: 'Total',
      contractor_receives: 'Each Contractor Gets',
      contractor_amount: 'Contractor Amount',
      all_contractors: 'All Contractors',
      save: 'Save',
      settings: 'Settings',
      overview: 'Overview',
      due_at: 'Booking Date',
      task_group: 'Task Group',
      client_schedules: 'Scheduled Bookings',
      create_client_schedule: 'Create Booking',
      destroy_client_schedule: 'Delete Booking',
      bookings: 'Bookings',
      regenerate_client_schedule: 'Regenerate Schedule',
      complete: 'Complete',
      pending: 'Pending',
      inactive: 'Inactive',
      worth: 'Worth',
      add_note: 'Add Note',
      edit_time: 'Edit Time',
      time_of_day: 'Time of Day',
      add_photo: 'Add Photo',
      destroy_image: 'Delete Photo',
      enter_quantity: 'Enter Quantity',
      quantity: 'Quantity',
      drag_image: 'Drag Image onto Box',
      drop_image: 'Drop Image(s) Here',
      browse_image: 'Browse/Take Photo',
      primary_image: 'Primary',
      make_primary_image: 'Make Primary',
      photos_notes: 'Photos & Notes',
      report: 'Report',
      skipped: 'Skipped',
      tasks_complete: 'Tasks Complete',
      earnings: 'Earnings',
      date: 'Date',
      activity_log: 'Activity Log',
      action: 'Action',
      swap_contractor: 'Swap Contractor',
      type: 'Type',
      invoice_description: {
        label: 'Invoice Description',
        help: 'If supplied, this description will appear on any invoice line in place of the task name.'
      },
      payment_method: 'Payment Method',
      payment_terms: {
        label: 'Payment Terms',
        help: 'Enter the number of days from an invoice being issued to when this client should pay.'
      },
      term_payment: 'Term Payment',
      cash_payment: 'Cash Payment',
      invoice_schedule: {
        label: 'Invoice Schedule',
        help: 'Choose the frequency at which this client should be invoiced.'
      },
      month: 'Monthly',
      fortnight: 'Fortnightly',
      week: 'Weekly',
      invoice_start: {
        label: 'Invoice Start',
        help: 'Choose the date at which the invoice schedule will begin, any rotations will be based from this date.'
      },
      booking_start: {
        label: 'Booking Start',
        help: 'Set a future date which will be used as a base for bookings to be generated, if this date is in the past it will be ignored.'
      },
      default_description: {
        label: 'Description',
        help: 'The default description to be used.'
      },
      contractor_description: {
        label: 'Description',
        help: 'The task description the contractor will see when doing this task.'
      },
      default_price: {
        label: 'Price',
        help: 'The default price to be used.'
      },
      task_group_price: {
        label: 'Price',
        help: 'The price this client will be charged for this task. It will be automatically added to the generated invoice.'
      },
      schedule_rotation: {
        label: 'Schedule',
        help: 'Select the rotation you wish to create this schedule on.',
        non: 'No Rotation',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly'
      },
      schedule_frequency: {
        label: 'Frequency',
        help: 'Select how frequently you want this task group scheduled.',
        weekly_1: 'Every Week',
        weekly_2: 'Every Two Weeks',
        weekly_4: 'Every Four Weeks',
        weekly_6: 'Every Six Weeks',
        weekly_8: 'Every Eight Weeks',
        weekly_12: 'Every 12 Weeks',
        weekly_24: 'Every Twenty Four Weeks',
        monthly_1: 'Every Month',
        monthly_2: 'Every Two Months',
        monthly_3: 'Every Three Months',
        monthly_4: 'Every Four Months',
        monthly_6: 'Every Six Months',
        monthly_12: 'Every Twelve Months'
      },
      week_of_month: {
        label: 'Week of Month',
        help: 'Select the week you want this task group to be scheduled on each month.',
        1: 'First Week',
        2: 'Second Week',
        3: 'Third Week',
        4: 'Last Week',
        ordinal: {
          1: 'first',
          2: 'second',
          3: 'third',
          4: 'last'
        }
      },
      day_of_week: {
        label: 'Day of Week',
        help: 'Select the day, or if this is a daily schedule you can select multiple days.'
      },
      default_contractor_percentage: {
        label: 'Contractor Percentage',
        help: 'The default percentage to be used.'
      },
      consumable: {
        label: 'Consumable',
        help: 'If this task uses a consumable item, the contractor will be asked to indicate the quantity of consumable items that were used. The price will be multiplied by this quantity.'
      },
      locked: {
        label: 'Lock',
        help: 'When activated this will prevent any changes to this {resource} both manual and automatic.'
      },
      skip: {
        label: 'Skip',
        help: 'When activated this will skip this {resource} from being applied.'
      },
      task_group_conflict: {
        label: 'Process When Scheduling Conflict Detected',
        help: "Select what should happen if/when this Task Group creates a schedule that falls on the same day as another Task Group of this client. 'Overwrite' causes less frequent Task Groups to overwrite more frequent Task Groups. 'Merge' will create a single booking with merged tasks and contractors. 'Coexist' will allow the two bookings to be scheduled on the same day.",
        overwrite: {
          label: 'Overwrite',
          description: 'Overwrites less frequent Task Groups when scheduled on the same day'
        },
        merge: {
          label: 'Merge',
          description: 'Merges with Task Groups when scheduled on the same day'
        },
        coexist: {
          label: 'Coexist',
          description: 'Coexists with Task Groups when scheduled on the same day'
        }
      },
      billing_address: 'Billing Address',
      at_address: 'At {address}',
      choose_options: 'Choose Options',
      chosen_options: 'Chosen Options',
      filters: {
        from_today: 'Filter from today',
        from_week: 'Filter from this week',
        from_last_month: 'Filter from last month',
        from_month: 'Filter from this month',
        from_last_quarter: 'Filter from last quarter',
        from_quarter: 'Filter from this quarter',
        from_financial_year: 'Filter from this financial year',
        from_year: 'Filter from this year'
      },
      date_selection: {
        today: 'Today',
        last_month: 'Last Month',
        last_30: 'Last 30 Days',
        last_quarter: 'Last Quarter',
        last_90: 'Last 90 Days',
        last_financial_year: 'Last Financial Year',
        last_year: 'Last Year',
        last_365: 'Last 365 Days'
      },
      terms_description: '{type} {length} <small>({days} Day Terms)</small>',
      tax_invoice: 'Tax Invoice',
      bill_to: 'Bill To',
      terms: 'Terms',
      term_length: '{days} Days',
      issued: 'Issued',
      service: 'Service',
      unit_cost: 'Unit Cost',
      gst: 'GST',
      add_row: 'Add Row',
      total_incl: 'Total Inc. GST',
      amount_applied: 'Amount Applied',
      balance_due: 'Balance Due',
      attached_jobs: 'Attached Jobs',
      attached_bookings: 'Attached Bookings',
      myob_contact: 'MYOB Contact',
      auto_invoice: 'Auto Invoice',
      auto_remind: 'Auto Payment Reminder',
      balance: 'Balance',
      paid: 'Paid',
      cash: 'Paid in Cash',
      cash_paid: 'Paid {amount} in Cash',
      cancelled: 'Cancelled',
      payment_type: {
        label: 'Payment Type',
        help: 'Choose how the contractor who does this task should be paid, they will only see the amount they get paid.'
      },
      postpaid: 'Postpaid',
      prepaid: 'Prepaid',
      source: 'Source',
      amount: 'Amount',
      fee: 'Fee',
      status: 'Status',
      income: 'Income',
      incoming: 'Incoming',
      outgoing: 'Outgoing',
      incoming_tax: 'Incoming GST',
      outgoing_tax: 'Outgoing GST',
      tax_total: 'GST Total',
      payg: 'PAYG',
      payg_contribution: 'PAYG Contribution for the Quarter',
      mark_bas: 'Mark BAS as Completed',
      invoice_amount: 'Invoice Amount: {amount}',
      transaction_amount: 'Transaction Amount: {amount}',
      difference: 'Difference: {amount}',
      remainder: 'Remainder: {amount}',
      accounting_number: 'Accounting Number',
      color: 'Colour',
      business_use: 'Business Use',
      account_statement: 'Account Statement',
      date_range: 'Date Range',
      range: 'Range',
      send_invoice_reminder: 'Send Invoice Reminder',
      send_invoice_notice: 'Send Notice to Pay',
      send_suspension_warning: 'Send Suspension Warning',
      suspend_service: 'Suspend Clients Service',
      last_sent: 'Last Sent',
      unpaid: 'Unpaid',
      last_issued: 'Last Invoice Issued',
      owing: 'Owing',
      week_number: 'Week {number}',
      expand_weeks: 'Expand Weeks',
      contract_weeks: 'Contract Weeks',
      photos_missing: 'Missing Photos',
      length: 'Length',
      count_days: '{count} Day|{count} Days',
      file: 'File',
      filename: 'Filename',
      size: 'Size',
      updated_at: 'Last Modified',
      expires_at: 'Expiry Date',
      owner: 'Owner',
      year: 'Year',
      quarter: 'Quarter',
      activity_year: '{from} - {to}',
      activity_quarter: 'Q{quarter}',
      avg_time_to_pay: 'Avg. Time to Pay',
      time_to_pay: '{days} Days',
      abn: 'ABN',
      bsb: 'Banking BSB',
      bank_account: 'Banking Account Number',
      legal_name: 'Legal Name',
      legal_business_name: 'Legal Business Name',
      credit_remainder: 'Apply remainder as a credit to this client',
      remainder_credited: 'Remainder of {amount} credited to client',
      frequency: 'Frequency',
      read_description: 'Read Description',
      due: 'Due',
      last_done: 'Last Done',
      stock_report: 'Stock Report',
      client_schedule: 'Client Schedule',
      destroy_client: 'Delete Client',
      average_use: {
        label: 'Average',
        help: 'The average use over the last 6 months'
      },
      mode_use: {
        label: 'Mode',
        help: 'The mode use over the last 6 months'
      },
      require_photos: {
        label: 'Require Photos',
        help: 'When turned on, contractors are required to supply photos each time they fulfill tasks for this client.'
      },
      documents: {
        insurance: 'Insurance Documents',
        msds: 'Material Safety Data Sheet',
        vaccination: 'Vaccination Certificate'
      },
      suspend_reason: {
        label: 'Reason for Suspension',
        help: 'Enter a reason why this clients services need to be suspended'
      },
      suspend_to: {
        label: 'Suspend Until',
        help: 'Enter the date when this suspension should be lifted, otherwise leave blank if indefinite'
      },
      pause_to_date: {
        label: 'Pause To',
        help: 'Select a date to pause to, this {resource} will automatically resume on that date. Leave blank to pause for an indefinite period.'
      },
      pause_reason: 'Pause Reason',
      paused_indefinitely: 'Paused indefinitely',
      paused_until: 'Paused until {date}'
    },
    activity: {
      schedule: {
        locked: '{client} scheduled booking ({taskGroup}) has been locked by {user}',
        unlocked: '{client} scheduled booking ({taskGroup}) has been unlocked by {user}',
        skip: '{user} has marked the scheduled booking ({taskGroup}) for {client} to be skipped',
        unskip: '{user} has unmarked the scheduled booking ({taskGroup}) for {client} from being skipped',
        reassign: '{user} has reassigned the scheduled booking ({taskGroup}) for {client} to {newUser}'
      }
    }
  }
}
