<template>
    <div id="header" class="w-100 bg-primary d-flex justify-content-between align-items-center">
        <div id="breadcrumb"></div>
        <div id="quick-actions" class="btn-group h-100">
            <header-button type="button" id="profile-dropdown">
                <div id="user-icon" class="d-flex align-items-center">
                    <img v-if="auth.avatar" :src="appURL + '/storage/' + auth.avatar" />
                    <span v-else class="d-block border img-circle text-center" :style="'width: ' + '32' + 'px; line-height: ' + '30' + 'px;'">
                        <fa-icon :icon="['fal', 'user']" />
                    </span>
                    <span class="ml-2">{{ auth.name }}</span>
                </div>
                <div class="dropdown-menu">
                    <router-link class="dropdown-item" :to="{ name: 'Profile' }">{{ $t('labels.nav.my_profile') }}</router-link>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="logout">{{ $t('labels.sign_out') }}</a>
                </div>
            </header-button>
            <header-button @click="logout">
                <fa-icon :icon="['fas', 'sign-out-alt']"></fa-icon>
            </header-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Header',
  data () {
    return {
      appURL: process.env.VUE_APP_ASSET_URL
    }
  },
  computed: mapState({
    auth: state => state.auth,
    chatState: state => state.chatState
  }),
  methods: {
    ...mapActions([
      'toggleChat'
    ]),
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
    #header {
        height: 50px;

        #quick-actions {
            font-size: 1.4rem;

            .dropdown-menu {
                font-size: 0.85rem;
                top: 47px;
                right: 45px;
                left: auto;
                width: 200px;
            }

            #profile-dropdown {

                &:hover .dropdown-menu {
                    display: block;
                }
            }

            .fa-layers-counter {
                transform: scale(0.45);
            }

            #user-icon {

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 16px;
                }

                span {
                    display: none;
                    font-size: 0.75rem;
                }
            }

            .dropdown-toggle:after {
                /*display: none;*/
            }
        }
    }

    @media (min-width: 768px) {
        #header {

            #quick-actions {

                #user-icon {

                    span {
                        display: inline;
                    }
                }
                .dropdown-toggle:after {
                    display: inline-block;
                }
            }
        }

    }
</style>
