<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.accounts') }}</h3>
      <nav-secondary-accounts />
    </route-header>
    <div class="row">
      <div class="col-12 mb-4" v-if="authCan('users')">
        <contractor-monthly-progress />
      </div>
    </div>
  </div>
</template>

<script>
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'Accounts',
  mixins: [authMethods],
  data () {
    return {
    }
  }
}
</script>
