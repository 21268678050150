<template>
    <div>
        <bs-card class="card-info" :icon="['fal', 'books']" :label="$t('labels.' + camelToSnake($options.name))">
            <template v-slot:header>
                <bs-button class="btn-sm btn-info" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
            </template>
            <bs-table>
                <template v-slot:thead>
                    <tr>
                        <th scope="col">{{ $t('labels.name') }}</th>
                        <th scope="col"></th>
                    </tr>
                </template>
                <tr v-for="r in resources.data" :key="r.id">
                    <td>{{ r.name }}</td>
                    <td class="t2">
                        <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(r)"></bs-action>
                        <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
                    </td>
                </tr>
                <tr v-for="blank in dataLength" :key="'b' + blank">
                    <td colspan="2" class="blank">
                        <div class="loading" v-if="loading"></div>
                        <span v-else>&nbsp;</span>
                    </td>
                </tr>
                <template v-slot:tfoot>
                    <tr>
                        <td colspan="2">
                            <pagination :data="resources" @pushPage="index"></pagination>
                        </td>
                    </tr>
                </template>
            </bs-table>
        </bs-card>
        <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
            <bs-form :submit="$t('labels.submit')" @submit="submit">
                <template v-slot:default="slotProps">
                    <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="slotProps.error"></tuxedo-input>
                    <checkbox-field :label="'Permissions'" :options="permissions" v-model="resource.permissions"></checkbox-field>
                </template>
            </bs-form>
        </bs-modal>
        <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
            <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
                {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
            </bs-form>
        </bs-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Roles',
  data () {
    return {
      tableRows: 10,
      page: 1,
      sort: {
        col: 'label',
        dir: 'asc'
      },
      loading: false,
      activeId: null,
      resource: {
        name: '',
        permissions: []
      },
      permissions: {
        view_clients: {
          name: this.$t('permits.view_clients'),
          children: {
            manage_clients: this.$t('permits.manage_clients'),
            suspend_clients: this.$t('permits.suspend_clients')
          }
        },
        manage_accounts: {
          name: this.$t('permits.manage_accounts')
        },
        manage_invoices: {
          name: this.$t('permits.manage_invoices')
        },
        manage_transactions: {
          name: this.$t('permits.manage_transactions')
        },
        manage_expenses: {
          name: this.$t('permits.manage_expenses')
        },
        manage_documents: {
          name: this.$t('permits.manage_documents')
        },
        manage_schedules: {
          name: this.$t('permits.manage_schedules'),
          children: {
            reschedule: this.$t('permits.reschedule'),
            reschedule_all: this.$t('permits.reschedule_all')
          }
        },
        settings: {
          name: this.$t('permits.settings'),
          children: {
            users: this.$t('permits.users'),
            roles: this.$t('permits.roles'),
            suburbs: this.$t('permits.suburbs'),
            vendors: this.$t('permits.vendors'),
            expense_categories: this.$t('permits.expense_categories')
          }
        },
        manage_notes: {
          name: this.$t('permits.manage_notes')
        },
        manage_tasks: {
          name: this.$t('permits.manage_tasks')
        },
        task_price: {
          name: this.$t('permits.task_price')
        }
      }
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      resources: state => state.roles,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.tableRows - length, 0)
    }
  },
  methods: {
    applySort (val) {
      this.sort = val
      this.index()
    },
    index (page, count) {
      if (page) this.page = page
      else page = this.page
      if (count) this.tableRows = count
      else count = this.tableRows
      this.loading = true
      this.$store.dispatch('index', { collection: this.$options.name, params: { page, count, sort: this.sort.col, direction: this.sort.dir } }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.$modal.show('create-' + this.$options.name)
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { collection: this.$options.name, id: this.activeId }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
