<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.upload }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="6" :dropZone="dropZone" @drop="dropFile">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.name')"></bs-th>
            <bs-th :label="$t('labels.type')" class="text-center"></bs-th>
            <bs-th :label="$t('labels.state')" class="text-center"></bs-th>
            <bs-th :label="$t('labels.size')" class="text-right"></bs-th>
            <bs-th scope="col"></bs-th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id" draggable="true" @dragstart="dragExpense($event, r.id)">
          <td class="text-break">{{ r.filename }}</td>
          <td class="text-center">
            <bs-badge class="bg-info text-light">{{ r.doc_type }}</bs-badge>
          </td>
          <td class="text-center">
            <bs-badge :class="r.state.class" v-tooltip.left="r.description">{{ $t('labels.' + r.state.state) }}</bs-badge>
          </td>
          <td class="text-right">{{ r.size }}</td>
          <td class="t2">
            <bs-actions>
              <template v-slot:primary>
                <bs-action :icon="['fal', 'file-alt']" :tip="r.filename" class="btn-secure" @click="view(r)" />
              </template>
              <template v-slot:secondary>
                <button class="dropdown-item" v-if="authCan('manage_documents')" @click="destroy(r)">{{ labels.delete }}</button>
              </template>
            </bs-actions>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.upload">
        <bs-form :submit="$t('labels.submit')" @submit="upload">
          <template v-slot:default="{ error }">
            <tuxedo-input :name="'description'" :label="$t('labels.description')" v-model="resource.description" :error="error" />
            <file-field :name="'file'" :multiple="true" :label="$t('labels.file')" v-model="resource.files" :accept="'image/*,.pdf'" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
      <bs-modal
          :name="'file-view-' + $options.name"
          :label="labels.view" @closed="closeFileView"
          :fullScreen="true"
      >
        <div v-if="!fileSource">Retrieving File</div>
        <img v-else-if="activeFile && activeFile.is_image" :src="fileSource" style="max-width:100%;max-height:100%" />
        <embed v-else-if="activeFile" :src="fileSource" style="width: 100%;height: 100%" />
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import { watchWindowSize } from '@/mixins/helpers'
import { authMethods } from '../../mixins/authMethods'
import submit from '../../router/submit'

export default {
  name: 'ExpenseDocuments',
  mixins: [watchWindowSize, resourceControl, authMethods],
  data () {
    return {
      icon: ['fal', 'cabinet-filing'],
      resource: {
        description: '',
        files: null
      },
      types: [
        {
          id: 'msds',
          label: this.$t('labels.documents.msds')
        }
      ],
      dropZone: true,
      activeFile: null,
      fileSource: null
    }
  },
  computed: {
    ...mapState({
      users: state => state.usersStore,
      resources: state => state.expenseDocuments,
      resourceState: state => state.expenseDocumentsState,
      resourceStore: state => state.expenseDocumentsStore
    })
  },
  methods: {
    beforeCreate () {
      this.resource.file = null
    },
    dropFile (e) {
      if (this.dropZone) {
        this.resource.files = e
        console.log(this.resource.files)
        this.upload()
      }
    },
    dragExpense (ev, id) {
      this.dropZone = false
      console.log('start drag')
      ev.dataTransfer.setData('document', id)
    },
    view (r) {
      this.activeFile = r
      this.$modal.show('file-view-' + this.$options.name)
      submit.get('api/documents/' + r.id + '/stream').then((res) => {
        this.fileSource = res.data
      })
    },
    closeFileView () {
      this.fileSource = null
    }
  }
}
</script>
