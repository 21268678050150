import helpers from '../helpers'
import Vue from 'vue'
import _ from 'lodash'

export const mutations = {
  online (state) { state.online = true },
  offline (state) { state.online = false },
  axiosError (state, val) {
    if (val && val.response && val.response.data) state.axiosError = val.response.data
    else state.axiosError = null
  },
  initialLoad (state, val) {
    if (val === 'reset') state.initialLoadCompleted = 0
    else state.initialLoadCompleted++
  },
  auth (state, val) {
    state.auth = val ? val.data : null
  },
  submitting (state, val) {
    state.submitting = val
  },
  chatOn (state) {
    state.chatOpen = true
  },
  chatToggle (state) {
    state.chatActiveID = null
    state.chatOpen = !state.chatOpen
  },
  chatTypeToggle (state) {
    state.chatType = state.chatType === 'chat-fixed' ? 'chat-float' : 'chat-fixed'
  },
  chatActive (state, val) {
    state.chatActive = val
  },
  pushState (state, payload) {
    const store = helpers.firstToLower(payload.view) + 'State'
    if (state[store] === undefined) console.error(store + ' does not exist in the store')
    else if (state[store][payload.key] === undefined) Vue.set(state[store], payload.key, payload.data)
    else state[store][payload.key] = payload.data
  },
  pushFilter (state, payload) {
    Object.keys(payload.data).forEach((key) => {
      if (!payload.data[key]) {
        Vue.delete(state[helpers.firstToLower(payload.view) + 'State'].filters, key)
      } else {
        state[helpers.firstToLower(payload.view) + 'State'].filters[key] = payload.data[key]
      }
    })
  },
  processResponse (state, payload) {
    function updateOrCreate (payload) {
      const collection = payload.collection
      const resource = payload.resource

      // Search collection for id and either update, or if not found, add as a new resource
      const key = state[collection].data ? state[collection].data.findIndex(o => o.id === resource.id) : -1
      if (key >= 0) Vue.set(state[collection].data, key, resource)
      else if (state[collection].data) state[collection].data.unshift(resource)
      else Vue.set(state[collection], 'data', [resource])

      // If this collection also has a store then update the store too
      if (state[collection + 'Store']) {
        const key = state[collection + 'Store'].findIndex(o => o.id === resource.id)
        if (key >= 0) Vue.set(state[collection + 'Store'], key, resource)
        else state[collection + 'Store'].unshift(resource)
      }
    }

    const data = payload.data
    if (payload.resource) {
      Vue.set(state, payload.resource, data)
    } else if (payload.subset) {
      const collection = payload.subset
      if (state[collection] === undefined) console.warn(collection + ' does not exist in the store')
      if (data && data.data) {
        data.data.forEach(function (r) {
          updateOrCreate({ collection, resource: r })
        })
      }
    } else {
      const collection = payload.collection
      if (state[collection] === undefined) console.warn(collection + ' does not exist in the store')
      else if (!data) Vue.set(state[collection], 'data', [])

      // Process a single returned ID, usually a resource that is not paginated
      else if ('id' in data) {
        Vue.set(state[collection], 'data', _.filter(state[collection].data, (o) => {
          return o.id !== data.id
        }))

        // Also check if this resource has a full store and update there too
        if (state[collection + 'Store']) {
          Vue.set(state[collection + 'Store'], 'data', _.filter(state[collection + 'Store'], (o) => {
            return o.id !== data.id
          }))
        }
      } else if (data.data && data.data.id) {
        updateOrCreate({ collection, resource: data.data })
      } else Vue.set(state, collection, data)
    }
  },
  recordResponseTime (state, payload) {
    if (!state.axiosTiming[payload.url]) {
      Vue.set(state.axiosTiming, payload.url, [])
    }
    Vue.set(state.axiosTiming[payload.url], state.axiosTiming[payload.url].length, payload.seconds)
    if (state.axiosTiming[payload.url].length > 5) state.axiosTiming[payload.url].shift()
  },
  updateQueue (state, payload) {
    state.axiosQueue.push({
      url: payload.url,
      message: payload.message,
      icon: payload.icon
    })
  },
  removeFromQueue (state, payload) {
    if (payload) {
      const key = state.axiosQueue.findIndex(q => q.url === payload.url)
      Vue.delete(state.axiosQueue, key)
    } else {
      const filterQueue = state.axiosQueue.filter(q => q.error && q.error.length > 0)
      Vue.set(state, 'axiosQueue', filterQueue)
    }
  },
  errorInQueue (state, payload) {
    if (payload) {
      const key = state.axiosQueue.findIndex(q => q.url === payload.url)
      const filterQueue = state.axiosQueue.filter(q => q.url !== payload.url)
      const icon = state.axiosQueue[key].icon
      const message = state.axiosQueue[key].message
      Vue.set(state, 'axiosQueue', filterQueue)
      state.axiosQueue.push({
        url: payload.url,
        message,
        icon,
        error: payload.error
      })
    }
  },
  updateChunks (state, payload) {
    if (payload) {
      for (const [key, value] of Object.entries(payload)) {
        Vue.set(state.chunkState, key, value)
      }
    } else {
      Vue.set(state.chunkState, 'total', 0)
    }
  },
  appendComments (state, payload) {
    const discussion = 'd' + payload.id

    // If this discussion isn't yet loaded into vuex
    // if (!state.comments[discussion]) {
    Vue.set(state.comments, discussion, payload.data)

    // Otherwise we append comments and update meta + links
    // } else {
    //   Vue.set(state.comments[discussion], 'meta', payload.data.meta)
    //   Vue.set(state.comments[discussion], 'links', payload.data.links)
    //   state.comments[discussion].data.unshift(payload.data.data)
    // }
  },
  viewLoading (state, val) {
    state.viewLoading = val
  }
}
