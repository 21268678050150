<template>
  <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.submit')" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
        <tuxedo-input :name="'invoice_description'" :label="$t('labels.invoice_description.label')" :help="$t('labels.invoice_description.help')" v-model="resource.invoice_description" :error="error"></tuxedo-input>
        <text-field :name="'description'" :label="$t('labels.default_description.label')" :help="$t('labels.default_description.help')" v-model="resource.description" :error="error"></text-field>
        <tuxedo-input :name="'price'" :label="$t('labels.default_price.label')" :help="$t('labels.default_price.help')" v-model="resource.price" :error="error"></tuxedo-input>
        <tuxedo-input :type="'number'" :name="'contractor_percentage'" :label="$t('labels.default_contractor_percentage.label')" :help="$t('labels.default_contractor_percentage.help')" v-model="resource.contractor_percentage" :error="error"></tuxedo-input>
        <toggle-field :name="'consumable'" :label="$t('labels.consumable.label')" :help="$t('labels.consumable.help')" v-model="resource.consumable" :error="error"></toggle-field>
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { mapState } from 'vuex'
import helpers from '../../helpers'

export default {
  name: 'TaskForm',
  props: ['activeId'],
  data () {
    return {
      resource: {
        name: '',
        invoice_description: '',
        description: '',
        price: 0,
        contractor_percentage: 80,
        consumable: 0
      },
      labels: {
        name: this.$tc('labels.' + helpers.camelToSnake(helpers.singular('tasks')), 0),
        create: this.$t('labels.action_resource', { action: this.$t('labels.create'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular('tasks')), 1) }),
        edit: this.$t('labels.action_resource', { action: this.$t('labels.edit'), resource: this.$tc('labels.' + helpers.camelToSnake(helpers.singular('tasks')), 1) })
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.tasksStore
    })
  },
  mounted () {
    if (this.activeId) {
      const task = this.resources.find(r => parseInt(r.id) === parseInt(this.activeId))
      if (task) this.resource = JSON.parse(JSON.stringify(task))
    }
    this.$modal.show('create-' + this.$options.name)
  },
  methods: {
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: 'tasks', resource: this.resource }).then(res => {
        this.$emit('task', res.data.data.id)
        this.$emit('closed')
      })
    }
  }
}
</script>
