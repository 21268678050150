<template>
    <div class="form-group bs-form-group">
        <label class="text-uppercase mb-0">{{ label }}</label>
        <div v-for="(option, value) in options" :key="value">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" :value="value" :id="value" v-model="inputValue" @change="update">
                <label class="custom-control-label" :for="value">{{ option.name ? option.name : option }}</label>
            </div>
            <div class="custom-control custom-checkbox ml-3" v-for="(childOption, childValue) in option.children" :key="childValue">
                <input type="checkbox" class="custom-control-input" :value="childValue" :id="childValue" v-model="inputValue" @change="update">
                <label class="custom-control-label" :for="childValue">{{ childOption }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CheckboxField',
  props: ['type', 'label', 'value', 'error', 'options'],
  data () {
    return {
      proxyValue: []
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (val) {
        this.proxyValue = val
      }
    }
  },
  methods: {
    update () {
      this.$emit('input', this.proxyValue)
    }
  }
}
</script>

<style lang="scss">
    .custom-checkbox {
        label {
            padding-top: 2px;
            font-size: 0.875rem;
        }
    }
</style>
