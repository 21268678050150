<template>
  <bs-modal :name="formName" :label="labels.form" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.resume')" @submit="$emit('submit', form)">
      {{ $t('messages.play_task') }}
    </bs-form>
  </bs-modal>
</template>

<script>
import { makeId } from '@/mixins/helpers'

export default {
  name: 'PlayTaskForm',
  mixins: [makeId],
  computed: {
    formName () { return 'play-task-' + this.makeId() }
  },
  data () {
    return {
      form: {
        id: null,
        paused: false,
        pause_to: null,
        pause_reason: null
      },
      labels: {
        form: this.$t('labels.action_resource', { action: this.$t('labels.resume'), resource: this.$tc('resource.task', 1) })
      }
    }
  },
  methods: {
    show (t) {
      this.form.id = t.id
      this.$modal.show(this.formName)
    },
    hide () {
      this.$modal.hide(this.formName)
    }
  }
}
</script>
