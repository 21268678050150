<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="6">
        <template v-slot:thead>
          <tr v-if="resourceState.search.active">
            <th class="p-1" :colspan="5">
              <input ref="searchInput" type="text" class="form-control border-1 border-primary shadow-sm bg-light" v-model="searchPhrase">
            </th>
            <th class="text-right"><bs-action :icon="['fas', 'times']" class="btn-outline-secondary" @click="applySearch({ active: false, phrase: '' })"></bs-action></th>
          </tr>
          <tr v-else>
            <bs-th :label="$t('labels.date')" scope="col" :sortable="resourceState.sort" :col="'created_at'" @click="applySort" />
            <bs-th :label="$t('labels.description')" scope="col" />
            <bs-th scope="col" />
            <bs-th :label="$t('labels.amount')" scope="col" class="text-right" />
            <bs-th :label="$t('labels.file')" scope="col" class="text-right" />
            <th class="text-right"><bs-action :icon="['fal', 'search']" class="btn-outline-secondary" @click="applySearch({ active: true, phrase: '' })"></bs-action></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id" @dragenter="dragEnter" @drop="dropData($event, r.id)">
          <td><date :date="r.created_at" /></td>
          <td>{{ r.description }}</td>
          <td class="d-flex justify-content-between">
            <bs-badge :class="'bg-' + r.category.color">
              <span>{{ r.category.name }} {{ r.category.number }}</span>
              <span v-if="r.vendor"> | {{ r.vendor.name }}</span>
              <span v-if="r.gst"> | {{ $t('labels.gst') }}</span>
            </bs-badge>
            <bs-badge class="bg-light-yellow" v-if="r.business_use < 100">
              <span>{{ $n(r.business_use / 100, 'percent') }}</span>
            </bs-badge>
          </td>
          <td class="text-right">
            {{ $n(r.amount * (r.business_use / 100), 'currency') }}
          </td>
          <td class="t3 text-right">
            <div v-for="d in r.documents" :key="d.id">
              <bs-action v-if="d.confirmed" :icon="['fal', 'file-alt']" :tip="d.filename" class="btn-secure" @click="view(r, d)" />
              <bs-badge v-else class="bg-info-light p-0">
                <div class="p-1" v-tooltip.left="'Confidence: ' + d.confidence + '%'">
                  <fa-icon :icon="['fas', 'eye']" class="mr-1 clickable" @click="view(r, d)" />
                  <span>{{ d.filename }}</span>
                  <fa-icon :icon="['fas', 'check']" class="ml-1 clickable" @click="confirm(r, d)" />
                </div>
                <div class="progress" style="height: 2px">
                  <div class="progress-bar bg-indigo" role="progressbar" :style="'width: ' + d.confidence + '%'"></div>
                </div>
              </bs-badge>
            </div>
          </td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
        <bs-form :submit="$t('labels.submit')" @submit="submit">
          <template v-slot:default="slotProps">
            <div class="row">
              <div class="col-7">
                <tuxedo-input :name="'description'" :label="$t('labels.description')" v-model="resource.description" :error="slotProps.error" />
              </div>
              <div class="col-5">
                <tuxedo-input :type="'datetime-local'" :name="'created_at'" :label="$t('labels.date')" v-model="resource.created_at" :error="slotProps.error"></tuxedo-input>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-4">
                <tuxedo-input :name="'amount'" :label="$t('labels.amount')" v-model="resource.amount" :error="slotProps.error" />
              </div>
              <div class="col-3">
                <tuxedo-input :type="'percentage'" :name="'business_use'" :label="$t('labels.business_use') + ' %'" v-model="resource.business_use" :error="slotProps.error"></tuxedo-input>
              </div>
              <div class="col-3">
                <div class="form-group bs-form-group">
                  <label class="text-uppercase mb-0">{{ $t('labels.gst') }}</label>
                  <input class="form-control shadow-sm border-top-0 border-left-0 border-bottom-1" :value="gstAmount" disabled>
                </div>
              </div>
              <div class="col-1">
                <tuxedo-toggle :name="'gst'" :label="$t('labels.gst')" v-model="resource.gst" :error="slotProps.error"></tuxedo-toggle>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-6">
                <tuxedo-select :name="'category'" :label="$tc('labels.expense_category', 1)" v-model="resource.category" :options="[{ id: '0', name: '' }].concat(sortedCategories)" :insistLabel="'name'" :insistReturn="'id'" :error="slotProps.error" />
              </div>
              <div class="col-6">
                <tuxedo-select :name="'vendor'" :label="$tc('labels.vendor', 1)" v-model="resource.vendor" :options="[{ id: '0', name: '' }].concat(sortedVendors)" :insistLabel="'name'" :insistReturn="'id'" :error="slotProps.error" />
              </div>
            </div>
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
      <bs-modal
          :name="'file-view-' + $options.name"
          :label="labels.view" @closed="closeFileView"
          :fullScreen="true"
      >
        <div v-if="!fileSource">Retrieving File</div>
        <img v-else-if="activeFile && activeFile.is_image" :src="fileSource" style="max-width:100%;max-height:100%" />
        <object v-else-if="activeFile" :src="fileSource" style="width: 100%;height: 100%" />
        <template v-slot:footer>
          <div class="d-flex justify-content-end align-items-center">
            <bs-button type="submit" class="btn-warning" @click="detach">
              <span>Remove</span>
            </bs-button>
          </div>
        </template>
      </bs-modal>
    </portal>
    <portal to="app-footer">
      <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import { Sec } from '../../mixins/sec'
import submit from '../../router/submit'

export default {
  name: 'Expenses',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'envelope-open-dollar'],
      resource: {
        created_at: null,
        category: null,
        vendor: null,
        amount: null,
        business_use: null,
        gst: true
      },
      activeExpense: null,
      activeFile: null,
      fileSource: null
    }
  },
  computed: {
    ...mapState({
      resources: state => state.expenses,
      resourceState: state => state.expensesState,
      categories: state => state.expenseCategoriesStore,
      vendors: state => state.vendorsStore
    }),
    sortedCategories () {
      return this._.orderBy(this.categories, ['name'])
    },
    sortedVendors () {
      return this._.orderBy(this.vendors, ['name'])
    },
    gstAmount () {
      if (this.resource.amount && this.resource.gst) {
        return parseFloat(this.resource.amount / 11).toFixed(2)
      }
      return 0
    }
  },
  methods: {
    dragEnter (ev) {
      const target = ev.target
      const tr = target.closest('tr')
      const parent = tr.parentNode
      Array.from(parent.children).forEach(tr => tr.classList.remove('bg-gray-200'))
      tr.classList.add('bg-gray-200')
    },
    dropData (ev, expense) {
      const target = ev.target
      const tr = target.closest('tr')
      const parent = tr.parentNode
      Array.from(parent.children).forEach(tr => tr.classList.remove('bg-gray-200'))
      const document = ev.dataTransfer.getData('document')
      submit.get('api/expense/' + expense + '/document/' + document + '/attach').then((res) => {
        this.$store.dispatch('index', { collection: 'expenseDocuments' })
        this.$store.commit('processResponse', { collection: 'expenses', data: res.data })
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.resource.created_at = new Sec().LocalDateTime()
      this.resource.business_use = 100
      this.resource.gst = true
      this.$modal.show('create-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.resource.category = o.category.id
      this.resource.vendor = o.vendor ? o.vendor.id : null
      this.resource.created_at = new Sec(o.created_at).LocalDateTime()
      this.$modal.show('create-' + this.$options.name)
    },
    view (r, d) {
      this.activeExpense = r
      this.activeFile = d
      this.$modal.show('file-view-' + this.$options.name)
      submit.get('api/documents/' + d.id + '/stream').then((res) => {
        this.fileSource = res.data
      })
    },
    confirm (r, d) {
      this.activeExpense = r
      this.activeFile = d
      submit.get('api/expense/' + this.activeExpense.id + '/document/' + this.activeFile.id + '/confirm').then((res) => {
        // this.$store.dispatch('index', { collection: 'expenseDocuments' })
        this.$store.commit('processResponse', { collection: 'expenses', data: res.data })
      })
    },
    closeFileView () {
      this.fileSource = null
    },
    detach () {
      submit.get('api/expense/' + this.activeExpense.id + '/document/' + this.activeFile.id + '/detach').then((res) => {
        this.$modal.hide('file-view-' + this.$options.name)
        this.$store.dispatch('index', { collection: 'expenseDocuments' })
        this.$store.commit('processResponse', { collection: 'expenses', data: res.data })
      })
    }
  }
}
</script>
