<template>
    <div class="d-flex align-items-center flex-wrap">
        <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/client/' + client })">{{ $t('labels.bookings') }}</bs-button>
        <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/client/' + client + '/tasks' })">{{ $t('labels.tasks') }}</bs-button>
        <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/client/' + client + '/invoices' })">{{ $tc('labels.invoice', 0) }}</bs-button>
        <bs-button class="btn-sm btn-warning ml-2" @click="$router.push({ path: '/client/' + client + '/settings' })">{{ $t('labels.settings') }}</bs-button>
    </div>
</template>

<script>
export default {
  name: 'NavSecondaryClient',
  props: ['client']
}
</script>
