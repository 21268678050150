<template>
  <bs-modal :name="'create-' + $options.name" :label="labels.credit" @closed="$emit('closed')">
    <bs-form :submit="$t('labels.submit')" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select :name="'client_id'"
                       :label="$tc('resource.client', 1)"
                       :options="clients"
                       v-model="resource.client_id"
                       :insistLabel="'name'"
                       :insistReturn="'id'"
                       :error="error"
        >
        </tuxedo-select>
      </template>
    </bs-form>
  </bs-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CreditClientForm',
  data () {
    return {
      resource: {
        id: null,
        credit: true,
        client_id: null
      },
      labels: {
        credit: this.$t('labels.action_resource', { action: this.$t('labels.credit'), resource: this.$tc('resource.client', 1) })
      }
    }
  },
  computed: {
    ...mapState({
      clients: state => state.clientsStore
    })
  },
  methods: {
    show (r) {
      this.resource.id = r.id
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch('update', { collection: 'Transactions', resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    }
  }
}
</script>
