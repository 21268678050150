<template>
    <div>
        <modal :name="name"
               :draggable="draggable"
               :adaptive="!fullScreen"
               :reset="true"
               :clickToClose="false"
               :scrollable="true"
               :width="fullScreen ? '90%' : 600"
               :height="fullScreen ? '90%' : 'auto'"
               @closed="$emit('closed')"
        >
            <bs-card class="card-info" :label="label" :allowOverflow="true">
                <template v-slot:header>
                    <bs-action :icon="['fas', 'times']" class="btn-info text-light" @click="$modal.hide(name)"></bs-action>
                </template>
                <slot></slot>
              <template v-slot:footer>
                <slot name="footer"></slot>
              </template>
            </bs-card>
        </modal>
    </div>
</template>

<script>
export default {
  name: 'BSModal',
  props: ['name', 'label', 'fullScreen'],
  computed: {
    draggable () {
      return window.innerWidth > 768
    }
  }
}
</script>

<style>
.v--modal-overlay .v--modal-box {
  overflow: visible!important;
}
</style>
