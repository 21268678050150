<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.invoice', { no: number }) }}</h3>
      <nav-secondary-accounts />
    </route-header>
    <div class="row">
      <div class="col-12 col-lg-6 mb-4" v-if="authCan('manage_invoices')">
        <invoice-editor :invoice="invoice" :key="'ie' + refKey" />
      </div>
      <div class="col-12 col-lg-6 mb-4" v-if="authCan('manage_invoices')">
        <client-consumables :invoice="invoice" class="mb-4" />
        <user-schedules :user="'0'" :invoice="invoice" :key="'us' + refKey" internalFooter />
      </div>
    </div>
    <bs-modal :name="'send-' + $options.name" :label="$t('labels.action_resource', { action: this.$t('labels.send'), resource: $tc('labels.invoice', 1) })">
      <bs-form :submit="$t('labels.send')" @submit="send">
        {{ $t('messages.send', { resource: $tc('labels.invoice', 1) }) }}
      </bs-form>
    </bs-modal>
    <portal to="app-footer">
      <div class="d-flex justify-content-end align-items-center" style="height: 50px;">
        <bs-button class="btn-success" v-if="state === 'open'" @click="prepare">
          <fa-icon :icon="['fas', 'paper-plane']" />
          <span class="pl-2">{{ $t('labels.send') }}</span>
        </bs-button>
        <bs-button class="btn-info ml-3" @click="next">
          <span class="pr-2">{{ $t('labels.next') }}</span>
          <fa-icon :icon="['fal', 'chevron-right']" />
        </bs-button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { authMethods } from '../mixins/authMethods'
import submit from '../router/submit.js'

export default {
  name: 'AccountsInvoice',
  props: ['invoice'],
  mixins: [authMethods],
  data () {
    return {
      refKey: 0
    }
  },
  computed: {
    ...mapState({
      invoices: state => state.invoices.data
    }),
    state () {
      const inv = this._.find(this.invoices, { id: parseInt(this.invoice) })
      if (inv) return inv.state.state
      return null
    },
    number () {
      const inv = this._.find(this.invoices, { id: parseInt(this.invoice) })
      if (inv) return inv.number
      return null
    }
  },
  beforeMount () {
    this.pushFilter({ view: 'Invoices', data: { client: null } })
  },
  methods: {
    ...mapMutations([
      'pushFilter'
    ]),
    next () {
      const currInx = this._.findIndex(this.invoices, { id: parseInt(this.invoice) })
      const next = this._.nth(this.invoices, currInx + 1)
      if (next) {
        this.$router.push({ name: 'AccountsInvoice', params: { invoice: next.id } })
      }
    },
    prepare () {
      this.$modal.show('send-' + this.$options.name)
    },
    send () {
      this.$store.commit('submitting', true)
      submit.get('api/invoices/' + this.invoice + '/send').then((res) => {
        this.$store.commit('processResponse', { collection: 'invoices', data: res.data })
        this.$store.commit('submitting', false)
        this.refKey++
        this.$modal.hide('send-' + this.$options.name)
      })
    }
  }

}
</script>
