import VueI18n from 'vue-i18n'
import Locales from '../resources/js/vue-i18n-locales.generated'
import Numbers from '../resources/js/vue-i18n-locales.numbers'
import Vue from 'vue'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  messages: Locales,
  numberFormats: Numbers
})
