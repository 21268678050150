<template>
    <div>
        <bs-card class="card-info" :icon="['fas', 'sticky-note']" :label="$t('labels.' + camelToSnake($options.name))">
            <template v-slot:header>
                <bs-button class="btn-sm btn-info" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
            </template>
            <div class="card-body border-bottom" v-for="r in resources.data" :key="r.id">
                <p class="card-text">{{ r.note }}</p>
                <div class="d-flex justify-content-between">
                    <div class="card-text"><small class="text-muted">{{ $t('messages.last_updated', { time: lastUpdated(r.updated_at) }) }}</small></div>
                    <div class="text-right">
                        <bs-action :icon="['fal', 'pencil']" class="btn-info mr-1" @click="update(r)"></bs-action>
                        <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
                    </div>
                </div>
            </div>
        </bs-card>
        <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
            <bs-form :submit="$t('labels.submit')" @submit="submit">
                <template v-slot:default="slotProps">
                    <text-field :name="'note'" :label="$t('labels.notes')" v-model="resource.note" :error="slotProps.error"></text-field>
                </template>
            </bs-form>
        </bs-modal>
        <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
            <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
                {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
            </bs-form>
        </bs-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '../../mixins/sec'
import helpers from '../../helpers'

export default {
  name: 'Notes',
  props: ['type', 'id'],
  data () {
    return {
      tableRows: 10,
      page: 1,
      sort: {
        col: 'update_at',
        dir: 'asc'
      },
      loading: false,
      activeId: null,
      resource: {
        note: ''
      }
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      resources: state => state.notes,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.tableRows - length, 0)
    }
  },
  methods: {
    lastUpdated (date) {
      return new Sec(date).format('locale')
    },
    applySort (val) {
      this.sort = val
      this.index()
    },
    index (page, count) {
      if (page) this.page = page
      else page = this.page
      if (count) this.tableRows = count
      else count = this.tableRows
      this.loading = true
      this.$store.dispatch('index', {
        collection: this.$options.name,
        path: helpers.camelToKebab(helpers.pluralize(this.type)),
        id: this.id,
        tag: helpers.camelToKebab(this.$options.name),
        params: { page, count, sort: this.sort.col, direction: this.sort.dir }
      }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.$modal.show('create-' + this.$options.name)
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', {
        collection: this.$options.name,
        path: helpers.camelToKebab(helpers.pluralize(this.type)),
        id: this.id,
        tag: helpers.camelToKebab(this.$options.name),
        resource: this.resource
      }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', {
        collection: this.$options.name,
        path: helpers.camelToKebab(helpers.pluralize(this.type)),
        id: this.id,
        tag: helpers.camelToKebab(this.$options.name),
        tag_id: this.activeId
      }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
