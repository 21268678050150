<template>
    <div>
        <bs-card class="card-purple" :icon="['fal', 'exclamation-square']" :label="$t('labels.' + camelToSnake($options.name))">
            <bs-table class="table-striped" v-bind="{dataLength, loading}" :cols="2">
                <template v-slot:thead>
                    <tr>
                        <bs-th :label="$t('labels.date')" :sortable="resourceState.sort" :col="'created_at'" @click="applySort"></bs-th>
                        <bs-th :label="$t('labels.action')"></bs-th>
                    </tr>
                </template>
                <tr v-for="r in resources.data" :key="r.id">
                    <td>
                      <date-time :date="r.created_at" />
                    </td>
                    <td>{{ r.description }}</td>
                </tr>
                <template v-slot:tfoot>
                    <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
                </template>
            </bs-table>
        </bs-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ActivityLog',
  data () {
    return {
      loading: false,
      formLoading: false
    }
  },
  mounted () {
    this.index(this.resourceState.page)
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      resourceState: state => state.activityLogState,
      resources: state => state.activityLog
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    index (page, count) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      this.loading = true
      this.$store.dispatch('index', {
        collection: this.$options.name,
        params: { page, count, sort: this.resourceState.sort.col, direction: this.resourceState.sort.dir }
      })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>
