<template>
  <bs-card class="card-info" :label="hideLabel ? null : visit.client_name">
    <template v-slot:header>

    </template>
    <bs-alert v-if="skipVisit" class="alert-danger m-2">{{ $t('messages.skip_visit') }}</bs-alert>
    <bs-alert v-else-if="!visit.client_active" class="alert-danger m-2">{{ $t('messages.visit_inactive') }}</bs-alert>
    <bs-alert v-else-if="visit.client_suspended" class="alert-danger m-2">{{ $t('labels.suspended') }}</bs-alert>
    <bs-alert v-else-if="visit.client_terms === 'cash'" class="alert-success m-2">{{ $t('messages.collect_cash') }}</bs-alert>
    <div class="m-2 text-info rounded" v-if="visit.address">
      <small><strong class="text-uppercase">{{ $tc('labels.address', 1) }}:</strong> {{ visit.address.readable }}</small><br>
      <small>
        <strong class="text-uppercase">
          {{ $t('labels.due') }}:</strong>&nbsp;<date-time :date="visit.due_at" />
      </small>
      <small @click="showTime(visit)" class="clickable underline ml-2 d-inline-block">Set Time</small>
      <small v-if="authCan('reschedule')" @click="showReschedule(visit)" class="clickable underline ml-2">Reschedule</small>
      <small v-if="authCan('suspend_clients')" @click="suspendClientPrompt(visit)" class="clickable underline ml-2">Suspend</small>
      <br>
      <small>
        <strong class="text-uppercase">{{ $t('labels.last_done') }}</strong>&nbsp;<date :date="visit.last_done" />
      </small><br>
      <small><strong class="text-uppercase">{{ $t('labels.frequency') }}:</strong>&nbsp;
        <span v-if="visit.rotation === 'daily'">{{ $t('messages.daily_rotation') }}
          <span v-for="(day, key) in visit.days_of_week" :key="'r' + visit.id + 'dow' + day">
            {{ rotationDate(day) }}<span v-if="key === visit.days_of_week.length - 2"> & </span><span v-else-if="key < visit.days_of_week.length - 1">, </span>
          </span>
        </span>
        <span v-else>{{ $t('messages.' + visit.rotation + '_' + visit.frequency + '_rotation', { day: rotationDate(visit.day_of_week) }) }}</span>
        <span v-if="authCan('reschedule_all')" @click="adjustTaskGroupPrompt(visit)" class="clickable underline ml-2">Adjust</span>
      </small>
    </div>
    <bs-table :cols="2">
      <template v-slot:thead>
        <tr>
          <bs-th :label="$t('labels.tasks')"></bs-th>
          <bs-th v-if="!visit.skip && visit.client_active && !visit.client_suspended" class="text-center" :label="$t('labels.complete')"></bs-th>
        </tr>
      </template>
      <tr v-for="t in visit.tasks" :key="'t' + t.id">
        <td>
          <div :style="t.paused ? 'text-decoration:line-through' : ''">
            {{ t.name }} <span v-if="t.consumable && t.quantity" class="bg-yellow font-weight-bold px-1">x{{ t.quantity }}</span>
            <small v-if="authCan('task_price') && !t.consumable">(<span v-html="$n(t.contractor_total, 'currency')"></span>)</small>
          </div>
          <small v-if="t.paused">
            <span v-if="t.pause_to">{{ $t('labels.paused_until', { date: dateString(t.pause_to) }) }}</span>
            <span v-else>{{ $t('labels.paused_indefinitely') }}</span>
          </small>
          <small v-else @click="showDescription(t)" class="clickable underline">{{ $t('labels.read_description') }}</small>
          <small v-if="t.paused" @click="resumeTaskPrompt(t)" class=" ml-2 clickable underline">{{ $t('labels.resume') }}</small>
          <small v-else @click="pauseTaskPrompt(t)" class=" ml-2 clickable underline">{{ $t('labels.pause') }}</small>
          <bs-modal :name="'task-' + t.id + '-description'" :label="$t('labels.description')">
            <div class="px-3 pt-3">
              <p>{{ t.description }}</p>
            </div>
          </bs-modal>
        </td>
        <td v-if="!visit.skip && visit.client_active && !visit.client_suspended" class="text-center">
          <toggle-field v-if="!t.paused" :value="t.complete" :name="'c' + t.id" @input="complete($event, t)" />
        </td>
      </tr>
      <tr v-if="visit.client_terms === 'cash' && visit.complete && visit.invoice">
        <td>
          Collect Cash Payment
        </td>
        <td class="text-center">
          <toggle-field :value="visit.paid === 'paid'" :name="'vp' + visit.id" @input="paid($event)"></toggle-field>
        </td>
      </tr>
    </bs-table>
    <template v-if="!visit.skip && visit.client_active && !visit.client_suspended" v-slot:footer>
      <div class="d-flex pt-3 px-2 flex-wrap">
        <div class="position-relative mr-1 mb-1" v-for="asset in visit.assets" :key="'img' + asset.id">
          <button @click="destroy(visit.id, asset.id)" class="btn btn-action position-absolute" style="top: 0; right: 0">
            <fa-icon :icon="['fas', 'times']" />
          </button>
          <img :src="asset.thumbnail + asset.name" class="img-thumbnail" />
        </div>
      </div>
      <div class="progress m-1" v-if="imageSubmitting">
        <div class="progress-bar" role="progressbar" :style="'width: ' + uploadProgress + '%;'" :aria-valuenow="uploadProgress" aria-valuemin="0" aria-valuemax="100">{{ uploadProgress }}%</div>
      </div>
      <div class="d-flex justify-content-end p-3">
        <label v-if="visit.client_require_photos" :for="'photo-input-' + visit.id" class="btn btn-secondary">
          <fa-icon :icon="['fal', 'camera']" />
          <input :id="'photo-input-' +  + visit.id" class="d-none" type="file" @input="attachFilesToResource" multiple />
        </label>
        <button class="btn btn-secondary ml-2" style="height: 40px;" @click="update(visit)">
          Notes
        </button>
      </div>
    </template>
    <bs-modal :name="'consumable-' + visit.id" :label="$t('labels.enter_quantity')">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          {{ $t('messages.consumable') }}
          <hr />
          <tuxedo-input :type="'number'" :name="'quantity'" :label="$t('labels.quantity')" v-model="task.quantity" :error="slotProps.error" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'task-notes-' + visit.id" :label="$t('labels.add_note')">
      <bs-form :submit="$t('labels.submit')" @submit="submitTaskGroup">
        <template v-slot:default="slotProps">
          <text-field :name="'notes'" :label="$t('labels.notes')" v-model="resource.notes" :error="slotProps.error"></text-field>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'time-' + visit.id" :label="$t('labels.edit_time')">
      <bs-form :submit="$t('labels.submit')" @submit="submitTaskTime">
        <template v-slot:default="{ error }">
          <tuxedo-time :name="'time'" :label="$t('labels.time_of_day')" v-model="schedule.time_of_day" :error="error" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'date-' + visit.id" :label="$t('labels.reschedule')">
      <bs-form :submit="$t('labels.submit')" @submit="submitReschedule">
        <template v-slot:default="{ error }">
          <tuxedo-date :label="$t('labels.date')" :name="'due_at'" v-model="schedule.due_at" :insistFormat="'isoDate'" :error="error" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-image-' + visit.id" :label="$t('labels.destroy_image')">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: 'Image' }) }}
      </bs-form>
    </bs-modal>
    <pause-task-form ref="pauseTask" @submit="pauseTask" />
    <play-task-form ref="playTask" @submit="resumeTask" />
    <adjust-task-group-frequency-form ref="adjustTask" @submit="adjustTaskGroup" />
    <suspend-client-form ref="suspendClient" @submit="suspendClient" />
    <confirm-assigned-contractors-form ref="confirmAssignedContractors" @submit="confirmContractors" />
  </bs-card>

</template>

<script>
import { mapState } from 'vuex'
import submit from '../../router/submit'
import { Sec } from '@/mixins/sec'
import { authMethods } from '@/mixins/authMethods'
import helpers from '../../helpers'
import PauseTaskForm from '@/components/forms/PauseTaskForm'
import PlayTaskForm from '@/components/forms/PlayTaskForm'
import AdjustTaskGroupFrequencyForm from '@/components/forms/AdjustTaskGroupFrequencyForm'
import SuspendClientForm from '@/components/forms/SuspendClientForm'
import ConfirmAssignedContractorsForm from '@/components/forms/ConfirmAssignedContractorsForm'

export default {
  name: 'TaskGroupCard',
  props: ['collection', 'visit'],
  mixins: [authMethods],
  components: {
    'pause-task-form': PauseTaskForm,
    'play-task-form': PlayTaskForm,
    'adjust-task-group-frequency-form': AdjustTaskGroupFrequencyForm,
    'suspend-client-form': SuspendClientForm,
    'confirm-assigned-contractors-form': ConfirmAssignedContractorsForm
  },
  computed: {
    ...mapState({
      submitting: state => state.submitting
    }),
    skipVisit () {
      return this.visit.skip || (this.visit.state && this.visit.state.state === 'skipped')
    },
    allTasksComplete () {
      for (const t of this.visit.tasks) {
        if (t.complete === false) return false
        if (t.complete === null) return false
      }
      return true
    },
    multipleContractorsAssigned () {
      return this.visit.users.length > 1
    }
  },
  data () {
    return {
      hideLabel: Object.prototype.hasOwnProperty.call(this.$attrs, 'hideLabel'),
      resource: {
        id: null,
        quantity: null,
        complete: null,
        images: [],
        notes: ''
      },
      task: {
        id: null
      },
      schedule: {
        id: null,
        time_of_day: null,
        due_at: null,
        reschedule: null
      },
      maxWidth: 1800,
      maxHeight: 1800,
      imageSubmitting: false,
      uploadProgress: null,
      imageId: null
    }
  },
  methods: {
    dateString (date) {
      return new Sec(date).format('M jS, Y')
    },
    async attachFilesToResource (e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return false
      }

      this.imageSubmitting = true

      const promises = []
      for (const f of files) {
        promises.push(new Promise(resolve => {
          console.log('Component Resource:')
          console.log(this.resource)
          console.log('---')
          this.processImage(f, (image) => {
            console.log(image)
            console.log(this.resource)
            this.resource.images.push(image)
            // try {
            //   throw new Error('Image Upload')
            // } catch (e) {
            //   console.log('fire bugsnag')
            //   Bugsnag.notify(e)
            // }
            resolve()
          })
        }))
      }

      Promise.all(promises).then(() => {
        this.submitTaskGroup()
      })
    },
    async processImage (file, callback) {
      const reader = new FileReader()
      const canvas = await this.createCanvas()
      const img = new Image()
      const maxWidth = this.maxWidth
      const maxHeight = this.maxHeight
      let mime = ''

      reader.onload = await function (e) {
        const dataURI = e.target.result
        if (dataURI) {
          console.log('Create New Image')
          img.src = dataURI
        }
      }

      img.onload = await function () {
        const arr = img.src.split(',')
        mime = arr[0].match(/:(.*?);/)[1]

        console.log('Mime: ' + mime)
        console.log('Image Width: ' + img.width)
        console.log('Image Height: ' + img.height)
        console.log('Natural Width: ' + img.naturalWidth)
        console.log('Natural Height: ' + img.naturalHeight)
        console.log('Max Width: ' + maxWidth)
        console.log('Max Height: ' + maxHeight)

        // Calculate the new size
        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height)
        const width = img.width * ratio + 0.5 | 0
        const height = img.height * ratio + 0.5 | 0
        console.log('Calc Width: ' + width)
        console.log('Calc Height: ' + height)

        // resize the canvas to the new dimensions
        canvas.width = width
        canvas.height = height

        console.log('Old image src size: ' + img.src.length)

        // scale & draw the image onto the canvas
        console.log('Resize and draw image')
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        console.log('New image src size: ' + canvas.toDataURL(mime).length)

        callback(helpers.base64ToFile(canvas.toDataURL(mime), file.name))
      }

      reader.readAsDataURL(file)
    },
    createCanvas () {
      const canvas = document.createElement('canvas')
      console.log('\'canvas\' Element Created')
      return canvas
    },
    createImage (reader) {
      const img = new Image()
      return Promise.resolve(resolve => {
        reader.onload = e => {
          const dataURI = e.target.result
          if (dataURI) {
            console.log('Create New Image')
            img.src = dataURI
          }
          resolve(img)
        }
      })
    },
    showDescription (t) {
      this.$store.commit('axiosError', null)
      this.$modal.show('task-' + t.id + '-description')
    },
    showTime (t) {
      this.schedule.id = t.id
      this.schedule.time_of_day = t.due_at
      this.schedule.reschedule = false
      this.$store.commit('axiosError', null)
      this.$modal.show('time-' + t.id)
    },
    showReschedule (t) {
      this.schedule.id = t.id
      this.schedule.due_at = t.due_at
      this.schedule.reschedule = true
      this.$store.commit('axiosError', null)
      this.$modal.show('date-' + t.id)
    },
    rotationDate (date) {
      return new Sec().dayOfWeek(date).format('l')
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource.id = o.id
      this.resource.notes = o.notes
      this.resource.images = []
      this.$modal.show('task-notes-' + this.visit.id)
    },
    complete (val, t) {
      this.task = t
      this.task.complete = val
      this.task.quantity = null
      if (t.consumable && val) {
        this.$modal.show('consumable-' + this.visit.id)
      } else {
        this.submit()
      }
    },
    paid () {
      const url = '/api/schedule/' + this.visit.id + '/paid'
      submit.get(url)
    },
    submit () {
      this.$store.dispatch('update', {
        path: 'ScheduleTasks',
        collection: this.collection,
        resource: this.task
      }).then(() => {
        if (this.allTasksComplete && this.multipleContractorsAssigned) {
          this.$refs.confirmAssignedContractors.show(this.visit)
        }
        this.$modal.hide('consumable-' + this.visit.id)
        this.$store.dispatch('index', { collection: 'UserMonthlyProgress', noReset: true })
      })
    },
    submitTaskTime () {
      const url = '/api/schedule/' + this.schedule.id + '/time'
      submit.post(url, this.schedule)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$modal.hide('time-' + this.schedule.id)
          this.$store.commit('submitting', false)
        })
    },
    submitReschedule () {
      const url = '/api/user-schedules/' + this.schedule.id
      submit.put(url, this.schedule)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$modal.hide('date-' + this.schedule.id)
          this.$store.commit('submitting', false)
          this.$store.dispatch('index', { collection: 'userTasksIncomplete' })
          this.$store.dispatch('index', { collection: 'userTasksToday' })
        })
    },
    submitTaskGroup () {
      const url = '/api/schedule/' + this.visit.id + '/notes'
      const formData = new FormData()
      console.log('new FormData')
      for (let i = 0; i < this.resource.images.length; i++) {
        formData.append('files[]', this.resource.images[i])
      }
      console.log('Files appended')
      if (this.resource.notes) {
        formData.append('notes', this.resource.notes)
      }
      console.log('Notes appended')

      this.$store.commit('submitting', true)
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(progressEvent)
        }
      }
      console.log('POST config prepared')
      submit.post(url, formData, config)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$modal.hide('task-notes-' + this.visit.id)
          this.$store.commit('submitting', false)
          this.imageSubmitting = false
          this.resource.images = []
        })
    },
    destroy (o, a) {
      this.resource.id = o
      this.imageId = a
      this.$modal.show('destroy-image-' + this.resource.id)
    },
    destruction () {
      this.$store.commit('submitting', true)
      const url = '/api/schedule/' + this.resource.id + '/asset/' + this.imageId
      submit.delete(url)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$modal.hide('destroy-image-' + this.resource.id)
          this.$store.commit('submitting', false)
        })
    },
    pauseTaskPrompt (t) {
      this.$refs.pauseTask.show(t)
    },
    pauseTask (resource) {
      const url = '/api/user-schedules/' + this.visit.id + '/task/' + resource.id + '/pause'
      submit.put(url, resource)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$refs.pauseTask.hide()
          this.$store.commit('submitting', false)
          this.$store.dispatch('index', { collection: 'userTasksIncomplete' })
          this.$store.dispatch('index', { collection: 'userTasksToday' })
        })
    },
    resumeTaskPrompt (t) {
      this.$refs.playTask.show(t)
    },
    resumeTask (resource) {
      const url = '/api/user-schedules/' + this.visit.id + '/task/' + resource.id + '/resume'
      submit.put(url, resource)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$refs.playTask.hide()
          this.$store.commit('submitting', false)
          this.$store.dispatch('index', { collection: 'userTasksIncomplete' })
          this.$store.dispatch('index', { collection: 'userTasksToday' })
        })
    },
    adjustTaskGroupPrompt (t) {
      this.$refs.adjustTask.show(t)
    },
    adjustTaskGroup (resource) {
      this.$store.commit('submitting', true)
      const url = '/api/clients/' + this.visit.client_id + '/task-groups/' + resource.id
      submit.put(url, resource)
        .then(() => {
          this.$emit('refresh')
          this.$refs.adjustTask.hide()
          this.$store.commit('submitting', false)
        })
    },
    suspendClientPrompt (t) {
      this.$refs.suspendClient.show(t)
    },
    suspendClient (resource) {
      this.$store.commit('submitting', true)
      const url = '/api/clients/' + this.visit.client_id + '/suspend'
      submit.post(url, resource)
        .then(() => {
          this.$emit('refresh')
          this.$refs.suspendClient.hide()
          this.$store.commit('submitting', false)
        })
    },
    confirmContractors (resource) {
      const url = '/api/user-schedules/' + resource.id
      submit.put(url, resource)
        .then((response) => {
          this.$store.commit('processResponse', { collection: this.collection, data: response.data })
          this.$refs.confirmAssignedContractors.hide()
          this.$store.commit('submitting', false)
        })
    }
  }
}
</script>
