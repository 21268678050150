<template>
  <div>
    <bs-card class="card-info">
      <bs-table>
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.name')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th class="text-center" :label="$t('labels.price')" :sortable="resourceState.sort" :col="'price'" @click="applySort"></bs-th>
            <bs-th class="text-center" :label="$t('labels.percentage')" :sortable="resourceState.sort" :col="'contractor_percentage'" @click="applySort"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>{{ r.name }}</td>
          <td class="text-center" v-html="$t('formats.price', { dollars: r.price_dollars, cents: r.price_cents})"></td>
          <td class="text-center">{{ $t('formats.percent' , { amount: r.contractor_percentage }) }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <tr v-for="blank in dataLength" :key="'b' + blank">
          <td colspan="4" class="blank">
            <div class="loading" v-if="loading"></div>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <task-form :activeId="activeId" v-if="taskFormActive" @closed="taskFormActive = false" />
    <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <portal to="app-footer">
      <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Tasks',
  data () {
    return {
      loading: false,
      dataLoading: false,
      activeId: null,
      taskFormActive: false,
      resource: {
        name: '',
        invoice_description: '',
        description: '',
        price: 0,
        contractor_percentage: 80,
        consumable: 0
      }
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      resourceState: state => state.tasksState,
      resources: state => state.tasks,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    index (page, count) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      this.loading = true
      this.$store.dispatch('index', { collection: this.$options.name, params: { page, count, sort: this.resourceState.sort.col, direction: this.resourceState.sort.dir } }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.taskFormActive = true
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.activeId = o.id
      this.taskFormActive = true
    },
    destruction () {
      this.$store.dispatch('destroy', { collection: this.$options.name, id: this.activeId }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
