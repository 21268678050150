<template>
  <span>{{ display }}</span>
</template>

<script>
export default {
  name: 'DateTime',
  props: ['date'],
  computed: {
    display () {
      let dt = this.date
      if (typeof this.date === 'string') dt = new Date(dt)

      const dayOfWeek = dt.toLocaleString(
        'default', { weekday: 'short' }
      )

      const monthOfYear = dt.toLocaleString(
        'default', { month: 'short' }
      )

      const date = dt.getDate()
      const year = dt.getFullYear()

      let hour = dt.getHours()
      let meridiem = 'am'
      if (hour > 12) {
        hour -= 12
        meridiem = 'pm'
      }
      const minute = dt.getMinutes()

      return `${dayOfWeek} ${monthOfYear} ${date}, ${year} ${hour}:${String(minute).padStart(2, '0')}${meridiem}`
    }
  }
}
</script>
