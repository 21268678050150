<template>
    <div v-if="user" v-tooltip.bottom="user && !showName ? user.name : ''" class="d-flex align-items-center">
        <img v-if="avatar" class="border img-circle" :src="appURL + '/storage/' + avatar" :height="height || 30" />
        <img v-else-if="user" class="border img-circle" :src="'https://ui-avatars.com/api/?size=40&background=dee2e6&color=6574cd&name=' + spaceToPlus(user.name)" :height="height || 30" />
        <span v-else class="d-block border img-circle text-center" :style="'width: ' + (height+2 || '32') + 'px; line-height: ' + (height || '30') + 'px;'">
            <fa-icon :icon="['fal', 'user']" />
        </span>
        <span v-if="showName" class="ml-2">{{ user.name }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Avatar',
  props: ['user', 'height'],
  data () {
    return {
      appURL: process.env.VUE_APP_ASSET_URL,
      showName: Object.prototype.hasOwnProperty.call(this.$attrs, 'showName')
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    avatar () {
      const user = this.user || this.auth
      if (user.avatar === null) return false
      return typeof user.avatar === 'object' ? user.avatar.path : typeof user.avatar === 'string' ? user.avatar : false
    }
  }
}
</script>

<style lang="scss">
    .placeholder-avatar {

    }
</style>
