<template>
  <div class="container-fluid">
    <route-header>
      <h3>{{ $t('labels.nav.my_jobs') }}</h3>
      <div v-if="mobileView">
        <tuxedo-date class="mb-1" :name="'from'" :insistFormat="'isoDate'" v-model="from" :shortcuts="shortcuts" />
        <tuxedo-select v-if="authCan('users')" :name="'user'" v-model="user" :options="[{id: '0', name: $t('labels.all_contractors')}].concat(users)" :insistLabel="'name'" :insistReturn="'id'" class="mb-1" />
      </div>
      <div v-else class="d-flex">
        <tuxedo-date class="mb-0" :name="'from'" :insistFormat="'isoDate'" v-model="from" :shortcuts="shortcuts" />
        <tuxedo-select v-if="authCan('users')" :name="'user'" v-model="user" :options="[{id: '0', name: $t('labels.all_contractors')}].concat(users)" :insistLabel="'name'" :insistReturn="'id'" class="ml-3 mb-0" />
      </div>
    </route-header>
    <div class="row">
      <div class="col-12">
        <user-schedules :user="user" :from="from" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { watchWindowSize } from '@/mixins/helpers'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'MyJobs',
  mixins: [watchWindowSize, authMethods],
  data () {
    return {
      user: null,
      from: new Date(),
      shortcuts: [
        {
          text: this.$t('labels.today'),
          onClick () {
            return new Date()
          }
        }
      ]
    }
  },
  watch: {
    from: function (val) {
      if (val) this.$store.commit('pushState', { view: 'userSchedules', key: 'dateFrom', data: val })
    },
    user: function (val) {
      if (val) {
        console.log(val)
        this.$store.commit('pushState', { view: 'userSchedules', key: 'user', data: val })
      }
    }
  },
  computed: {
    ...mapState({
      users: state => state.usersStore,
      resourceState: state => state.userSchedulesState
    })
  },
  mounted () {
    if (this.resourceState.dateFrom) this.from = this.resourceState.dateFrom
    if (this.$route.query.user && this.authCan('users')) this.user = this.$route.query.user
    else if (this.resourceState.user && this.authCan('users')) this.user = this.resourceState.user
    else this.user = this.auth.id
    if (this.authCan('users')) {
      this.$store.dispatch('index', { collection: 'Users' })
    }
  }
}
</script>
