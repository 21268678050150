<template>
    <div class="container-fluid">
        <route-header>
            <h3>{{ $t('labels.nav.accounts') }}</h3>
          <nav-secondary-accounts />
        </route-header>
        <div class="row">
            <div class="col-12 mb-4" v-if="authCan('manage_invoices')">
                <invoices />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { authMethods } from '../mixins/authMethods'

export default {
  name: 'Accounts',
  mixins: [authMethods],
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    })
  },
  beforeMount () {
    this.pushFilter({ view: 'Invoices', data: { client: null } })
  },
  methods: {
    ...mapMutations([
      'pushFilter'
    ])
  }
}
</script>
