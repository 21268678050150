<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.create }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="3">
        <template v-slot:thead>
          <tr>
            <th scope="col">{{ $t('labels.name') }}</th>
            <th scope="col">{{ $t('labels.email') }}</th>
            <th scope="col"></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td>
            <span>{{ r.name }}</span><br>
            <small v-if="r.abn">ABN: {{ r.formatted_abn }}</small>
          </td>
          <td>{{ r.email }}</td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" :tip="labels.edit" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" :tip="labels.delete" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.create">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="{ error }">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error" />
          <tuxedo-input :name="'trading_name'" :label="$t('labels.trading_name')" v-model="resource.trading_name" :error="error" />
          <tuxedo-input :name="'abn'" :label="$t('labels.abn')" v-model="resource.abn" :error="error" />
          <tuxedo-input :name="'email'" :label="$t('labels.email')" v-model="resource.email" :error="error" />
          <tuxedo-input :name="'phone'" :label="$t('labels.phone')" v-model="resource.phone" :error="error" />
          <tuxedo-select :name="'user_id'" :label="$tc('labels.contractor', 1)" v-model="resource.user_id" :error="error"
                         :options="users" :insistReturn="'id'" :insistLabel="'name'" :help="$t('tip.vendor_contractor')"
          />
          <tuxedo-toggle :name="'gst_registered'" :label="$t('labels.gst_registered')" v-model="resource.gst_registered" />
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'

export default {
  name: 'Vendors',
  mixins: [resourceControl],
  data () {
    return {
      icon: ['fal', 'hand-holding-usd'],
      resource: {
        name: '',
        trading_name: null,
        abn: '',
        phone: null,
        email: null,
        gst_registered: false
      }
    }
  },
  computed: {
    ...mapState({
      resources: state => state.vendors,
      resourceState: state => state.vendorsState,
      resourceStore: state => state.vendorsStore,
      users: state => state.usersStore
    })
  }
}
</script>
