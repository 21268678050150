<template>
  <div>
    <bs-card class="card-info" :icon="['fal', 'user-friends']" :label="$t('labels.' + camelToSnake($options.name))">
      <template v-slot:header>
        <tuxedo-date class="mb-0" :name="'month'" v-model="month" :format="'MMM YYYY'" :shortcuts="[]" :type="'month'"></tuxedo-date>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="5">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.name')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th></bs-th>
            <bs-th class="text-center" :label="$t('labels.tasks_complete')"></bs-th>
            <bs-th class="text-right" :label="$t('labels.earnings')"></bs-th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td class="clickable" style="vertical-align: top" @click="$router.push({ name: 'MyJobs', query: { user: r.user }})">
            <avatar :user="r" showName />
          </td>
          <td class="py-2">
            Week 1<br>
            Week 2<br>
            Week 3<br>
            Week 4<br>
            Week 5<br>
            <b>Total</b>
          </td>
          <td class="text-center">
            {{ r.tasks.complete.week_1 }} / {{ r.tasks.total.week_1 }}<br>
            {{ r.tasks.complete.week_2 }} / {{ r.tasks.total.week_2 }}<br>
            {{ r.tasks.complete.week_3 }} / {{ r.tasks.total.week_3 }}<br>
            {{ r.tasks.complete.week_4 }} / {{ r.tasks.total.week_4 }}<br>
            {{ r.tasks.complete.week_5 }} / {{ r.tasks.total.week_5 }}<br>
            <b>{{ r.tasks.complete.total }} / {{ r.tasks.total.total }}</b>
          </td>
          <td class="text-right">
            <span v-html="$t('formats.price', r.earnings.so_far.week_1)"></span> /
            <span v-html="$t('formats.price', r.earnings.potential.week_1)"></span><br>
            <span v-html="$t('formats.price', r.earnings.so_far.week_2)"></span> /
            <span v-html="$t('formats.price', r.earnings.potential.week_2)"></span><br>
            <span v-html="$t('formats.price', r.earnings.so_far.week_3)"></span> /
            <span v-html="$t('formats.price', r.earnings.potential.week_3)"></span><br>
            <span v-html="$t('formats.price', r.earnings.so_far.week_4)"></span> /
            <span v-html="$t('formats.price', r.earnings.potential.week_4)"></span><br>
            <span v-html="$t('formats.price', r.earnings.so_far.week_5)"></span> /
            <span v-html="$t('formats.price', r.earnings.potential.week_5)"></span><br>
            <b><span v-html="$t('formats.price', r.earnings.so_far.total)"></span> /
              <span v-html="$t('formats.price', r.earnings.potential.total)"></span></b>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '../../mixins/sec'

export default {
  name: 'ContractorMonthlyProgress',
  data () {
    return {
      loading: false,
      activeId: null,
      resource: {
        name: ''
      },
      month: new Sec().JSDate()
    }
  },
  mounted () {
    this.index(this.resourceState.page)
  },
  watch: {
    month: function () {
      this.index(this.resourceState.page)
    }
  },
  computed: {
    ...mapState({
      resources: state => state.contractorMonthlyProgress,
      submitting: state => state.submitting,
      resourceState: state => state.contractorMonthlyProgressState
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    search () {
      this.index(1)
    },
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    index (page, count) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      this.loading = true
      this.$store.dispatch('index', {
        collection: this.$options.name,
        params: {
          page,
          count,
          sort: this.resourceState.sort.col,
          direction: this.resourceState.sort.dir,
          month: this.month ? new Sec(this.month).YmdDate() : null
        }
      }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$root.clearData(this.resource)
      this.$modal.show('create-' + this.$options.name)
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { collection: this.$options.name, id: this.activeId }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
