<template>
    <button class="btn text-uppercase font-weight-bold shadow-sm" :class="submitting ? 'disabled' : ''" @click="processClick">
        <slot v-if="!submitting"></slot>
        <fa-icon v-else :icon="['fal', 'atom-alt']" class="loading fa-spin"></fa-icon>
    </button>
</template>

<script>
export default {
  name: 'BSButton',
  props: ['to', 'submitting'],
  methods: {
    processClick () {
      if (this.submitting) stop()
      if (this.to) this.$router.push(this.to)
      else this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
    button.btn {
        transition: all .15s ease;
        font-size: 0.875rem;
        padding: 0.375rem 1rem;
        border-radius: 0.25rem;

        &.btn-sm {
            font-size: 0.75rem;
            padding: 0.25rem .5rem;
        }

        .loading {
            position: relative;
            font-size: 1rem;
            top: 1px;
        }

        &.disabled {
            cursor: default;
        }
    }

    button.shadow-sm {
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);

        &:hover {
            box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
            transform: translateY(-1px);
        }
    }
</style>
