<template>
    <div class="row mb-2">
        <div class="col d-flex justify-content-between border-bottom mx-3 p-0" :class="mobileView ? 'flex-column' : ''">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { watchWindowSize } from '@/mixins/helpers'

export default {
  name: 'RouteHeader',
  mixins: [watchWindowSize]
}
</script>
