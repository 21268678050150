<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="$tc('labels.away_event', 0)">
      <vue-cal
          active-view="month"
          :disable-views="['years', 'year', 'week', 'day']"
          :events="events"
          events-on-month-view="true"
      />
    </bs-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueCal from 'vue-cal'

export default {
  name: 'AwayEventsCalendar',
  components: {
    VueCal
  },
  data () {
    return {
      icon: ['fal', 'hiking'],
      resource: {
      }
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth,
      users: state => state.usersStore,
      resources: state => state.awayEvents,
      resourceState: state => state.awayEventsState
    }),
    events () {
      const events = []
      this.resources.data.forEach(e => {
        const user = this._.find(this.users, u => u.id === e.user_id)
        events.push({
          start: e.start,
          end: e.end,
          title: user ? user.name : '',
          content: e.description,
          class: 'bg-indigo text-light'
        })
      })
      return events
    }
  },
  mounted () {
    this.index()
  },
  methods: {
    index () {
      this.$store.dispatch('index', {
        collection: 'AwayEvents',
        params: {
          allUsers: true,
          count: 100
        }
      })
    }
  }
}
</script>

<style>
.vuecal__event-time {
  display: none;
}
</style>
