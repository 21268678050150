<template>
  <div>
    <bs-card class="card-info" :icon="icon" :label="labels.name">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ labels.upload }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="6">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.owner')" v-if="!mobileView"></bs-th>
            <bs-th :label="$t('labels.type')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.name')"></bs-th>
            <bs-th :label="$t('labels.size')" v-if="!mobileView" class="text-right"></bs-th>
            <bs-th :label="$t('labels.expires_at')" class="text-right" v-if="!mobileView"></bs-th>
            <bs-th scope="col"></bs-th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td v-if="!mobileView">
            <avatar :user="_.find(users, u => u.id === r.owner)" showName />
          </td>
          <td>{{ $t('labels.documents.' + r.name) }}</td>
          <td>{{ r.description }}</td>
          <td v-if="!mobileView" class="text-right">{{ r.size }}</td>
          <td v-if="!mobileView" class="text-right">
            <span v-if="r.expires_at"><date :date="r.expires_at" /></span>
          </td>
          <td class="t2">
            <bs-actions>
              <template v-slot:primary>
                <bs-action :icon="['fal', 'download']" :tip="labels.download" class="btn-info" @click="download(r)"></bs-action>
              </template>
              <template v-slot:secondary>
                <button class="dropdown-item" v-if="authCan('manage_documents')" @click="destroy(r)">{{ labels.delete }}</button>
                <a :href="'mailto:?subject=' + r.subject + '&body=' + r.path" class="dropdown-item clickable" v-if="r.name !== 'insurance'">{{ labels.email }}</a>
                <a :href="'sms:+61;?&body=' + textMessage(r)" class="dropdown-item clickable" v-if="r.name !== 'insurance'">{{ labels.text }}</a>
              </template>
            </bs-actions>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" :state="resourceState" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <portal to="app-modal">
      <bs-modal :name="'create-' + $options.name" :label="resource.id ? labels.edit : labels.upload">
        <bs-form :submit="$t('labels.submit')" @submit="upload">
          <template v-slot:default="{ error }">
            <tuxedo-select :name="'type'" :label="$t('labels.type')" :options="types" v-model="resource.name" :error="error" />
            <tuxedo-input :name="'description'" :label="$t('labels.name')" v-model="resource.description" :error="error" />
            <file-field :name="'file'" :label="$t('labels.file')" v-model="resource.file" :accept="'application/pdf'" :help="$t('messages.pdf_only')" :error="error" />
          </template>
        </bs-form>
      </bs-modal>
      <bs-modal :name="'destroy-' + $options.name" :label="labels.delete">
        <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
          {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
        </bs-form>
      </bs-modal>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resourceControl } from '../../mixins/resourceControl'
import helpers from '../../helpers'
import { watchWindowSize } from '@/mixins/helpers'
import { authMethods } from '../../mixins/authMethods'

export default {
  name: 'Documents',
  mixins: [watchWindowSize, resourceControl, authMethods],
  data () {
    return {
      icon: ['fal', 'cabinet-filing'],
      resource: {
        type: '',
        description: '',
        file: null
      },
      types: [
        {
          id: 'msds',
          label: this.$t('labels.documents.msds')
        }
      ]
    }
  },
  computed: {
    ...mapState({
      users: state => state.usersStore,
      resources: state => state.documents,
      resourceState: state => state.documentsState,
      resourceStore: state => state.documentsStore
    })
  },
  methods: {
    textMessage (r) {
      return helpers.stringToSMS(r.subject + '\n\n' + r.path)
    },
    beforeCreate () {
      this.resource.file = null
    },
    download (r) {
      this.$store.dispatch('download', {
        path: 'documents/' + r.id + '/download',
        accept: 'application/pdf'
      })
    }
  }
}
</script>
