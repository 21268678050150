<template>
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-body py-2 position-relative overflow-hidden">
      <div class="d-flex">
        <div>
          <fa-icon :icon="queue.icon ? queue.icon : ['fal', 'crosshairs']"></fa-icon>
        </div>
        <div class="d-none d-sm-inline ml-2">
          <span>{{ queue.message }}</span><br>
          <small v-if="queue.error">{{ queue.error }}</small>
        </div>
      </div>
      <div class="toast-remove position-absolute px-1 text-white clickable" style="top:0;bottom:0;" @click="removeFromQueue(queue)">x</div>
    </div>
    <div v-if="queue.error" class="progress" style="height: 4px;">
      <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated'" role="progressbar" style="width: 100%"></div>
    </div>
    <div v-else class="progress" style="height: 4px;">
      <div class="progress-bar bg-info" :class="width >= 100 ? 'progress-bar-striped progress-bar-animated' : ''" role="progressbar" :style="'width: ' + width + '%'" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'APIQueue',
  props: ['queue'],
  data () {
    return {
      width: 0
    }
  },
  computed: {
    ...mapState({
      axiosTiming: state => state.axiosTiming
    }),
    avgTime () {
      if (this.axiosTiming[this.queue.url]) {
        let time = 0
        this.axiosTiming[this.queue.url].forEach(value => {
          time += value
        })
        return time / this.axiosTiming[this.queue.url].length
      } else return 10
    }
  },
  created () {
    const interval = 100
    const increment = 100 / (this.avgTime * 1000 / interval)
    const timer = setInterval(() => {
      this.width += increment
      if (this.width >= 100) clearInterval(timer)
    }, interval)
  },
  methods: {
    ...mapMutations([
      'removeFromQueue'
    ])
  }
}
</script>

<style lang="scss">
.toast-body {
  .toast-remove {
    transition: right 0.2s ease-in-out;
    right: -20px;
    background: rgba(231, 80, 89, 0.5)
  }

  &:hover {
    .toast-remove {
      right: 0
    }
  }
}
</style>
