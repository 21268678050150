<template>
    <div class="form-group bs-form-group">
        <label :for="name" class="text-uppercase mb-0">{{ label }}</label>
        <div class="progress" v-if="loading">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" :style="'width: 100%'"></div>
        </div>
        <select v-else class="custom-select shadow-sm border-top-0 border-left-0 border-bottom-1" v-model="inputValue">
            <slot></slot>
        </select>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
    </div>
</template>

<script>
export default {
  name: 'SelectField',
  props: ['label', 'name', 'help', 'value', 'error', 'default', 'loading'],
  computed: {
    inputValue: {
      get () {
        return this.value ?? this.default
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.$emit('input', this.value ?? this.default)
  }
}
</script>

<style lang="scss">
    .bs-form-group {
        > label {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }
</style>
