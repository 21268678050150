<template>
  <div>
    <bs-card class="card-info" :icon="['fal', 'user-friends']" :label="$t('labels.' + camelToSnake($options.name))">
      <template v-slot:header>
        <bs-button class="btn-sm btn-info" @click="create">{{ $t('labels.create_' + camelToSnake(singular($options.name))) }}</bs-button>
      </template>
      <bs-table v-bind="{dataLength, loading}" :cols="4">
        <template v-slot:thead>
          <tr>
            <bs-th :label="$t('labels.name')" :sortable="resourceState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.email')" :sortable="resourceState.sort" :col="'email'" @click="applySort"></bs-th>
            <bs-th :label="$t('labels.role')" @click="applySort"></bs-th>
            <th></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td class="clickable" @click="$router.push({ path: '/user/' + r.id })">{{ r.name }}</td>
          <td>{{ r.email }}</td>
          <td>{{ r.role.name }}</td>
          <td class="t3">
            <bs-action :icon="['fal', 'sign-in']" class="btn-warning" @click="assumeUser(r.id)"></bs-action>
            <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <template v-slot:tfoot>
          <pagination :data="resources" @pushPage="index"></pagination>
        </template>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="$t('labels.create_' + camelToSnake(singular($options.name)))">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="{ error }">
          <div class="row">
            <div class="col-6">
              <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="error"></tuxedo-input>
            </div>
            <div class="col-6">
              <tuxedo-select :name="'role_id'" :label="$t('labels.role')" v-model="resource.role_id" :options="roles" :insistReturn="'id'" :insistLabel="'name'" :error="error" />
            </div>
          </div>
          <tuxedo-input :type="'email'" :name="'email'" :label="$t('labels.email')" v-model="resource.email" :error="error"></tuxedo-input>
          <tuxedo-input :type="'password'" :name="'password'" :label="$t('labels.password')" :help="$t('messages.password_not_required')" v-model="resource.password" :error="error"></tuxedo-input>
          <tuxedo-input :name="'phone'" :label="$t('labels.phone')" v-model="resource.phone" :error="error"></tuxedo-input>
          <tuxedo-input :name="'abn'" :label="$t('labels.abn')" v-model="resource.abn" :error="error"></tuxedo-input>
          <div class="row">
            <div class="col-6">
              <tuxedo-input :name="'bank_bsb'" :label="$t('labels.bsb')" v-model="resource.bank_bsb" :error="error"></tuxedo-input>
            </div>
            <div class="col-6">
              <tuxedo-input :name="'bank_account'" :label="$t('labels.bank_account')" v-model="resource.bank_account" :error="error"></tuxedo-input>
            </div>
          </div>
          <tuxedo-input :name="'legal_name'" :label="$t('labels.legal_name')" v-model="resource.legal_name" :error="error"></tuxedo-input>
          <tuxedo-input :name="'legal_business_name'" :label="$t('labels.legal_business_name')" v-model="resource.legal_business_name" :error="error"></tuxedo-input>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      loading: false,
      dataLoading: false,
      activeId: null,
      assignedClients: [],
      resource: {
        name: '',
        email: '',
        phone: null,
        role_id: null,
        password: '',
        password_confirmation: ''
      }
    }
  },
  mounted () {
    this.index(this.page)
  },
  computed: {
    ...mapState({
      resourceState: state => state.usersState,
      roles: state => state.roles.data,
      clients: state => state.clients.data,
      resources: state => state.users,
      submitting: state => state.submitting
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.resourceState.tableRows - length, 0)
    }
  },
  methods: {
    assumeUser (id) {
      this.$store.dispatch('assumeUser', { id })
    },
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    },
    index (page, count) {
      if (page) this.$store.commit('pushState', { view: this.$options.name, key: 'page', data: page })
      else page = this.resourceState.page
      if (count) this.$store.commit('pushState', { view: this.$options.name, key: 'tableRows', data: count })
      else count = this.resourceState.tableRows
      this.loading = true
      this.$store.dispatch('index', { collection: this.$options.name, params: { page, count, sort: this.resourceState.sort.col, direction: this.resourceState.sort.dir } }).then(() => {
        this.loading = false
      })
    },
    create () {
      this.$store.commit('axiosError', null)
      this.$root.clearData(this.resource)
      this.$modal.show('create-' + this.$options.name)
    },
    destroy (o) {
      this.activeId = o.id
      this.$modal.show('destroy-' + this.$options.name)
    },
    update (o) {
      this.$store.commit('axiosError', null)
      this.resource = JSON.parse(JSON.stringify(o))
      this.$modal.show('create-' + this.$options.name)
    },
    submit () {
      this.$store.dispatch(this.resource.id ? 'update' : 'store', { collection: this.$options.name, resource: this.resource }).then(() => {
        this.$modal.hide('create-' + this.$options.name)
      })
    },
    destruction () {
      this.$store.dispatch('destroy', { collection: this.$options.name, id: this.activeId }).then(() => {
        this.$modal.hide('destroy-' + this.$options.name)
      })
    }
  }
}
</script>
