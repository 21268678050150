<template>
    <div v-if="state.total > state.processed" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-body py-2">
            <fa-icon :icon="state.icon ? state.icon : ['fal', 'crosshairs']"></fa-icon>
            <span class="d-none d-sm-inline ml-2">{{ state.message }}</span>
        </div>
        <div class="progress" style="height: 4px;">
            <div class="progress-bar bg-info" :class="width >= 100 ? 'progress-bar-striped progress-bar-animated' : ''" role="progressbar" :style="'width: ' + width + '%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChunkProcess',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      state: state => state.chunkState
    }),
    width () {
      return parseInt((this.state.processed / this.state.total) * 100)
    }
  }
}
</script>

<style lang="scss">

</style>
