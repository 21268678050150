<template>
  <div>
    <bs-card class="card-info">
      <bs-table>
        <template v-slot:thead>
          <tr v-if="clientsState.search.active">
            <th class="p-1" :colspan="authCan('manage_accounts') ? 5 : 4">
              <input ref="searchInput" type="text" class="form-control border-1 border-primary shadow-sm bg-light" v-model="searchPhrase">
            </th>
            <th class="text-right"><bs-action :icon="['fas', 'times']" class="btn-outline-secondary" @click="applySearch({ active: false, phrase: '' })"></bs-action></th>
          </tr>
          <tr v-else>
            <bs-th class="text-center" :label="$t('labels.active')"></bs-th>
            <bs-th :label="$t('labels.name')" :sortable="clientsState.sort" :col="'name'" @click="applySort"></bs-th>
            <bs-th class="text-center" :label="$t('labels.invoice_schedule.label')" />
            <bs-th class="text-center" :label="$t('labels.last_issued')" />
            <bs-th class="text-right" :label="$t('labels.owing')" v-if="authCan('manage_accounts')"></bs-th>
            <th class="text-right"><bs-action :icon="['fal', 'search']" class="btn-outline-secondary" @click="applySearch({ active: true, phrase: '' })"></bs-action></th>
          </tr>
        </template>
        <tr v-for="r in resources.data" :key="r.id">
          <td class="text-center" width="70">
            <toggle-field :value="r.active" @input="active($event, r)" :name="'a' + r.id"></toggle-field>
          </td>
          <td class="clickable" @click="$router.push({ path: '/client/' + r.id })">
            <div class="d-flex justify-content-between">
              <span>{{ r.name }}</span>
              <bs-badge v-if="r.suspended" :class="'bg-danger text-light'">{{ $t('labels.suspended') }}</bs-badge>
            </div>
          </td>
          <td class="text-center">{{ $t('labels.' + r.invoice_schedule) }}</td>
          <td class="text-center"></td>
          <td class="text-right" v-if="authCan('manage_accounts')">
            <span v-if="r.owing">{{ $n(r.owing, 'currency') }}</span>
          </td>
          <td class="t2">
            <bs-action :icon="['fal', 'pencil']" class="btn-info" @click="update(r)"></bs-action>
            <bs-action :icon="['fal', 'trash']" class="btn-danger" @click="destroy(r)"></bs-action>
          </td>
        </tr>
        <tr v-for="blank in dataLength" :key="'b' + blank">
          <td :colspan="authCan('manage_accounts') ? 6 : 5" class="blank">
            <div class="loading" v-if="loading"></div>
            <span v-else>&nbsp;</span>
          </td>
        </tr>
      </bs-table>
    </bs-card>
    <bs-modal :name="'create-' + $options.name" :label="$t('labels.' + camelToSnake(singular($options.name)))">
      <bs-form :submit="$t('labels.submit')" @submit="submit">
        <template v-slot:default="slotProps">
          <tuxedo-input :name="'name'" :label="$t('labels.name')" v-model="resource.name" :error="slotProps.error"></tuxedo-input>
          <toggle-field :name="'active'" :label="$t('labels.active')" v-model="resource.active"></toggle-field>
        </template>
      </bs-form>
    </bs-modal>
    <bs-modal :name="'destroy-' + $options.name" :label="$t('labels.destroy_' + camelToSnake(singular($options.name)))">
      <bs-form :destroy="$t('labels.destroy')" @submit="destruction">
        {{ $t('messages.delete', { resource: camelToSpace(singular($options.name)) }) }}
      </bs-form>
    </bs-modal>
    <portal to="app-footer">
      <pagination :data="resources" :state="clientsState" @pushPage="index" :filters="filters"></pagination>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'debounce'
import { resourceControl } from '../../mixins/resourceControl'
import { authMethods } from '../../mixins/authMethods'

export default {
  name: 'Clients',
  mixins: [resourceControl, authMethods],
  data () {
    return {
      loading: false,
      activeId: null,
      resource: {
        name: '',
        active: ''
      },
      filters: {
        clientState: true
      }
    }
  },
  mounted () {
    this.search = debounce(this.search, 500)
  },
  computed: {
    ...mapState({
      resources: state => state.clients,
      submitting: state => state.submitting,
      clientsState: state => state.clientsState
    }),
    dataLength () {
      let length = 0
      if (this.resources.data) length = this.resources.data.length
      return Math.max(this.clientsState.tableRows - length, 0)
    },
    searchPhrase: {
      get () {
        return this.clientsState.search.phrase
      },
      set (val) {
        this.applySearch({ active: true, phrase: val })
      }
    }
  },
  methods: {
    afterCreate () {
      this.resource.active = true
    },
    active (val, r) {
      this.resource = r
      this.resource.active = val
      this.submit()
    },
    applySort (val) {
      this.$store.commit('pushState', { view: this.$options.name, key: 'sort', data: val })
      this.index()
    }
  }
}
</script>
