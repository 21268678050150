<template>
    <div>
        <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" :id="name" :checked="value" @change="checked($event.target.checked)">
            <label class="custom-control-label" :for="name">{{ label }}</label>
        </div>
        <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
        <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
    </div>
</template>

<script>
export default {
  name: 'ToggleField',
  props: ['label', 'name', 'value', 'help', 'error'],
  methods: {
    checked (checked) {
      this.$emit('input', checked ? 1 : 0)
    }

  }
}
</script>
